import { result } from "lodash";
import {
  SET_BOOKINGS,
  SET_SEARCH_BOOKINGS,
  SET_BOOKINGS_GET_API,
  BOOKING_CHECKOUT,
  GET_BOOKINGS,
  SET_CART_DETAILS,
} from "../constants";
import { fetchBookings, getCartDetails, searchBooking } from "./apis";

export const setBriks = (payload) => {
  return {
    type: SET_BOOKINGS,
    payload,
  };
};

export const setSearchBriks = (payload) => {
  return {
    type: SET_SEARCH_BOOKINGS,
    payload,
  };
};

export const bookingCheckout = (payload) => {
  return {
    type: BOOKING_CHECKOUT,
    payload,
  };
};

export const setBookings = (payload) => {
  return {
    type: SET_BOOKINGS_GET_API,
    payload,
  };
};

export const getBookings = () => {
  return (dispatch) => {
    return fetchBookings()
      .then((result) => {
        dispatch(setBookings(result));
      })
      .catch((er) => {
        console.log("ownership list ERR: ", er);
      });
  };
};

export const setCartDetails = (payload) => {
  return {
    type: SET_CART_DETAILS,
    payload,
  };
};

export const getBookingDetails = (filters) => {
  return (dispatch) => {
    return getCartDetails(filters)
      .then((result) => {
        console.log("RESULT: [getBookingDetails]: ", result[0]);
        dispatch(setCartDetails(result[0]));
      })
      .catch((er) => {
        console.log("ownership list ERR: ", er);
      });
  };
};

export const getBriks = (filters) => {
  return (dispatch) => {
    return searchBooking(filters)
      .then((res) => {
        let result = []
        for (let i = 0; i < res.length; i++) {
         
          let temp = {
            ...res[i].brik_data
          }
          temp['average_review'] = res.average_review
          temp['total_review'] = res.total_review
          result.push(temp)
        }
        console.log("SEACH RESULTS: ", result);
        // if (result.response)
        dispatch(setBriks(result));
        // else
        // dispatch(setMyOwnerShipData(result));
        // dispatch(setComponentBusyStateOwnership(false));
      })
      .catch((er) => {
        console.log("ownership list ERR: ", er);
        // dispatch(setComponentBusyStateOwnership(false));
      });
  };
};

export const getSearchBriks = (filters) => {
  return (dispatch) => {
    return searchBooking(filters)
      .then((res) => {
        let result = []
        for (let i = 0; i < res.length; i++){
          if(res[i]?.brik_data?.is_test_spv){
            continue
          }
          let temp = {
            ...res[i].brik_data
          }
          temp['average_review'] = res.average_review
          temp['total_review'] = res.total_review
          result.push(temp)
        }
        console.log("SET SEARCH BOOKING RESULTS: ", result);
        // if (result.response)
        dispatch(setSearchBriks(result));
        // else
        // dispatch(setMyOwnerShipData(result));
        // dispatch(setComponentBusyStateOwnership(false));
      })
      .catch((er) => {
        console.log("ownership list ERR: ", er);
        // dispatch(setComponentBusyStateOwnership(false));
      });
  };
};
