import { Box, Grid, Button, makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../providers/AuthProvider'

const useStyles = makeStyles((theme) => ({
    loginContainer: { height: "400px" },
    loginFormSection: { display: "flex", flexDirection: "column", justifyContent: "center" },
    [theme.breakpoints.down('sm')]: {
        loginContainer: { height: "500px" },
        loginFormSection: { justifyContent: "flex-end" },
    },
}))

function HomePage() {
    const auth = useAuth()
    const history = useHistory()
    const classes = useStyles()

    useEffect(() => {
        if (localStorage.getItem('token'))
            history.push('/my-ownership')
    }, [])


    return (
        <Box style={{ padding: 10 }}>
            <Grid className={classes.loginContainer} container spacing={2}>
                <Grid item xs={12} sm={8} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Box style={{ color: 'blue', padding: '12px 0' }}>
                        Welcome!
                    </Box>

                    <Box style={{ padding: '15px 0', fontWeight: 'bold' }}>
                        Enjoy Ownership Experiece in simple way
                    </Box>

                    <Box style={{ lineHeight: 1.5, fontWeight: 300 }}>
                        BRIK itt is India’s Leading  P7roptech Company unlocking Fractional Ownership in Private & Residential Real Estate. It brings to the people the opportunity to own Exclusive luxury residential & vacational assets.
                    </Box>
                </Grid>

                <Grid className={classes.loginFormSection} item xs={12} sm={4}>
                    <Button onClick={()=>{
                            history.push('/login')
                        }} variant='contained' fullWidth color='primary'>
                        LOGIN
                    </Button>
                </Grid>

            </Grid>

            {/* <button onClick={()=>auth.login(1)}>Login</button> */}
        </Box>
    )
}

export default HomePage
