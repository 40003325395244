import { createTheme } from '@material-ui/core';

const theme = createTheme({
    palette: {
        // primary: {
        //     main: '#ffffff',        //blue
        //     contrastText: '#282827',
        // },
        // secondary: {
        //     main: '#4A8EAB',        //green
        //     contrastText: '#ffffff',
        // },
        primary: {
            main: '#e7cbab',
            contrastText: 'rgb(139, 120, 98)',
            // main: '#ffffff',        //blue
            // contrastText: '#282827',
        },
        secondary: {
            main: '#4A8EAB',        //green
            contrastText: '#ffffff',
        }
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
            '"Proxima Nova"',
            'Lato',
        ].join(','),
    },
    overrides: {
        MuiDrawer:{
            paper:{
                backgroundColor:"#434343",
                border:"none !important"
            }
        },
        MuiListItem:{
            button:{
                '&:hover':{
                    textDecoration:"none",
                    borderRadius:"27px"
                }
            }
        },
        MuiOutlinedInput: {
            input:{
                height:"10px",
                padding:"14.5px 11px"
            },
            root: {
                "& $notchedOutline": {
                    borderColor: '#979797'
                },
                "&:hover $notchedOutline": {
                    borderColor: "#979797"
                },
                "&$focused $notchedOutline": {
                    borderColor: "#979797"
                }
            }
        }
    }
});

export default theme;