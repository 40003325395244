import {
  Box,
  Grid,
  Paper,
  IconButton,
  Avatar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Button,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RoomIcon from "@material-ui/icons/Room";
import Radio from "@material-ui/core/Radio";
import moment from "moment";
import { paymentValidation } from "../validations";
import StarIcon from "@material-ui/icons/Star";
import { BrikCoin } from "../assets/assets";
import DirectionIcon from "../assets/icons/Group 7006.svg";
import ShareIcon from "../assets/icons/Group 7007.svg";
import CallBrikIcon from "../assets/icons/Group 7008.svg";
import CallBrikIcon2 from "../assets/icons/GroupCallIIcon.svg";
import { useParams } from "react-router-dom";
import { getBookingByID } from "../store/actions/apis";
import { useGeneralProvider } from "../providers/GeneralProvider";
import { responsiveStyles } from "../assets/css/generalStyling";
import { addBooking } from "../store/actions/apis";
import MyPropertyIcon from "../assets/icons/myProperties.svg";
import CancelBookingIcon from "../assets/icons/Group.svg";
import ModifyGuestDetailsIcon from "../assets/icons/Group1.svg";
import CancellationPolicyIcon from "../assets/icons/Group 7011.svg";
import PropertyPolicyIcon from "../assets/icons/Group 1511.svg";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Modal from "../component/Modal";
import {cancelBookingRequest} from "../store/actions/apis.js"

function BookingDetails() {
  const [selectedValue, setSelectedValue] = React.useState("a");
  const { booking_id } = useParams();
  const general = useGeneralProvider();
  const [bookingData, setBookingData] = useState({
    brik_images: [{ image: "" }],
    booking_status : "",
  });

  useEffect(() => {
    getBookingByID(booking_id).then((result) => {
      setBookingData(result.data.bookings);
    });
    general.setHeaderComponent(() => {
      return (
        <Breadcrumbs style={{ fontSize: "14px" }} aria-label="breadcrumb">
          <Link
            style={{ textDecoration: "none", cursor: "pointer" }}
            href="/"
            color="inherit"
          >
            Home
          </Link>
          <Link
            style={{ textDecoration: "none", cursor: "pointer" }}
            href="/booking"
            color="inherit"
          >
            Booking
          </Link>
        </Breadcrumbs>
      );
    });
  }, []);

  const responsive = responsiveStyles();
  const [modal, setModal] = useState(false);
  const [modalGuestDetails, setModalGuestDetails] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const [mode, setMode] = useState("write");
  const [pay, setPay] = useState(false);
  const history = useHistory();
  const [cancellationError, setCancellationError] = useState("");
  const {
    values,
    touched,
    errors,
    setFieldError,
    handleSubmit,
    handleChange,
    handleBlur,
    submitForm,
  } = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      instructions: "",
    },
    onSubmit: () => {
      alert("SUBMIT GUEST DETAILS");
    },
  });

  return (
    <>
      <Modal
        width="470px"
        height="320px"
        title="Cancel Your Booking"
        status={modal}
        saveBtnText="Confirm"
        saveHandler={() => {
          if(bookingData.booking_status !== "cancel"){
          if (cancellationReason !== "") {
            // alert("submit");
            let body = {
              "order_id" : booking_id,
              "reason" : cancellationReason,
             }
             cancelBookingRequest(body, booking_id).then((res) =>{
              
              setCancellationError(""); 
            setModal(false)})
            alert("Booking Cancellation Request Submitted")
            } else setCancellationError("Cancellation Reason is required");}
            else{
              alert("Booking has already been cancelled")
            } 
        }}
        closeBtnText="Cancel"
        closeHandler={() => {
          setModal(false);
        }}
        borderRadius="none"
      >
        <Box style={{ fontSize: "16px", color: "#282827" }}>
          Tell us, what is the reason?
        </Box>
        <Box marginTop="5px">
          <TextField
            onChange={(event) => {
              setCancellationReason(event.target.value);
              let cancelreasons = event.target.value;
            }}
            variant="outlined"
            fullWidth
            error={cancellationError !== ""}
            helperText={cancellationError}
            minRows={5}
            multiline
            placeholder="Tell us more...(Optional)"
          />
        </Box>
        <Box>
          <Link
            style={{
              textDecoration: "none",
              cursor: "pointer",
              color: "#4A8EAB",
            }}
          >
            <Box display="flex" marginTop="10px">
              <Box>
                <img src={CallBrikIcon2} />
              </Box>
              <Box marginLeft="8px">Call Brik Manager</Box>
            </Box>
          </Link>
        </Box>
      </Modal>
      <Modal
        width="600px"
        height="362px"
        title="Modify Guest Details"
        status={modalGuestDetails}
        saveBtnText="Confirm"
        saveHandler={() => {
          alert("submit");
        }}
        closeBtnText="Cancel"
        closeHandler={() => {
          setModalGuestDetails(false);
        }}
        borderRadius="none"
      >
        <Box flexDirection="column" padding="0 11px" width="100%">
          <Box lineHeight="2">
            <Box>
              <Box fontWeight="600" paddingBottom="3px">
                FULL NAME
              </Box>
              <Box>
                <TextField
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && errors.name}
                  helperText={touched.name && errors.name}
                  placeholder="Enter Full Name"
                  variant="outlined"
                  fullWidth
                />
              </Box>
            </Box>
            <Box padding="10px 0">
              <Box fontWeight="600" paddingBottom="3px">
                MOBILE NUMBER
              </Box>
              <Box>
                <TextField
                  id="phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.phone && errors.phone}
                  helperText={touched.phone && errors.phone}
                  placeholder="Enter Phone Number"
                  variant="outlined"
                  fullWidth
                />
              </Box>
            </Box>
            <Box>
              <Box fontWeight="600" paddingBottom="3px">
                EMAIL ID
              </Box>
              <Box>
                <TextField
                  id="email"
                  name="email"
                  value={values.email}
                  error={touched.email && errors.email}
                  helperText={touched.email && errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Email"
                  variant="outlined"
                  fullWidth
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Box className={responsive.web}>
        <Box style={{ marginTop: "20px" }}>
          <Grid spacing={2} container>
            <Grid xs={8} item>
              <Paper
                elevation={0}
                style={{ padding: "10px", border: "1px solid #00000026" }}
              >
                <Box style={{ fontSize: "20px", marginBottom: "10px" }}>
                  Description
                </Box>
                <Box display="flex">
                  <Box>
                    <img
                      src={bookingData.brik_images[0].image}
                      style={{
                        height: "100px",
                        width: "150px",
                        borderRadius: "5px",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                  <Box style={{ color: "#565656", paddingLeft: "10px" }}>
                    <Box style={{ fontSize: "18px", fontWeight: "bold" }}>
                      {bookingData.brik_name}
                    </Box>
                    <Box>{bookingData.location}</Box>
                    {/* <Box>{bookingData.location}</Box>
                <Box>Address Line2</Box> */}
                  </Box>
                </Box>
              </Paper>

              <Box
                style={{
                  fontSize: "22px",
                  fontWeight: "bold",
                  padding: "14px 0px",
                }}
              >
                Booking Details
              </Box>
              <Paper elevation={3}>
                <Box style={{ padding: "10px" }}>
                  <Box
                    style={{
                      fontSize: "18px",
                      marginBottom: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    {bookingData.user_name}
                  </Box>
                  <Box display="flex">
                    <Box flex={1}>
                      <Box style={{ color: "#4A8EAB" }}>Check-in-date</Box>
                      <Box style={{ fontWeight: "bold", padding: "4px 0px" }}>
                        {moment(bookingData.date_check_in).format(
                          "DD MMM YYYY"
                        )}
                      </Box>
                    </Box>
                    <Box
                      style={{
                        alignSelf: "center  ",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      flex={1}
                      textAlign="center"
                      alignItems="center"
                    >
                      <Box
                        style={{
                          backgroundColor: "#BDE7FF",
                          padding: "4px",
                          borderRadius: "5px",
                          color: "#124765",
                          width: "100px",
                        }}
                      >
                        {moment(bookingData.date_check_out).diff(
                          moment(bookingData.date_check_in),
                          "days"
                        )}{" "}
                        nights
                      </Box>
                    </Box>
                    <Box flex={1} textAlign="right">
                      <Box style={{ color: "#4A8EAB" }}>Check-out-date</Box>
                      <Box style={{ fontWeight: "bold", padding: "4px 0px" }}>
                        {moment(bookingData.date_check_out).format(
                          "DD MMM YYYY"
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    {/* <Box
                      style={{
                        color: "#979797",
                        fontSize: "16px",
                        padding: "10px 0px",
                      }}
                    >
                      Instruction
                    </Box>
                    <Box>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Tincidunt fermentum, urna dolor elementum a volutpat
                      pellentesque. Diam turpis platea auctor odio integer.
                    </Box> */}
                  </Box>
                </Box>
                <Box style={{ marginTop: "20px", backgroundColor: "#EEEEEE" }}>
                  <Box display="flex" padding="10px">
                    <Box flex={1}>
                      <img src={DirectionIcon} />
                    </Box>
                    <Box flex={1}>
                      <img src={ShareIcon} />
                    </Box>
                    <Box flex={1}>
                      <img src={CallBrikIcon} />
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>

            <Grid xs={4} item>
              <Paper
                elevation={0}
                style={{
                  padding: "10px",
                  color: "#565656",
                  border: "1px solid #00000026",
                }}
              >
                <Box lineHeight="2">
                  <Box fontSize="17px" fontWeight="600" padding="2px 0">
                    Price Summary
                  </Box>
                  <Box display="flex">
                    <Box flex={1} color="#565656">
                      Property Charge/Night
                    </Box>
                    <Box fontWeight="600">
                      ₹{" "}
                      {bookingData.per_night_inr
                        ? bookingData.per_night_inr
                        : 0}{" "}
                      /Night
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box flex={1} color="#565656">
                      In Brikk coin
                    </Box>
                    <Box display="flex">
                      <Box>
                        <img
                          src={BrikCoin}
                          style={{ height: "16px", paddingTop: "4px" }}
                        />
                      </Box>
                      <Box color="#8B7862" paddingLeft="3px" fontWeight="600">
                        {bookingData.per_night_coin
                          ? bookingData.per_night_coin
                          : 0}{" "}
                        /Night
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box flex={1} color="#565656">
                      Total Booking Nights
                    </Box>
                    <Box fontWeight="600">
                      {moment(bookingData.date_check_out).diff(
                        moment(bookingData.date_check_in),
                        "days"
                      )}{" "}
                      Night
                    </Box>
                  </Box>
                  <hr style={{ color: "#00000026" }} />
                  <Box display="flex">
                    <Box flex={1} fontWeight="bold">
                      Total Payable amount{" "}
                      <Box
                        component="span"
                        style={{ fontSize: "10px", color: "#565656" }}
                      >
                        {bookingData.per_night_inr
                          ? bookingData.per_night_inr
                          : 0}{" "}
                        x{" "}
                        {moment(bookingData.date_check_out).diff(
                          moment(bookingData.date_check_in),
                          "days"
                        )}{" "}
                      </Box>
                    </Box>
                    <Box fontWeight="600">
                      {/* {(() => {
                    let days = moment(bookingData.date_check_out).diff(
                      moment(bookingData.date_check_in),
                      "days"
                    );
                    let value = bookingData.per_night_inr
                      ? bookingData.per_night_inr
                      : 0;

                    return days * value;
                  })()} */}
                      {bookingData.no_coins_used
                        ? bookingData.no_coins_used
                        : 0}{" "}
                      /Nights
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Box fontWeight="bold" flex={1}>
                      Total Payable Brik Coin
                    </Box>
                    <Box display="flex">
                      <Box>
                        <img
                          src={BrikCoin}
                          style={{ height: "16px", paddingTop: "4px" }}
                        />
                      </Box>
                      <Box color="#8B7862" paddingLeft="3px" fontWeight="600">
                        {/* {(() => {
                      let days = moment(bookingData.date_check_out).diff(
                        moment(bookingData.date_check_in),
                        "days"
                      );
                      let value = bookingData.per_night_coin
                        ? bookingData.per_night_coin
                        : 0;

                      return days * value;
                    })()} */}
                        {bookingData.amount_paid ? bookingData.amount_paid : 0}{" "}
                        /Nights
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Paper>
              <Box
                fontSize="17px"
                color="#565656"
                fontWeight="600"
                padding="10px 0"
              >
                {" "}
                Manage your Booking
              </Box>
              <Paper
                onClick={() => {
                  setModal(true);
                }}
                style={{
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                }}
              >
                <Box paddingLeft="17px">
                  <img src={CancelBookingIcon} />
                </Box>
                <Box fontWeight="600" color="#565656" paddingLeft="17px">
                  Cancel your Booking
                </Box>
                <Box flex={1} textAlign="right">
                  <IconButton
                    onClick={() => {
                      window.location.href = "/my-ownership";
                    }}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
              </Paper>
              <Paper
                onClick={() => {
                  setModalGuestDetails(true);
                }}
                style={{
                  cursor: "pointer",
                  margin: "10px 0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box paddingLeft="17px">
                  <img src={ModifyGuestDetailsIcon} />
                </Box>
                <Box fontWeight="600" color="#565656" paddingLeft="17px">
                  Modify Guest Details
                </Box>
                <Box flex={1} textAlign="right">
                  <IconButton
                    onClick={() => {
                      window.location.href = "/my-ownership";
                    }}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
              </Paper>
              <Paper
                style={{
                  margin: "10px 0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box paddingLeft="17px">
                  <img src={CancellationPolicyIcon} />
                </Box>
                <Box fontWeight="600" color="#565656" paddingLeft="17px">
                  Cancellation Policy
                </Box>
                <Box flex={1} textAlign="right">
                  <IconButton
                    onClick={() => {
                      window.location.href = "/my-ownership";
                    }}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
              </Paper>
              <Paper style={{ display: "flex", alignItems: "center" }}>
                <Box paddingLeft="17px">
                  <img src={PropertyPolicyIcon} />
                </Box>
                <Box fontWeight="600" color="#565656" paddingLeft="17px">
                  Property Policies & Rules
                </Box>
                <Box flex={1} textAlign="right">
                  <IconButton
                    onClick={() => {
                      window.location.href = "/my-ownership";
                    }}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* <Box className={responsive.app}>
        <Box
          style={{
            display: "flex",
            padding: "3% 0",
            alignItems: "center",
            display: pay ? "none" : "flex",
          }}
        >
          <Box>
          <Box
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowBackIosIcon />
            </Box>
          </Box>
          <Box fontSize="28px" fontWeight="700">
            Booking Review
          </Box>
        </Box>
        <Box container style={{ display: pay ? "none" : "block" }}>
          <Box display="flex" width="100%">
            <Box>
              <img
                height="100%"
                width="200px"
                src="http://localhost:3000/static/media/Slider1.ebc3c01d.png"
              />
            </Box>
            <Box marginLeft="20px" lineHeight="1.5">
              <Box style={{ fontWeight: "bold", fontSize: "16px" }}>
                Rio De Goa
              </Box>
              <Box display="flex" alignItems="center">
                <RoomIcon style={{ color: "red", fontSize: "18px" }} />
                <Box>Magdgoan,Goa, India</Box>
              </Box>
              <Box
                display="flex"
                fontSize="600"
                color="#8B7862"
                fontWeight="600"
                padding="5px 0px"
              >
                <Avatar
                  src={BrikCoin}
                  style={{ height: "20px", width: "20px" }}
                />{" "}
                &nbsp;2/Night &nbsp;
                <Box component="span" style={{ color: "#565656" }}>
                  (4000/Night)
                </Box>
              </Box>
              <Box display="flex">
                <Box
                  display="flex"
                  alignItems="center"
                  style={{
                    backgroundColor: "#00A355",
                    color: "white",
                    borderRadius: "5px",
                    fontSize: "12px",
                    padding: "2px 10px",
                  }}
                  component="span"
                >
                  <Box>4.6&nbsp;&nbsp;</Box>
                  <StarIcon
                    style={{
                      height: "14px",
                      width: "14px",
                      color: "#FFC107",
                    }}
                  />
                </Box>
                <Box color="#8B7862">&nbsp;58 Rating, 23 Reviews</Box>
              </Box>
            </Box>
          </Box>
          <hr />
          <Box
            style={{
              marginTop: "18px",
              padding: "13px 15px",
              borderRadius: "7px",
            }}
          >
            <Box
              width="100%"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Box color="#4A8EAB" fontSize="13px" paddingBottom="5px">
                  Check-in date
                </Box>
                <Box
                  style={{
                    borderColor: "#C4C4C4",
                    padding: "15px 35px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "7px",
                    fontWeight: "600",
                  }}
                >
                  13 Apr 2022
                </Box>
              </Box>
              <Box
                style={{
                  padding: "7px 19px",
                  marginTop: "18px",
                  color: "#124765",
                  backgroundColor: "#BDE7FF",
                  borderRadius: "6px",
                  fontWeight: "600",
                }}
              >
                6 Nights
              </Box>
              <Box>
                <Box color="#4A8EAB" fontSize="13px" paddingBottom="5px">
                  Check-out date
                </Box>
                <Box
                  style={{
                    borderColor: "#C4C4C4",
                    padding: "15px 35px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "7px",
                    fontWeight: "600",
                  }}
                >
                  13 Apr 2022
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <hr />
        <Accordion style={{ display: pay ? "none" : "block" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box display="flex" width="100%">
              <Box flex={1} fontWeight="700" fontSize="14px" paddingLeft="10px">
                Property Policies & Rules
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              At BRIKitt, we’re on a mission to enrich lives through second home
              ownership. For Proud BRIK owners, a second home brings great pride
              and a commitment to being a conscientious, respectful owner and
              good neighbor. Together, BRIK owners protect and enhance the
              experience of their fellow owners and the local community. BRIK
              owners agree to abide by the following policies:
            </Box>
            <Box>
              <ul>
                <li>
                  BRIKitt homes are designed solely for the personal use and
                  enjoyment of owners family and their personal guests families.
                  No Stag stays are allowed at any of the BRIKitt Properties.
                </li>
                <li>
                  Large events or parties that would cause disruption for the
                  neighborhood are prohibited.
                </li>
                <li>Quiet hours are observed from 9 p.m. to 7 a.m.</li>
                <li>
                  Owners may bring up to One dog onto the property. Dogs cannot
                  weigh more than 80 pounds each and no other animals are
                  allowed (except certified assistance animals).
                </li>
                <li>
                  BRIKitt owners are strongly encouraged to avoid parking on the
                  street unless absolutely necessary.
                </li>
                <li>
                  No trash or garbage is allowed to accumulate, and garbage
                  containers must be stored out of sight except on collection
                  day.
                </li>
              </ul>
            </Box>
            <Box>
              Violations of BRIK owner policies may result in monetary penalties
              or a temporary suspension of stay rights.
            </Box>
          </AccordionDetails>
        </Accordion>
        <Paper
          style={{
            padding: "10px",
            color: "#565656",
            display: pay ? "none" : "block",
          }}
        >
          <Box lineHeight="2">
            <Box fontSize="17px" fontWeight="600" padding="2px 0">
              Price Summary
            </Box>
            <Box display="flex">
              <Box flex={1} color="#565656">
                Property Charge/Night
              </Box>
              <Box fontWeight="600">4000/Night</Box>
            </Box>
            <Box display="flex">
              <Box flex={1} color="#565656">
                In Brikk coin
              </Box>
              <Box display="flex">
                <Box>
                  <img
                    src={BrikCoin}
                    style={{ height: "16px", paddingTop: "4px" }}
                  />
                </Box>
                <Box color="#8B7862" paddingLeft="3px" fontWeight="600">
                  2/Nights
                </Box>
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1} color="#565656">
                Total Booking Nights
              </Box>
              <Box fontWeight="600">6 Nights</Box>
            </Box>
            <hr />
            <Box display="flex">
              <Box flex={1} fontWeight="bold">
                Total Payable amount{" "}
                <Box
                  component="span"
                  style={{ fontSize: "10px", color: "#565656" }}
                >
                  (6X4000)
                </Box>
              </Box>
              <Box fontWeight="600">24000/Nights</Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Box fontWeight="bold" flex={1}>
                Total Payable Brik Coin
              </Box>
              <Box display="flex">
                <Box>
                  <img
                    src={BrikCoin}
                    style={{ height: "16px", paddingTop: "4px" }}
                  />
                </Box>
                <Box color="#8B7862" paddingLeft="3px" fontWeight="600">
                  2/Nights
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
        <Accordion style={{ display: pay ? "none" : "block" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box display="flex" width="100%">
              <Box flex={1} fontWeight="700" fontSize="14px" paddingLeft="10px">
                Guest Details
              </Box>
              {mode == "read" ? (
                <Box
                  color="#4A8EAB"
                  onClick={(e) => {
                    setMode("edit");
                    e.stopPropagation();
                  }}
                >
                  Edit
                </Box>
              ) : (
                <Box
                  color="#4A8EAB"
                  onClick={(e) => {
                    setMode("read");
                    e.stopPropagation();
                  }}
                >
                  Done
                </Box>
              )}
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            {mode == "read" ? (
              <Box
                width="50%"
                flexDirection="column"
                padding="0 11px"
                style={{ width: "100%" }}
              >
                <Box lineHeight="2">
                  <Box display="flex" width="100%">
                    <Box flex={1} color="#979797">
                      {" "}
                      FULL NAME
                    </Box>
                    <Box fontWeight="600" flex={1}>
                      {" "}
                      Arjun Kumar
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row" width="100%">
                    <Box flex={1} color="#979797">
                      {" "}
                      MOBILE NUMBER
                    </Box>
                    <Box fontWeight="600" flex={1}>
                      {" "}
                      +91 9876543210
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row" width="100%">
                    <Box flex={1} color="#979797">
                      {" "}
                      EMAIL ID
                    </Box>
                    <Box fontWeight="600" flex={1}>
                      {" "}
                      arjunkumarkk676@gmail.com
                    </Box>
                  </Box>
                </Box>
                <Box color="#565656" fontSize="12px" padding="10px 0px">
                  We’ll send your booking information on your mobile & email.
                </Box>
                <Box display="flex" flexDirection="column" width="100%">
                  <Box flex={1} color="#979797" padding="6px 0">
                    {" "}
                    DESCRIPTION
                  </Box>
                  <Box lineHeight="1.6">
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Tincidunt fermentum, urna dolor elementum a volutpat
                    pellentesque. Diam turpis platea auctor odio integer.
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                width="100%"
                flexDirection="column"
                padding="0 11px"
                style={{ width: "100%" }}
              >
                <Box lineHeight="2">
                  <Box>
                    <Box fontWeight="600" paddingBottom="3px">
                      FULL NAME
                    </Box>
                    <Box>
                      <TextField
                        id="name"
                        name="name"
                        placeholder="Enter Full Name"
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box padding="10px 0">
                    <Box fontWeight="600" paddingBottom="3px">
                      MOBILE NUMBER
                    </Box>
                    <Box>
                      <TextField
                        id="name"
                        name="name"
                        placeholder="Enter Full Name"
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Box fontWeight="600" paddingBottom="3px">
                      EMAIL ID
                    </Box>
                    <Box>
                      <TextField
                        id="name"
                        name="name"
                        placeholder="Enter Full Name"
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Box>
                <Box color="#565656" fontSize="12px" padding="10px 0px">
                  We’ll send your booking information on your mobile & email.
                </Box>
                <Box padding="10px 0">
                  <Box fontWeight="600" paddingBottom="3px">
                    ADD ANY INSTRUCTION IF ANY
                  </Box>
                  <Box>
                    <TextField
                      id="name"
                      name="name"
                      placeholder="Type Here"
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
        <Box style={{ display: pay ? "block" : "none" }}>
          <Box
            style={{
              backgroundColor: "#FEE0BE",
              display: "flex",
              padding: "3% 0",
              alignItems: "center",
              display: pay ? "flex" : "none",
            }}
          >
            <Box>
              <IconButton size="small">
                <ArrowBackIosIcon
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </IconButton>
            </Box>
            <Box fontSize="28px" fontWeight="700">
              Payment
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#FEE0BE",
              padding: "3% 0",
              alignItems: "center",
              display: pay ? "flex" : "none",
            }}
          >
            <Box>
              <img
                height="100%"
                width="200px"
                src="http://localhost:3000/static/media/Slider1.ebc3c01d.png"
              />
            </Box>
            <Box marginLeft="20px" lineHeight="1.5">
              <Box style={{ fontWeight: "bold", fontSize: "16px" }}>
                Rio De Goa
              </Box>
              <Box display="flex" alignItems="center">
                <RoomIcon style={{ fontSize: "18px" }} />
                <Box>Magdgoan,Goa, India</Box>
              </Box>
              <Box>13 April,2022 - 19 April,2022</Box>
            </Box>
          </Box>
          <Box
            style={{
              fontSize: "18px",
              fontWeight: "600",
              padding: "20px 0px 10px 0",
            }}
          >
            Select Payment Method
          </Box>
          <Box display="flex" width="100%">
            <Box flex={1} fontWeight="700" fontSize="14px" paddingLeft="10px">
              Recommended
            </Box>
          </Box>
          <Box>
            <Box style={{ display: "flex", alignItems: "baseline" }}>
              <Box>
                <Radio
                  checked={selectedValue === "a"}
                  onChange={handleChange}
                  value="a"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />
              </Box>
              <Box padding="0 10px">
                <Box display="flex">
                  <Box>
                    <img src={BrikCoin} style={{ width: "20px" }} />
                  </Box>
                  <Box paddingLeft="10px" color="#8B7862" fontWeight="600">
                    Brikitt Coin
                  </Box>
                </Box>
                <Box paddingLeft="30px" display="flex" padding="3px 0">
                  <Box paddingRight="9px" color="#9B9B9B">
                    Balance:
                  </Box>
                  <Box>
                    <img
                      src={BrikCoin}
                      style={{ width: "15px", paddingTop: "3px" }}
                    />
                  </Box>
                  <Box color="#B88952">08</Box>
                </Box>
                <Box paddingLeft="30px" display="flex" color="#9B9B9B">
                  <Box>Minimum recharge required:</Box>
                  <Box paddingLeft="6px">
                    <img
                      src={BrikCoin}
                      style={{ width: "15px", paddingTop: "3px" }}
                    />
                  </Box>
                  <Box color="#B88952">04</Box>
                  <Box color="#9B9B9B">(8000)</Box>
                </Box>
              </Box>
            </Box>
            <Box
              padding="4px 8px"
              margin="11px 0"
              style={{ backgroundColor: "#4A8EAB16" }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Ullamcorper commodo, at imperdiet feugiat commod.
            </Box>
            <Box>
              <Button variant="contained" color="secondary" fullWidth>
                Add Money & Pay
              </Button>
            </Box>
          </Box>
        </Box>

        <Box margin="15px 0" style={{ display: pay ? "block" : "none" }}>
          <Paper>
            <Box padding="10px 25px" fontWeight="600">
              Pay the remaining amount via
            </Box>
            <Box>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box display="flex" width="100%" alignItems="center">
                    <Box>
                      <Radio
                        checked={selectedValue === "b"}
                        onChange={handleChange}
                        value="a"
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "B" }}
                      />
                    </Box>
                    <Box
                      // flex={1}
                      fontWeight="700"
                      fontSize="14px"
                    >
                      UPI
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>abc</Box>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box display="flex" width="100%" alignItems="center">
                    <Box>
                      <Radio
                        checked={selectedValue === "c"}
                        onChange={handleChange}
                        value="a"
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "C" }}
                      />
                    </Box>
                    <Box fontWeight="700" fontSize="14px">
                      Credit Card/Debit Card
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>abc</Box>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box display="flex" width="100%" alignItems="center">
                    <Box>
                      <Radio
                        checked={selectedValue === "d"}
                        onChange={handleChange}
                        value="a"
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "D" }}
                      />
                    </Box>
                    <Box
                      // flex={1}
                      fontWeight="700"
                      fontSize="14px"
                    >
                      Net Banking
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>abc</Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Paper>
        </Box>
        <Box
          padding="3%"
          fixed
          bottom="0%"
          style={{
            display: pay ? "none" : "flex",
            justifyContent: "space-between",
          }}
        >
          <Box alignItems="left">
            <Box display="flex">
              <Box>
                <img
                  src={BrikCoin}
                  style={{ height: "16px", paddingTop: "4px" }}
                />
              </Box>
              <Box color="#8B7862" paddingLeft="3px" fontWeight="600">
                2/Nights
              </Box>
              <Box color="#8B7862" fontWeight="600">
                (4000/Night)
              </Box>
            </Box>
            <Box color="#8B7862" fontWeight="600">
              14 Sep - 19 Sep
            </Box>
          </Box>
          <Button
            alignItems="right"
            variant="contained"
            color="#4A8EAB"
            onClick={() => {
              setPay(true);
            }}
          >
            NEXT
          </Button>
        </Box>
        <Box
          padding="3%"
          fixed
          bottom="0%"
          style={{
            display: pay ? "flex" : "none",
            justifyContent: "space-between",
          }}
        >
          <Box alignItems="left">
            <Box display="flex">
              <Box>
                <img
                  src={BrikCoin}
                  style={{ height: "16px", paddingTop: "4px" }}
                />
              </Box>
              <Box color="#8B7862" paddingLeft="3px" fontWeight="600">
                12 Brikitt Coin
              </Box>
            </Box>
            <Box color="#8B7862" fontWeight="600">
              &nbsp;(&nbsp;&#8377;24000)
            </Box>
            <Box color="black" fontWeight="700">
              View Details
            </Box>
          </Box>
          <Button
            alignItems="left"
            variant="contained"
            color="#4A8EAB"
            onClick={() => {}}
          >
            ADD MONEY AND PAY
          </Button>
        </Box>
      </Box> */}
    </>
  );
}

export default BookingDetails;
