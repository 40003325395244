import { useSnackbar } from "notistack";
import React, { createContext, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAuthToken, getNotificationsList } from "../store/actions/apis";
import {
  getUserData,
  getToken,
  setComponentBusyState,
  setUserData,
  resetNotificationCounter,
  setNotificationList,
} from "../store/actions/userActions";
import { getWalletBalance } from "../store/actions/walletAction";

const AuthContext = createContext({
  login: () => {},
  getUser: () => {},
  getUserToken: () => {},
});

function AuthProvider(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const notification = useSnackbar();

  useEffect(() => {
    // if(localStorage.getItem('token'))
    //     dispatch(getUserData({id:1}))
  }, []);

  const getUser = () => {
    let userData = localStorage.getItem("user");

    return JSON.parse(userData);
  };

  const getUserId = () => {
    let userData = localStorage.getItem("user");

    return JSON.parse(userData).user_id;
  };

  const getUserToken = () => {
    return localStorage.getItem("token");
  };

  const setUser = () => {
    localStorage.clear();
  };

  const login = (data) => {
    dispatch(setComponentBusyState(true));

    return getAuthToken(data)
      .then((result) => {
        localStorage.setItem("token", result.token);
        const {
          name,
          user_id,
          email,
          profile_pic,
          terms_accepted,
          document_id,
        } = result;
        localStorage.setItem(
          "user",
          JSON.stringify({
            name,
            user_id,
            email,
            profile_pic,
            terms_accepted,
            document_id,
          })
        );
        dispatch(setUserData(result));
        dispatch(setComponentBusyState(false));
        getNotificationsList().then((result) => {
          var count = result.data.reduce(function (n, val) {
            return n + (val.read === false);
          }, 0);

          dispatch(resetNotificationCounter(count));
          dispatch(setNotificationList(result.data));
        });
        dispatch(getWalletBalance());
        notification.enqueueSnackbar("Logged In Successfully", {
          variant: "success",
        });
      })
      .catch((er) => {
        notification.enqueueSnackbar("Please Check Credentials", {
          variant: "error",
        });

        dispatch(setComponentBusyState(false));
      });
    // dispatch(getToken(data))

    // dispatch(getUserData({id:id}))
    // window.location.href="/home"
  };

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <AuthContext.Provider
      value={{ login, logout, getUser, getUserToken, getUserId, setUser }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);

  // if(context){
  //     return "useAuth must be used inside AuthProvider "
  // }else
  return context;
};

export default AuthProvider;
