import firebase from "firebase/app";
import "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: "AIzaSyAScB_omUCYlpolnkDx8uOm5AdI-vfv1wQ",
  // authDomain: "push-notifications-8d1cb.firebaseapp.com",
  // projectId: "push-notifications-8d1cb",
  // storageBucket: "push-notifications-8d1cb.appspot.com",
  // messagingSenderId: "228600270170",
  // appId: "1:228600270170:web:20bb94ea8abffe3e2a3763",
  // measurementId: "G-5447YCCMSB"

  //dev
  // apiKey: "AIzaSyAEOQEkjL_iSWZxj2efU21EI5Fm6r25VI0",
  // authDomain: "brikket-7d6c4.firebaseapp.com",
  // projectId: "brikket-7d6c4",
  // storageBucket: "brikket-7d6c4.appspot.com",
  // messagingSenderId: "548428406489",
  // appId: "1:548428406489:web:5ef9ab536c3d080ac3e8c8",

  //prduction
  apiKey: "AIzaSyCS4nrQqdF7Yk0tlKoJhOBL7icVbzLc_3k",
  authDomain: "brikkit-95462.firebaseapp.com",
  projectId: "brikkit-95462",
  storageBucket: "brikkit-95462.appspot.com",
  messagingSenderId: "621284823319",
  appId: "1:621284823319:web:d49b8b005a4de12d812752",
  measurementId: "G-6SRS4HNCEW",
};

let messaging = null;
let allowedNotification = false;

if ("Notification" in window && window.location.protocol == "https:") {
  allowedNotification = true;
}

console.log("Firebase Notificaiton Allowed: ", allowedNotification);
if (allowedNotification) {
  firebase.initializeApp(firebaseConfig);

  if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
  }
}

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
