import { SET_USER, GET_USER, IS_COMPONENT_BUSY,RESET_NOTIFICATION_COUNTER, SET_NOTIFICATION, SET_NOTIFICATION_LIST} from "../constants";
import { fetchUserData, getAuthToken } from "./apis";


export const setUserData = (data) => {
    return {
        type: SET_USER,
        payload: data
    }
}

export const setComponentBusyState = (data) => {
    return {
        type: IS_COMPONENT_BUSY,
        payload: data
    }
}

export const getToken = (data) => {
    return (dispatch) => {
        dispatch(setComponentBusyState(true))

        return getAuthToken(data).then(result => {
            localStorage.setItem("token", result.token)
            const {name,user_id,email}=result
            localStorage.setItem("user", JSON.stringify({name,user_id,email}))
            dispatch(setUserData(result))
            dispatch(setComponentBusyState(false))
        }).catch(er => {
            
            dispatch(setComponentBusyState(false))
        })
    }
}

export const pushNotification=(data)=>{
    return {
        type:SET_NOTIFICATION,
        payload:data
    }
}

export const resetNotificationCounter=(data=0)=>{
    return {
        type:RESET_NOTIFICATION_COUNTER,
        payload:data
    }
}

export const setNotificationList=(data)=>{
    return {
        type:SET_NOTIFICATION_LIST,
        payload:data
    }
}

export const getUserData = (data) => {
    return (dispatch) => {
        dispatch(setComponentBusyState(true))
        return fetchUserData(data).then(result => {
            // console.log("FEATCNH: ", result)
            // let user = {
            //     id: data,
            //     name: "Mukul",
            //     last_name: "Jain",
            //     token: "xyz"
            // }
            // localStorage.setItem("token", user.token)
            // dispatch(setUserData(user))
            dispatch(setComponentBusyState(false))
        })
    }
}
