import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import Header from "./Header";
import { Avatar, Box, Card, Button } from "@material-ui/core";
import { icons, Logo } from "../assets/assets";
import { useGeneralStyles } from "../assets/css/generalStyling";
import { useHistory } from "react-router-dom";
import image from "../layouts/Group.svg";
// import BackIcon from '@material-ui/icons/Background.svg';
import BackgroundImgae from "../assets/icons/bgcontact.png";
import Gold from "../assets/icons/Gold.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootListItem: {
    color: "#8B7862",
    // textAlign:"center"
  },
  selectedListItem: {
    color: "white",
    background:
      "linear-gradient(272.56deg, #77644E 0%, rgba(139, 120, 98, 0.75) 100%)",
    borderRadius: "27px",
    "&:hover": {
      borderRadius: "27px !important",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "center",
    // justifyContent: 'flex-end',
  },
  content: {
    backgroundColor: "#434343",
    flexGrow: 1,
    minHeight: "100vh",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  mobWidth: {
    position: "relative",
    backgroundColor: "white",
    height: "100%",
    padding: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    mobWidth: { width: "100vw !important" },
  },
}));

function DashboardLayoutWeb({ children }) {
  const classes = useStyles();
  const styles = useGeneralStyles();
  const theme = useTheme();
  const history = useHistory();
  const [open, setOpen] = React.useState(true);
  const [selected, setSelected] = useState(1);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    switch (history.location.pathname.split("/")[1]) {
      case "explore":
        setSelected(1);
        break;
      case "my-ownership":
        setSelected(2);
        break;
      case "detail":
        setSelected(2);
        break;
      case "account":
        setSelected(3);
        break;
      case "faq":
        setSelected(4);
        break;
      case "booking":
        setSelected(5);
        break;
      default:
        setSelected(1);
    }
    console.log("history: ", history.location.pathname.split("/")[1]);
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Box
            padding={3}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar
              style={{
                border: "1px solid white",
                borderRadius: "10px",
                padding: "5px",
                backgroundColor: "#FFFFFF",
              }}
              src={Logo}
            />
            <Box
              style={{ padding: "0px 10px", color: "#FFFFFF", fontWeight: 500 }}
            >
              BRIK ITT
            </Box>
          </Box>
          {/* <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton> */}
        </div>
        {/* <Divider /> */}
        <List style={{ padding: "20px", marginTop: "30px" }}>
          <ListItem
            onClick={() => {
              window.open("https://brikitt.com/projects");
            }}
            button
            selected={1 == selected}
            classes={{
              selected: classes.selectedListItem,
              root: classes.rootListItem,
            }}
          >
            {1 == selected ? (
              <ListItemIcon>
                <Avatar
                  style={{ height: "20px", width: "20px" }}
                  src={icons.exploreWhite}
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <Avatar
                  style={{ height: "20px", width: "20px" }}
                  src={icons.explore}
                />
              </ListItemIcon>
            )}
            <ListItemText primary="Explore" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setSelected(2);
              history.push("/my-ownership");
            }}
            selected={2 == selected}
            classes={{
              selected: classes.selectedListItem,
              root: classes.rootListItem,
            }}
          >
            {2 == selected ? (
              <ListItemIcon>
                <Avatar
                  style={{ height: "20px", width: "20px" }}
                  src={icons.myOwnershipWhite}
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <Avatar
                  style={{ height: "20px", width: "20px" }}
                  src={icons.myOwnership}
                />
              </ListItemIcon>
            )}
            <ListItemText primary="My Ownership" />
          </ListItem>

          <ListItem
            button
            onClick={() => {
              setSelected(5);
              history.push("/booking");
            }}
            selected={5 == selected}
            classes={{
              selected: classes.selectedListItem,
              root: classes.rootListItem,
            }}
          >
            {5 == selected ? (
              <ListItemIcon>
                <Avatar
                  style={{ height: "20px", width: "20px" }}
                  src={icons.Booking}
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <Avatar
                  style={{ height: "20px", width: "20px" }}
                  src={icons.Booking}
                />
              </ListItemIcon>
            )}
            <ListItemText>Bookings</ListItemText>
          </ListItem>

          <ListItem
            button
            onClick={() => {
              setSelected(3);
              history.push("/account");
            }}
            selected={3 == selected}
            classes={{
              selected: classes.selectedListItem,
              root: classes.rootListItem,
            }}
          >
            {3 == selected ? (
              <ListItemIcon>
                <Avatar
                  style={{ height: "20px", width: "20px" }}
                  src={icons.accountWhite}
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <Avatar
                  style={{ height: "20px", width: "20px" }}
                  src={icons.account}
                />
              </ListItemIcon>
            )}

            <ListItemText primary="Account" />
          </ListItem>
          <ListItem
            button
            selected={4 == selected}
            onClick={() => {
              window.open("https://www.brikitt.com/faq-contact");
            }}
            classes={{
              selected: classes.selectedListItem,
              root: classes.rootListItem,
            }}
          >
            {4 == selected ? (
              <ListItemIcon>
                <Avatar
                  style={{ height: "20px", width: "20px" }}
                  src={icons.faqWhite}
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <Avatar
                  style={{ height: "20px", width: "20px" }}
                  src={icons.faq}
                />
              </ListItemIcon>
            )}
            <ListItemText primary="FAQ" />
          </ListItem>
        </List>

        {/* <Box padding="12px 30px">
          <Card
            style={{
              padding: "20px",
              borderRadius: "10px",
              background: `url(${BackgroundImgae})`,
              backgroundPosition: "center",
            }}
          >
            <Box fontWeight="600" color="#8B7862">
              Contact
            </Box>
            <Box
              padding="10px 0"
              fontSize="11px"
              lineHeight="1.7"
              color="#B9B0A6"
            >
              We are ready to help you to anytime Contact us
            </Box>
            <Button
              onClick={() => {
                window.location.href = "https://www.brikitt.com/faq-contact";
              }}
              variant="contained"
              width="20px"
              height="20px"
              style={{
                color: "white",
                background:
                  "linear-gradient(272.56deg, #77644E 0%, rgba(139, 120, 98, 0.75) 100%)",
                borderRadius: "10px",
                fontSize: "12px",
              }}
            >
              Start
            </Button>
            <Box style={{ padding: "19px 0px 0px 25px", width: "105px" }}>
              <img src={image} style={{ width: "80px" }} />
            </Box>
          </Card>
        </Box> */}
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <Box className={classes.mobWidth}>
          <Header type="web" open={open} handleDrawerOpen={handleDrawerOpen} />
          {children}
        </Box>
      </main>
    </div>
  );
}

export default DashboardLayoutWeb;
