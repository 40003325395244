import React, { useState, useEffect } from 'react';
import { FormControl, TextField, Popper, Paper, MenuItem, FormHelperText, ClickAwayListener } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';

const CustomSearchComponent = ({
  searchBriks,
  selectedBrik,
  setSelectedBrik,
  debouncedFetchBriks,
  selectedValue,
  setFilter,
  setLocation,
  setDatePickerModal,
  value,
  handleSelectedBrik
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Modified filtering logic to show all suggestions when input is empty
  const filteredBriks = Array.from(
    new Set(
      searchBriks
        .map(brik => brik?.property_name)
        .filter(Boolean)
    )
  ).filter(brikName => 
    searchValue === '' ? true : brikName.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleSearch = () => {
    const filters = {
      date_check_in: moment(selectedValue[0]).format("YYYYMMDD"),
      date_check_out: moment(selectedValue[1]).format("YYYYMMDD"),
      is_self: value === "my_properties",
      q: searchValue || "all"
    };
    setFilter(filters);
    setLocation(searchValue);
    setDatePickerModal(true);
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
    setIsDropdownOpen(true);
   
  };

  const handleInputFocus = (event) => {
    setAnchorEl(event.currentTarget);
    debouncedFetchBriks()
    setIsDropdownOpen(true);
  };

  const handleSelectBrik = (brik) => {
    setSelectedBrik(brik);
    setSearchValue(brik);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    if (selectedBrik) {
      handleSearch();
      handleSelectedBrik(selectedBrik);
    }
  }, [selectedBrik]);

  return (
    <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
      <FormControl fullWidth sx={{ mt: 2, position: 'relative' }}>
        <TextField
          id="briks"
          variant="outlined"
          value={searchValue}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          placeholder="Search for a brik"
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <SearchIcon
                // onClick={handleSearch}
                sx={{ cursor: '' }}
              />
            )
          }}
        />
        <Popper 
          open={isDropdownOpen} 
          anchorEl={anchorEl} 
          placement="bottom-start" 
          style={{ width: anchorEl ? anchorEl.clientWidth : 'auto', zIndex: 1300 }}
        >
          <Paper elevation={3}>
            {filteredBriks.map((brikName) => (
              <MenuItem key={brikName} onClick={() => handleSelectBrik(brikName)}>
                {brikName}
              </MenuItem>
            ))}
          </Paper>
        </Popper>
        <FormHelperText>Select a brik</FormHelperText>
      </FormControl>
    </ClickAwayListener>
  );
};

export default CustomSearchComponent;