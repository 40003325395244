import Logo from "../assets/Logo.png";
import exploreIcon from "../assets/icons/explore.png";
import AccountIcon from "../assets/icons/account icon.svg";
import ExploreIcon from "../assets/icons/Explore.svg";
import MyOwnershipIcon from "../assets/icons/My ownership.svg";
import FAQIcon from "../assets/icons/FAQ.svg";
import CarParking from "../assets/aminities/Car parking.svg";
import Gym from "../assets/aminities/Gym.svg";
import Pool from "../assets/aminities/ic-baseline-pool.svg";
import Wifi from "../assets/aminities/wifi.svg";
import BrikCoin from "../assets/BrikCoin.svg";
import TV from "../assets/aminities/TV.svg";

import AccountWhite from "../assets/icons/account white.svg";
import ExploreWhite from "../assets/icons/Explore white.svg";
import profileBlackIcon from "../assets/icons/profile 2.svg";
import exploreBlackIcon from "../assets/icons/explore 2.svg";
import MyOwnershipWhite from "../assets/icons/My ownership white.svg";
import MyOwnershipBlack from "../assets/icons/my ownership1.svg";
import FAQWhite from "../assets/icons/FAQ white.svg";
import Booking from "../assets/icons/Booking.png";

export const icons = {
  explore: ExploreIcon,
  exploreWhite: ExploreWhite,
  myOwnership: MyOwnershipIcon,
  myOwnershipWhite: MyOwnershipWhite,
  profileBlack: profileBlackIcon,
  ownershipBlack: MyOwnershipBlack,
  exploreBlackIcon: exploreBlackIcon,
  account: AccountIcon,
  accountWhite: AccountWhite,
  faq: FAQIcon,
  faqWhite: FAQWhite,
  Booking: Booking,
};

export const amenities = {
  "Wi-FI": Wifi,
  TV: TV,
};
export { Logo, BrikCoin };
