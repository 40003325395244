import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import { icons, Logo } from "../assets/assets";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { Box, Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useAuth } from "../providers/AuthProvider";
import { useHistory } from "react-router-dom";
import Header from "./Header";
import exploreIcon from "../assets/icons/Explore.svg";
import MyOwnership from "../assets/icons/My ownership.svg";
import acountIcon from "../assets/icons/account icon.svg";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootListItem: {
    color: "#8B7862",
    // textAlign:"center"
  },
  selectedListItem: {
    color: "white",
    background:
      "linear-gradient(272.56deg, #77644E 0%, rgba(139, 120, 98, 0.75) 100%)",
    borderRadius: "27px",
    "&:hover": {
      borderRadius: "27px !important",
    },
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
  },
}));

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  selectedBottomNavigation: {
    color: "#8B7862 !important",
  },
  BottomNavigation: {
    position: "absolute",
    width: "100%",
    bottom: "0px",
    display: "none",
  },
  [theme.breakpoints.down("sm")]: {
    BottomNavigation: {
      display: "block",
    },
  },
}));

function MainDrawerApp({ children, ...props }) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const auth = useAuth();
  const classes2 = useStyles2();
  const history = useHistory();
  const [selected, setSelected] = React.useState(1);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // const container = window !== undefined ? () => window().document.body : undefined;

  const handleDrawerOpen = () => {
    setMobileOpen(true);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  React.useEffect(() => {
    switch (history.location.pathname.split("/")[1]) {
      case "explore":
        setSelected(1);
        break;
      case "my-ownership":
        setSelected(2);
        setValue(2);
        break;
      case "detail":
        setSelected(2);
        break;
      case "account":
        setSelected(3);
        setValue(3);
        break;
      case "faq":
        setSelected(4);
        break;
      case "bookings":
        setSelected(5);
        break;
      default:
        setSelected(1);
    }
    console.log("history: ", history.location.pathname.split("/")[1]);
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header
        type="app"
        open={mobileOpen}
        handleDrawerOpen={handleDrawerToggle}
      />

      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        {/* <Hidden smUp implementation="css"> */}
        <Drawer
          // container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className={classes.drawerHeader} style={{ textAlign: "end" }}>
            <Box display="flex" alignItems="center">
              <Box flex={1} textAlign="left" paddingLeft="30px" color="white">
                {auth.getUser() !== null && `Welcome ${auth.getUser().name}`}
              </Box>
              <Box>
                <IconButton
                  onClick={handleDrawerToggle}
                  style={{ color: "white" }}
                >
                  {theme.direction === "ltr" ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </IconButton>
              </Box>
            </Box>
          </div>
          <Divider />
          <List style={{ padding: "20px", marginTop: "30px" }}>
            <ListItem
              onClick={() => {
                // setSelected(1)
                window.open("https://brikitt.com");
              }}
              button
              selected={1 == selected}
              classes={{
                selected: classes.selectedListItem,
                root: classes.rootListItem,
              }}
            >
              {1 == selected ? (
                <ListItemIcon>
                  <Avatar
                    style={{ height: "20px", width: "20px" }}
                    src={icons.exploreWhite}
                  />
                </ListItemIcon>
              ) : (
                <ListItemIcon>
                  <Avatar
                    style={{ height: "20px", width: "20px" }}
                    src={icons.explore}
                  />
                </ListItemIcon>
              )}
              <ListItemText primary="Explore" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                if (auth.getUser() !== null) {
                  setSelected(2);
                  history.push("/my-ownership");
                }
              }}
              selected={2 == selected}
              classes={{
                selected: classes.selectedListItem,
                root: classes.rootListItem,
              }}
            >
              {2 == selected ? (
                <ListItemIcon>
                  <Avatar
                    style={{ height: "20px", width: "20px" }}
                    src={icons.myOwnershipWhite}
                  />
                </ListItemIcon>
              ) : (
                <ListItemIcon>
                  <Avatar
                    style={{ height: "20px", width: "20px" }}
                    src={icons.myOwnership}
                  />
                </ListItemIcon>
              )}
              <ListItemText primary="My Ownership" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setSelected(5);
                history.push("/booking");
              }}
              selected={5 == selected}
              classes={{
                selected: classes.selectedListItem,
                root: classes.rootListItem,
              }}
            >
              {5 == selected ? (
                <ListItemIcon>
                  <Avatar
                    style={{ height: "20px", width: "20px" }}
                    src={icons.Booking}
                  />
                </ListItemIcon>
              ) : (
                <ListItemIcon>
                  <Avatar
                    style={{ height: "20px", width: "20px" }}
                    src={icons.Booking}
                  />
                </ListItemIcon>
              )}
              <ListItemText>Bookings</ListItemText>
            </ListItem>
            <ListItem
              button
              onClick={() => {
                if (auth.getUser() !== null) {
                  setSelected(3);
                  history.push("/account");
                }
              }}
              selected={3 == selected}
              classes={{
                selected: classes.selectedListItem,
                root: classes.rootListItem,
              }}
            >
              {3 == selected ? (
                <ListItemIcon>
                  <Avatar
                    style={{ height: "20px", width: "20px" }}
                    src={icons.accountWhite}
                  />
                </ListItemIcon>
              ) : (
                <ListItemIcon>
                  <Avatar
                    style={{ height: "20px", width: "20px" }}
                    src={icons.account}
                  />
                </ListItemIcon>
              )}
              <ListItemText primary="Account" />
            </ListItem>
            <ListItem
              button
              selected={4 == selected}
              onClick={() => {
                window.location.href = "https://www.brikitt.com/faq-contact";
              }}
              // onClick={() => {
              //   setSelected(4)
              //   history.push('/faq')
              // }}
              classes={{
                selected: classes.selectedListItem,
                root: classes.rootListItem,
              }}
            >
              {4 == selected ? (
                <ListItemIcon>
                  <Avatar
                    style={{ height: "20px", width: "20px" }}
                    src={icons.faqWhite}
                  />
                </ListItemIcon>
              ) : (
                <ListItemIcon>
                  <Avatar
                    style={{ height: "20px", width: "20px" }}
                    src={icons.faq}
                  />
                </ListItemIcon>
              )}

              <ListItemText primary="FAQ" />
            </ListItem>
          </List>
          {auth.getUser()?.user_id && (
            <Box
              style={{
                padding: "13px",
                position: "absolute",
                bottom: "0px",
                width: "100%",
              }}
            >
              <Button
                onClick={() => {
                  auth.logout();
                }}
                color="secondary"
                variant="contained"
                fullWidth
              >
                Sign Out
              </Button>
            </Box>
          )}
          {auth.getUser()?.user_id && (
            <Box
              style={{
                padding: "13px",
                position: "absolute",
                bottom: "60px",
                width: "100%",
              }}
            >
              <Button
                onClick={() => {
                  history.push("/changepassword")
                }}
                color="secondary"
                variant="contained"
                fullWidth
              >
                Change Password
              </Button>
            </Box>
          )}
        </Drawer>
        {/* </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden> */}
      </nav>
      <main className={classes.content}>
        <Box
          style={{
            maxHeight: "85vh",
            overflowX: "hidden",
            width: "100vw",
            overflowY: "auto",
            // padding: "10px"
          }}
        >
          {children}
        </Box>

        <Box className={classes2.BottomNavigation}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              switch (newValue) {
                case 2:
                  setSelected(2);
                  history.push("/my-ownership");
                  break;

                case 3:
                  setSelected(3);
                  history.push("/account");
                  break;

                default:
                  setSelected(1);
                  history.push("/");
              }
              setValue(newValue);
            }}
            className={classes2.root}
          >
            <BottomNavigationAction
              // style={{ color: "#ACACAC" }}
              onClick={() => {
                window.open("https://www.brikitt.com/projects");
              }}
              classes={{ selected: classes2.selectedBottomNavigation }}
              label="Explore"
              icon={
                1 == value ? (
                  <img src={exploreIcon} />
                ) : (
                  <img src={icons.exploreBlackIcon} />
                )
              }
            />
            {auth.getUser()?.user_id == undefined && (
              <BottomNavigationAction
                onClick={() => {
                  history.push("/");
                }}
                classes={{ selected: classes2.selectedBottomNavigation }}
                label="Login"
                icon={<LocationOnIcon />}
              />
            )}
            {auth.getUser() !== null && (
              <BottomNavigationAction
                onClick={() => {
                  history.push("/my-ownership");
                }}
                classes={{ selected: classes2.selectedBottomNavigation }}
                label="My Ownership"
                icon={
                  2 == value ? (
                    <img src={MyOwnership} />
                  ) : (
                    <img src={icons.ownershipBlack} />
                  )
                }
              />
            )}

            {auth.getUser() !== null && (
              <BottomNavigationAction
                onClick={() => {
                  history.push("/account");
                }}
                classes={{ selected: classes2.selectedBottomNavigation }}
                label="Profile"
                icon={
                  3 == value ? (
                    <img src={acountIcon} />
                  ) : (
                    <img src={icons.profileBlack} />
                  )
                }
              />
            )}
          </BottomNavigation>
        </Box>
      </main>
    </div>
  );
}

export default MainDrawerApp;
