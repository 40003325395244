import React, {useEffect, useState} from "react";
import { Button, Grid, Box } from "@material-ui/core";
import HomeImg from "../assets/images/Home.png";
import BookingCard from "./component/BookingCard";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import BrikittIcon from "../assets/icons/Brikitt.png";
import CancelIcon from "../assets/icons/CancelIcon.png";
import Cancel from "../assets/icons/cancelBooking.png";
import Manage from "../assets/icons/manage.png";
import PolicyIcon from "../assets/icons/PolicyIcon.png";
import RightArrow from "../assets/icons/RightArrow.png";
import SuccessLocation from "../assets/icons/SuccessLocation.svg";
import SuccessCall from "../assets/icons/SuccessCall.svg";
import Success from "../assets/icons/Success.svg";
import Share from "../assets/icons/Share.svg";
import { getBookingByID } from "../store/actions/apis";
import LeftIconOutline from "../assets/icons/LeftIconOutline.png";
import LocBlack from "../assets/icons/LocBlack.png";
import { useParams } from "react-router-dom";


function BookingSuccess() {
  const history = useHistory();
  const [bookingData, setBookingData] = useState({
    brik_images: [{ image: "" }],
  });
  const { booking_id } = useParams();
  useEffect(() => {
    getBookingByID(booking_id).then((result) => {
      setBookingData(result.data.bookings);
    });
  }, []);
  return (
    <Box className="layout" style={{top:"80px", bottom:"60px"}}>
      <Box style={{backgroundColor:"#C6FFD8", top:"0px" }}>
        <Grid container style={{color:"#1FA24A"}}>
        <Grid item xs={12} style={{padding:"15px"}}></Grid>
          <Grid item xs={8} style={{paddingLeft:"10px"}}>
            <Grid item xs={12}>
              <img onClick={()=>{}} src={LeftIconOutline} width="5%" marginTop="20px" />
            </Grid>
            <Grid item xs={12}><h2 style={{marginBottom:"-10px"}}>Congratulation</h2></Grid>
            <Grid item xs={12}><h3 >Your Booking is Confirmed</h3></Grid>
          </Grid>
          <Grid item xs={4}>
          <img src={Success} width="100%"/>
          </Grid>
        </Grid>
      </Box>
      <Box  style={{ padding:"10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <img src={bookingData.brik_images[0].image} width="100%" borderRadius="50px" />
          </Grid>
          <Grid item xs={6} style={{marginTop:"-20px"}}>        
            <Grid item xs={12}>
              <h2>{bookingData.brik_name}</h2>
            </Grid>
            <Grid style={{marginTop:"-15px"}}>              
              <Grid item xs={12}>
                <img src={LocBlack} width="10px"/>
                {bookingData.location}
              </Grid>
              {/* <Grid item xs={12}>
                Full address line 1
              </Grid>
              <Grid item xs={12}>
                Full address line 2
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box style={{  padding:"10px",marginTop:"-20px" }}>
        <h4>Booking Details</h4>
        <Box style={{ marginTop:"-10px",backgroundColor:"#fff" ,borderStyle:"solid" ,borderColor:"#C5C5C5",borderWidth:"1px", borderRadius:"10px"}}>
          <h3 style={{ paddingLeft:"10px"}}>{bookingData.user_name}</h3>
          <Grid container style={{paddingLeft:"10px"}}>  
            <Grid container xs={12} style={{justifyContent:"center", marginTop:"-20px"}}>
              <Grid container xs={12}>
                <Grid item xs={4}>
                  <h3 style={{ color:"#4A8EAB"}}>Check-in date</h3>
                  <h3> {moment(bookingData.date_check_in).format("DD MMM YYYY")}</h3>
                </Grid>
                <Grid item xs={3} style={{marginTop:"12%"}}>
                  <Box style={{backgroundColor:"#BDE7FF", padding:"2px", textAlign:"center", borderRadius:"4px" }}>
                  {moment(bookingData.date_check_out).diff(
                      moment(bookingData.date_check_in),
                      "days"
                    )}{" "}
                    nights
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <h3 style={{ color:"#4A8EAB"}}>Check-out date</h3>
                  <h3>{moment(bookingData.date_check_out).format("DD MMM YYYY")}</h3>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} style={{marginTop:"-20px"}}>
              <h2>Instructions</h2>
              <p style={{marginTop:"-20px"}}>
              Follow House Rules as mentioned in the booking confirmation mail sent to you on your Email id. 
              </p>
            </Grid> */}
            
            <Grid container style={{backgroundColor:"#EEEEEE",marginLeft:"-10px",width:"110%"}}>
              <Grid container style={{padding:"10px"}}>
                <Grid item xs={4} style = {{ textAlign:"center" }} >
                  <Grid container style = {{ justifyContent:"center" }}>
                    <Grid item xs={12}>
                      <img onClick={()=>{}} src={SuccessLocation} width="25%" />  
                    </Grid> 
                    <Grid item xs={12} style={{color:"#4A8EAB"}}>
                      Direction
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3} style = {{ textAlign:"center" }}>
                <Grid container style = {{ justifyContent:"center" }}>
                <Grid item xs={12}>
                  <img onClick={()=>{}} src={Share} width="30%"/> 
                </Grid> 
                <Grid item xs={12} style={{color:"#4A8EAB"}}>
                  Share
                </Grid>
                </Grid>
                </Grid>
                <Grid item xs={5} style = {{ textAlign:"center" }}>
                <Grid container style = {{ justifyContent:"center" }}>
                    
                <Grid item xs={12}>
                  <img onClick={()=>{}} src={SuccessCall} width="20%"/> 
                </Grid> 
                <Grid item xs={12} style={{color:"#4A8EAB"}}>
                  Call Brik Manager
                </Grid>
                </Grid>
                </Grid>
                </Grid>
                </Grid>
              </Grid>
        </Box>
      </Box>
      <Box style={{  padding:"10px",backgroundColor:"#fff", borderRadius:"5px", margin:"10px" }}>
        <Grid container>
          <Grid item xs={12}>
            <h2>Price Summary</h2>
          </Grid>
          <Grid container xs={12} style={{justifyContent:"space-between"}}>
            <Grid item xs={6}>
              Property charge/night
            </Grid>
            <Grid item xs={4} style={{textAlign:"right"}}>
            &#8377; {bookingData.per_night_inr ? bookingData.per_night_inr : 0}{" "}
                  /Night
            </Grid>
          </Grid>
          <Grid container xs={12} style={{justifyContent:"space-between"}}>
            <Grid item xs={6}>
              In brikk coin
            </Grid>
            <Grid item xs={4} style={{textAlign:"right"}}>
              <img src={BrikittIcon} width="10%" />  {bookingData.per_night_coin
                      ? bookingData.per_night_coin
                      : 0}{" "}
                    /Night
            </Grid>
          </Grid>
          <Grid container xs={12} style={{justifyContent:"space-between"}}>
            <Grid item xs={6}>
              Total booking nights
            </Grid>
            <Grid item xs={4} style={{textAlign:"right"}}>
            {moment(bookingData.date_check_out).diff(
                    moment(bookingData.date_check_in),
                    "days"
                  )}{" "}Night
            </Grid>
          </Grid>
          <Box style={{marginTop:"2px",width:"100%",borderBottomWidth:"2px",borderBottomStyle:"solid", borderBottomColor:"#C4C4C4"}}/>
          <Grid container xs={12} style={{justifyContent:"space-between"}}>
            <Grid item xs={6} style={{marginTop:'-10px'}} >
              <h4 >Total Payable Amount</h4>
              <p style={{paddingLeft:"90px", marginTop:"-15px"}}>(6x4000)</p>
            </Grid>
            <Grid item xs={4} style={{textAlign:"right", marginTop:"-10px"}}>
              <h4>&#8377; {bookingData.amount_paid ? bookingData.amount_paid : 0}{" "}
                    /Nights</h4>
            </Grid>
          </Grid>
          <Grid container xs={12} style={{justifyContent:"space-between", marginTop:"-20px"}}>
            <Grid item xs={6}>
            <h4>Total Payble Brikk Coin</h4>
            </Grid>
            <Grid item xs={4} style={{textAlign:"right",marginTop:"18px"}}>
              <img src={BrikittIcon} width="10%" />{bookingData.no_coins_used ? bookingData.no_coins_used : 0}{" "}
                  /Nights
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box style={{  padding:"10px",marginTop:"-20px" }}>
        <h4>Manage Your Booking</h4>
        <Box style={{ marginTop:"-10px",backgroundColor:"#fff", borderRadius:"5px"}}>
          <Button
        onClick={()=>{}}
        style={{ margin:"5px 10px", height:"52px", width:"94%",borderRadius:"5px", alignItems:"center", backgroundColor:"#fff"}}
        >
          <Box style={{  width:"100%"  }}>
          <Grid container xs={12} style={{ alignItems:"center", textAlign:"start" }}>
              <Grid item xs={1}>
                <img src={Cancel} width="25px" />
              </Grid>
              <Grid item xs={10}>
                <h4 style={{paddingBottom:"7px",paddingLeft:"10px"}}>Cancel Your Booking</h4>
              </Grid>
              {/* <Grid item xs={1}>

              </Grid> */}
              <Grid item xs={1}>
                <img src={RightArrow} width="10px"/>
              </Grid>
            </Grid> 
          </Box>
          </Button>
          <Button
        onClick={()=>{}}
        style={{ margin:"5px 10px", height:"52px", width:"94%",borderRadius:"5px", alignItems:"center", backgroundColor:"#fff"}}
        >
          <Box style={{  width:"100%"  }}>
          <Grid container xs={12} style={{ alignItems:"center", textAlign:"start" }}>
              <Grid item xs={1}>
                <img src={Manage} width="25px" />
              </Grid>
              <Grid item xs={10}>
                <h4 style={{paddingBottom:"7px",paddingLeft:"10px"}}>Modify Guest Details</h4>
              </Grid>
              {/* <Grid item xs={1}>

              </Grid> */}
              <Grid item xs={1}>
                <img src={RightArrow} width="10px"/>
              </Grid>
            </Grid> 
          </Box>
          </Button>
        </Box>
      </Box>
      <Button
      onClick={()=>{}}
       style={{ margin:"5px 10px", height:"52px", width:"94%",borderRadius:"5px", alignItems:"center", backgroundColor:"#fff"}}>
        <Box style={{  width:"100%"  }}>
        <Grid container xs={12} style={{ alignItems:"center", textAlign:"start" }}>
            <Grid item xs={1}>
              <img src={CancelIcon} width="25px" />
            </Grid>
            <Grid item xs={10}>
              <h4 style={{paddingBottom:"7px",paddingLeft:"10px"}}>Cancellation Policy</h4>
            </Grid>
            {/* <Grid item xs={1}>

            </Grid> */}
            <Grid item xs={1}>
              <img src={RightArrow} width="10px"/>
            </Grid>
          </Grid> 
        </Box>
      </Button>
      
      <Button
      onClick={()=>{}}
      style={{margin:"5px 10px",height:"52px", width:"94%",borderRadius:"5px", alignItems:"center" , backgroundColor:"#fff"}}>
        <Box style={{  width:"100%" }}>
          <Grid container xs={12} style={{ alignItems:"center", textAlign:"start" }}>
            <Grid item xs={1}>
              <img src={PolicyIcon} width="25px" />
            </Grid>
            <Grid item xs={10}>
              <h4 style={{paddingBottom:"7px",paddingLeft:"10px"}}>Property Policies & Rules</h4>  
            </Grid>
            {/* <Grid item xs={1}>

            </Grid> */}
            <Grid item xs={1}>
              <img src={RightArrow} width="10px"/>
            </Grid>
          </Grid> 
        </Box>
      </Button>
      <Box

        onClick={()=>{}}
        style={{  margin:"5px 10px",height:"52px", width:"94%",borderRadius:"5px", borderWidth:"2px" ,borderStyle:"solid",borderColor:"#4A8EAB", alignItems:"center",color:"#4A8EAB"}}
      >
        <Grid container xs={12} style={{  justifyContent:"space-around" }}>
        <Box style={{ alignItems:"center",textAlign:"center", marginTop:"-5px"}}>
          <h4 >GO HOME</h4>
        </Box>
        </Grid>
      </Box>
      <Box
      onClick={()=>{}}
      style={{ margin:"5px 10px",height:"52px", width:"94%", alignItems:"center",color:"#4A8EAB" }}
      >
        <Grid container xs={12} style={{  justifyContent:"space-around" }}>
          <Box style={{ alignItems:"center" ,textAlign:"center", marginTop:"-5px"}}>
            <h4 >
              VIEW MY BOOKINGS
            </h4>
          </Box>
        </Grid>
      </Box>
      <Box style={{ padding:"0px" }}>
      </Box>
    </Box>
  );
}

export default BookingSuccess;
