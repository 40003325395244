import { SET_USER_PROFILE_DATA } from "../constants";

let initialState = {
    personal_info: {

    },
    contact_info: {

    },
    id_cards: [],
    bank_account_details: {

    },
    nominee_details: {

    }
}



const AccountReducer = (state = initialState, action) => {
    const { payload } = action

    switch (action.type) {
        case SET_USER_PROFILE_DATA:
            return {
                ...payload
            }
        default:
            return state
    }
}

export default AccountReducer;
