import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  Container,
} from "@material-ui/core";
import { IconButton } from "@mui/material";
import { cashfreeSandbox, cashfreeProd } from "cashfree-dropjs";
import { BrikCoin } from "../assets/assets";
import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { setTrasactionData } from "../store/actions/ownershipActions";
import RoomIcon from "@material-ui/icons/Room";
import StarIcon from "@material-ui/icons/Star";
import {
  getSpvTransactionPlan,
  getTransactionData,
  getPaymentDetails,
} from "../store/actions/apis";
import Radio from "@material-ui/core/Radio";
import { responsiveStyles } from "../assets/css/generalStyling";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { useFormik } from "formik";
import { addBooking } from "../store/actions/apis";
import CongratulationIcon from "../assets/icons/Group 7020.svg";
import { paymentValidation } from "../validations";
import Modal from "../component/Modal";
import { useDispatch } from "react-redux";
import { Close } from "@material-ui/icons";
import { getWalletBalance } from "../store/actions/walletAction";
import { useSnackbar } from "notistack";
import { TransactionPopup } from "../component/confirmBookingPopup";

function Payment() {
  const [mode, setMode] = useState("write");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };
  // handleCoinPayment(values)

  const handleCancel = () => {
    setIsPopupOpen(false);
    console.log('Transaction cancelled');
  };

  const handleConfirm = () => {
    setIsPopupOpen(false);
    handleCoinPayment(values)
    console.log('Transaction confirmed');
    // Handle the transaction logic here
  };
  const [pay, setPay] = useState(false);
  const [pays,setPays] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [isProd, setIsProd] = useState(true);
  const responsive = responsiveStyles();
  const notification = useSnackbar();
  const [selectedValue, setSelectedValue] = React.useState("a");
  const [paymentBtn, setPaymentBtn] = useState("show");
  const { bookingCheckout } = useSelector((state) => state.booking);
  const [modal, setModal] = useState(false);
  const [maintananceData, setMaintananceData] = useState([]);
  const { balance } = useSelector((state) => state.wallet);
  const history = useHistory();
  const [partialPay, setPartialPay] = useState(false)
  const [brik, setSelectedBrik] = useState(0);
   const [isDetailsSubmitted, setIsDetailsSubmitted] = useState(false);
  // const guestForm = useGuestDetailsForm();
  const {
    values,
    touched,
    errors,
    setFieldError,
    handleSubmit,
    handleChange,
    handleBlur,
    submitForm,
  } = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      instructions: "",
    },
    validationSchema: paymentValidation,
    onSubmit: (values) => {
      setMode("read");
      // Just save the guest details, don't handle payment yet
      console.log("Guest details saved:", values);
    },
  });
  const onSubmitGuestDetails = (e) => {
    e.preventDefault();
    handleSubmit(e);
    setIsDetailsSubmitted(true);
  };

 
  const dispatch = useDispatch();

  // const {
  //   values,
  //   touched,
  //   errors,
  //   setFieldError,
  //   handleSubmit,
  //   handleChange,
  //   handleBlur,
  //   submitForm,
  // } = useFormik({
  //   initialValues: {
  //     name: "",
  //     phone: "",
  //     email: "",
  //     instructions: "",
  //   },
  //   onSubmit: () => {
  //     setMode("read");

  //     let payByCoin = false;
  //     let payOnline = false;

  //     if (balance > bookingCheckout.cart_details.total_payable_coins) {
  //       //if usr have enough balance to book brik
  //       payByCoin = true;
  //     } else if (balance > 0) {
  //       //if usr don't have enough balance to book brik but he had balance to pay
  //       payByCoin = true;
  //       payOnline = true;
  //     } else {
  //       payOnline = true;
  //     }

  //     console.log({
  //       brik_id: bookingCheckout.brik.id,
  //       spv_id: bookingCheckout.brik.spv_info.id,
  //       date_check_in: moment(bookingCheckout.dates[0]).format("YYYYMMDD"),
  //       date_check_out: moment(bookingCheckout.dates[1]).format("YYYYMMDD"),
  //       adult: bookingCheckout.members.adults,
  //       children: bookingCheckout.members.kids,
  //       name: values.name,
  //       phone: values.phone,
  //       email: values.email,
  //       instructions: values.instructions,
  //     });

  //     if (payByCoin && payOnline == false) {
  //       addBooking({
  //         brik_id: bookingCheckout.brik.id,
  //         spv_id: localStorage.getItem('spv_id'),
  //         date_check_in: moment(bookingCheckout.dates[0]).format("YYYYMMDD"),
  //         date_check_out: moment(bookingCheckout.dates[1]).format("YYYYMMDD"),
  //         adult: bookingCheckout.members.adults,
  //         children: bookingCheckout.members.kids,
  //         name: values.name,
  //         phone: values.phone,
  //         email: values.email,
  //         instructions: values.instructions,
  //         pay_by_coin: payByCoin,
  //         pay_online: payOnline,
  //       }).then((result) => {
  //         setModal(true);
  //         dispatch(getWalletBalance());
  //       }).catch((e) => {
  //         const errorMessage = e.response.data.error;
      
  //         notification.enqueueSnackbar(
  //           errorMessage,
  //           {
  //             variant: "error",
  //           }
  //         );
  //       });
  //     } else {setPaymentBtn(true);}
  //   },
  //   validationSchema: paymentValidation,
  // });

   
  
   
  
  // PaymentHandler.js
  // const handlePayment = (guestDetails) => {
  //   let payByCoin = false;
  //   let payOnline = false;
  
  //   if (balance > bookingCheckout.cart_details.total_payable_coins) {
  //     payByCoin = true;
  //   } else if (balance > 0) {
  //     payByCoin = true;
  //     payOnline = true;
  //   } else {
  //     payOnline = true;
  //   }
  
  //   console.log({
  //     brik_id: bookingCheckout.brik.id,
  //     spv_id: bookingCheckout.brik.spv_info.id,
  //     date_check_in: moment(bookingCheckout.dates[0]).format("YYYYMMDD"),
  //     date_check_out: moment(bookingCheckout.dates[1]).format("YYYYMMDD"),
  //     adult: bookingCheckout.members.adults,
  //     children: bookingCheckout.members.kids,
  //     name: guestDetails.name,
  //     phone: guestDetails.phone,
  //     email: guestDetails.email,
  //     instructions: guestDetails.instructions,
  //   });
  
  //   if (payByCoin && payOnline == false) {
  //     addBooking({
  //       brik_id: bookingCheckout.brik.id,
  //       spv_id: localStorage.getItem('spv_id'),
  //       date_check_in: moment(bookingCheckout.dates[0]).format("YYYYMMDD"),
  //       date_check_out: moment(bookingCheckout.dates[1]).format("YYYYMMDD"),
  //       adult: bookingCheckout.members.adults,
  //       children: bookingCheckout.members.kids,
  //       name: guestDetails.name,
  //       phone: guestDetails.phone,
  //       email: guestDetails.email,
  //       instructions: guestDetails.instructions,
  //       pay_by_coin: payByCoin,
  //       pay_online: payOnline,
  //     })
  //       .then((result) => {
  //         setModal(true);
  //         dispatch(getWalletBalance());
  //       })
  //       .catch((e) => {
  //         const errorMessage = e.response.data.error;
      
  //         notification.enqueueSnackbar(
  //           errorMessage,
  //           {
  //             variant: "error",
  //           }
  //         );
  //       });
  //   } else {
  //     setPaymentBtn(true);
  //   }
  // };
  const handleCoinPayment = (values) => {
    // Check if user has enough coins
    // if (balance >= bookingCheckout.cart_details.total_payable_coins) {
    //   // Full payment by coins
    //   addBooking({
    //     brik_id: bookingCheckout.brik.id,
    //     spv_id: localStorage.getItem('spv_id'),
    //     date_check_in: moment(bookingCheckout.dates[0]).format("YYYYMMDD"),
    //     date_check_out: moment(bookingCheckout.dates[1]).format("YYYYMMDD"),
    //     adult: bookingCheckout.members.adults,
    //     children: bookingCheckout.members.kids,
    //     name: guestDetails.name,
    //     phone: guestDetails.phone,
    //     email: guestDetails.email,
    //     instructions: guestDetails.instructions,
    //     pay_by_coin: true,
    //     pay_online: false,
    //   })
    //     .then((result) => {
    //       setModal(true);
    //       dispatch(getWalletBalance());
    //     })
    //     .catch((e) => {
    //       const errorMessage = e.response.data.error;
         
    //       notification.enqueueSnackbar(
    //         errorMessage,
    //         { variant: "error" }
    //       );
    //     });
    // } else if (balance > 0) {
    //   // Partial payment with coins + cash
    //   addBooking({
    //     brik_id: bookingCheckout.brik.id,
    //     spv_id: localStorage.getItem('spv_id'),
    //     date_check_in: moment(bookingCheckout.dates[0]).format("YYYYMMDD"),
    //     date_check_out: moment(bookingCheckout.dates[1]).format("YYYYMMDD"),
    //     adult: bookingCheckout.members.adults,
    //     children: bookingCheckout.members.kids,
    //     name: guestDetails.name,
    //     phone: guestDetails.phone,
    //     email: guestDetails.email,
    //     instructions: guestDetails.instructions,
    //     pay_by_coin: true,
    //     pay_online: true,
    //   })
    //     .then((result) => {
    //       setPaymentBtn(true); // Show online payment button for remaining amount
    //       notification.enqueueSnackbar(
    //         "Coins will be applied. Please complete remaining payment online.",
    //         { variant: "info" }
    //       );
    //     })
    //     .catch((e) => {
    //       const errorMessage = e.response.data.error;
        
    //       notification.enqueueSnackbar(
    //         errorMessage,
    //         { variant: "error" }
    //       );
    //     });
    // } else {
    //   notification.enqueueSnackbar(
    //     "Insufficient coin balance",
    //     { variant: "error" }
    //   );
    // }
    let payByCoin = false;
      let payOnline = false;

      if (balance > bookingCheckout.cart_details.total_payable_coins) {
        //if usr have enough balance to book brik
        payByCoin = true;
      } else if (balance > 0) {
        //if usr don't have enough balance to book brik but he had balance to pay
        payByCoin = true;
        payOnline = true;
      } else {
        payOnline = true;
      }

      console.log({
        brik_id: bookingCheckout.brik.id,
        spv_id: bookingCheckout.brik.spv_info.id,
        date_check_in: moment(bookingCheckout.dates[0]).format("YYYYMMDD"),
        date_check_out: moment(bookingCheckout.dates[1]).format("YYYYMMDD"),
        adult: bookingCheckout.members.adults,
        children: bookingCheckout.members.kids,
        name: values.name,
        phone: values.phone,
        email: values.email,
        instructions: values.instructions,
      });

      if (payByCoin && payOnline == false) {
        addBooking({
          brik_id: bookingCheckout.brik.id,
          spv_id: localStorage.getItem('spv_id'),
          date_check_in: moment(bookingCheckout.dates[0]).format("YYYYMMDD"),
          date_check_out: moment(bookingCheckout.dates[1]).format("YYYYMMDD"),
          adult: bookingCheckout.members.adults,
          children: bookingCheckout.members.kids,
          name: values.name,
          phone: values.phone,
          email: values.email,
          instructions: values.instructions,
          pay_by_coin: payByCoin,
          pay_online: payOnline,
        }).then((result) => {
          setModal(true);
          dispatch(getWalletBalance());
        }).catch((e) => {
          const errorMessage = e.response.data.error;
      
          notification.enqueueSnackbar(
            errorMessage,
            {
              variant: "error",
            }
          );
        });
      } else {setPaymentBtn(true);
        setPartialPay(true);
        
      }
  };
  
  const handleCashPayment = (guestDetails) => {
    addBooking({
      brik_id: bookingCheckout.brik.id,
      spv_id: localStorage.getItem('spv_id'),
      date_check_in: moment(bookingCheckout.dates[0]).format("YYYYMMDD"),
      date_check_out: moment(bookingCheckout.dates[1]).format("YYYYMMDD"),
      adult: bookingCheckout.members.adults,
      children: bookingCheckout.members.kids,
      name: guestDetails.name,
      phone: guestDetails.phone,
      email: guestDetails.email,
      instructions: guestDetails.instructions,
      pay_by_coin: false,
      pay_online: true,
    })
      .then((result) => {
         // Show online payment interface
        console.log("cashPayment_2024",result.data)
        let token = result.data.order_token;
        if(token){
          setPaymentBtn(false);
          setPay(true);
          renderDropin(token);
        }else{
          notification.enqueueSnackbar(
            "Cash Payment is not allowed for this Booking",
            { variant: "error" }
          );
        }
                                    //console.log("token",token)
                                    // setOrderToken(token)
                                    //console.log(orderToken)
                                 
                                    //renderDropin(token)
      })
      .catch((e) => {
        const errorMessage =  typeof e.response.data.error === "string" 
        ? e.response.data.error 
        : "Cash Payment is unavailable for this Booking";
        notification.enqueueSnackbar(
          errorMessage,
          { variant: "error" }
        );
      });
  };
  

  const handleChange2 = (event) => {
    setSelectedValue(event.target.value);
  };

  // const maintain = () => {
  //   getTransactionData(id)
  //     .then((result) => {
  //       const { data } = result;
  //       console.log("RRSULT TXN: ", data);

  //       if (data.length) {
  //         setMaintananceData(data);
  //         dispatch(setTrasactionData(data[0]));
  //       }
  //     })
  //     .catch((er) => {
  //       console.log("ERROR TXN: ", er);
  //     });
  // };

  const [components, setComponents] = useState([
    "order-details",
    "card",
    "upi",
    "app",
    "netbanking",
    "paylater",
    "credicardemi",
  ]);
  const cbs = (data) => {
    if (data.order && data.order.status === "PAID") {
      setMessage("Transaction Successful");
      setPay(false);
      setOpen(true);
      setTimeout(function () {
        setOpen(false);
      }, 5000);
      //maintain();
    }
  };
  const cbf = (data) => {
    setMessage(data.order.errorText);
    setPay(false);
    setOpen(true);
    setTimeout(function () {
      setOpen(false);
    }, 5000);
    //maintain();
  };

  useEffect(() => {
    if (Object.keys(bookingCheckout.brik).length === 0)
      history.push("/booking");
    // if (maintananceData.length)
    //   dispatch(setTrasactionData(maintananceData[brik]));
  }, [brik]);

  const getPayments = (installment_id) => {
    getPaymentDetails(67, installment_id)
      .then((result) => {
        console.log("payment details", result.data.order_token);
        let token = result.data.order_token;
        //console.log("token",token)
        // setOrderToken(token)
        //console.log(orderToken)
        setPay(true);
        renderDropin(token);
        //renderDropin(token)
      })
      .catch((err) => {
        console.log("details error", err);
      });
    //console.log(installment_id)
    //console.log("maintain",maintananceData)
    // const rnd = maintananceData.map((data) => {
    //   //console.log(data.installments,"--",installment_id)
    //   //console.log("data",data.payment_details.payment_plan_id)
    //   const rn = data.installments.map((dt) => {
    //     //console.log(dt.id,"--",installment_id)
    //     if (dt.id === installment_id) {
    //       //console.log(data.installments.id,"--",installment_id)
    //       //console.log(data.payment_details.payment_plan_id)
    //       getPaymentDetails(
    //         data.payment_details.payment_plan_id,
    //         installment_id
    //       )
    //         .then((result) => {
    //           console.log("payment details", result.data.order_token);
    //           let token = result.data.order_token;
    //           //console.log("token",token)
    //           // setOrderToken(token)
    //           //console.log(orderToken)
    //           setPay(true);
    //           renderDropin(token);
    //           //renderDropin(token)
    //         })
    //         .catch((err) => {
    //           console.log("details error", err);
    //         });
    //     }
    //   });
    //}
    //);
  };

  const renderDropin = (orderToken) => {
    // {dropinComponents.map(({ name, id }, index) => {
    //   handleOnChange(index)
    // })}
    // handleOnChange()
    if (orderToken === "") {
      alert("Order Token is empty");
      return;
    }
    // if (!components.length) {
    //   alert('No drop in specified');
    //   return;
    // }
    let parent = document.getElementById("drop_in_container");
    parent.innerHTML = "";
    let cashfree;
    if (isProd) {
      cashfree = new cashfreeProd.Cashfree();
    } else {
      cashfree = new cashfreeSandbox.Cashfree();
    }
    console.log("before Initialisation");
    cashfree.initialiseDropin(parent, {
      orderToken,
      onSuccess: cbs,
      onFailure: cbf,
      components,
    });
    console.log("after Initialisation");
  };

  const ModalHeader1 = () => {
    return (
      <Box
        style={{ zIndex: 1, color: "white" }}
        padding="14px 23px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box style={{ fontSize: "21px", fontWeight: "600", color: "Black" }}>
          Cashfree Payment
        </Box>
        <Box>
          <IconButton
            style={{ color: "Black" }}
            onClick={() => {
              setPay(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const ModalHeader2 = () => {
    return (
      <Box
        style={{ zIndex: 1, color: "white" }}
        padding="14px 23px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box style={{ fontSize: "21px", fontWeight: "600", color: "Black" }}>
          Transaction Status
        </Box>
        <Box>
          <IconButton
            style={{ color: "Black" }}
            onClick={() => {
              setOpen(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const ModalHeader = () => {
    return (
      <Box
        padding="0px 14px"
        display="flex"
        style={{
          zIndex: 1,
          color: "black",
          paddingTop: "10px",
          fontWeight: "600",
        }}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box></Box>
        <Box>
          <IconButton
            style={{ color: "Black" }}
            onClick={() => {
              setModal(false);
              history.replace("/booking");
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Modal
        width="auto"
        height="auto"
        headerComponent={ModalHeader2}
        status={open}
      >
        {message}
      </Modal>
      <Modal
        width="550px"
        height="100%"
        headerComponent={ModalHeader1}
        status={pay}
      >
        {/* <div className="mt-2">
                  <span className="order-token mr-8">Order Token :</span>
                  <input
                    type="text"
                    placeholder="order_token"
                    id="orderToken"
                    value={orderToken}
                    className="inputText"
                    onChange={(e) => setOrderToken(e.target.value)}
                  />
                </div> */}
        {/* <p className="order-token">Choose components</p>
                <ul className="toppings-list"> */}
        {/* {dropinComponents.map(({ name, id }, index) => { */}
        {/* handleOnChange(index)
                    // return (
                    //   <>
                    //     <input */}
        {/* //       type="checkbox"
                    //       id={`custom-checkbox-${index}`}
                    //       name={name}
                    //       value={id}
                    //       checked={checkedState[index]}
                    //       onChange={() => handleOnChange(index)}
                    //       key={id}
                    //     />
                    //     <label className="mr-8" htmlFor={`custom-checkbox-${index}`}>
                    //       {name}
                    //     </label>
                    //   </>
                    // );
                  // })} */}
        {/* </ul> */}
        {/* <button className="btn-render mt-2" onClick={renderDropin}>
                  Render
                </button> */}
        <div
          className="dropin-parent"
          id="drop_in_container"
          style={{ minHeight: "600px" }}
        ></div>
      </Modal>
      <Box className={responsive.web}>
        <Box style={{ marginTop: "20px" }}>
          <Modal
            modalPaper={{ backgroundColor: "#C6FFD8" }}
            width="470px"
            height="270px"
            status={modal}
            headerComponent={ModalHeader}
            borderRadius="none"
          >
            <Box
              style={{
                backgroundColor: "#C6FFD8",
                color: "#1FA24A",
                textAlign: "center",
              }}
            >
              <Box>
                <img src={CongratulationIcon} />
              </Box>
              <Box fontSize={20} fontWeight="600">
                Congratulations
              </Box>
              <Box>Your Booking Is Confirmed</Box>
            </Box>
          </Modal>
          <Grid spacing={2} container>
            <Grid xs={8} item>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box display="flex" width="100%">
                    <Box
                      flex={1}
                      fontWeight="700"
                      fontSize="14px"
                      paddingLeft="10px"
                    >
                      Description
                    </Box>
                  </Box>
                </AccordionSummary>

                <AccordionDetails>
                  <Box width="100%">
                    <Box display="flex" width="100%">
                      <Box>
                        <img
                          height="100%"
                          width="200px"
                          src={bookingCheckout.brik.spv_cover_image?bookingCheckout.brik.spv_cover_image:bookingCheckout.brik.brik_cover_image}
                        />
                      </Box>
                      <Box marginLeft="20px" lineHeight="1.5">
                        <Box style={{ fontWeight: "bold", fontSize: "16px" }}>
                          {bookingCheckout.brik.property_name}
                        </Box>
                        <Box display="flex" alignItems="center">
                          <RoomIcon
                            style={{ color: "red", fontSize: "18px" }}
                          />
                          <Box>{bookingCheckout.brik.location}</Box>
                        </Box>
                        <Box
                          display="flex"
                          fontSize="600"
                          color="#8B7862"
                          fontWeight="600"
                          padding="5px 0px"
                        >
                          <Avatar
                            src={BrikCoin}
                            style={{ height: "20px", width: "20px" }}
                          />{" "}
                          &nbsp;
                          {bookingCheckout.brik.per_night_coin !== null
                            ? bookingCheckout.brik.per_night_coin
                            : 0}
                          /Night &nbsp;
                          <Box component="span" style={{ color: "#565656" }}>
                            (₹{" "}
                            {bookingCheckout.brik.per_night_inr
                              ? bookingCheckout.brik.per_night_inr
                              : 0}
                            /Night)
                          </Box>
                        </Box>
                        <Box display="flex">
                          <Box
                            display="flex"
                            alignItems="center"
                            style={{
                              backgroundColor: "#00A355",
                              color: "white",
                              borderRadius: "5px",
                              fontSize: "12px",
                              padding: "2px 10px",
                            }}
                            component="span"
                          >
                            <Box>
                              {bookingCheckout.brik.average_rating}&nbsp;&nbsp;
                            </Box>
                            <StarIcon
                              style={{
                                height: "14px",
                                width: "14px",
                                color: "#FFC107",
                              }}
                            />
                          </Box>
                          <Box color="#8B7862">
                            &nbsp;{bookingCheckout.brik.total_rating} Rating,{" "}
                            {bookingCheckout.brik.total_review} Reviews
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      style={{
                        marginTop: "18px",
                        padding: "13px 15px",
                        backgroundColor: "#DBF4FF",
                        borderRadius: "7px",
                      }}
                    >
                      <Box
                        width="100%"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <Box
                            color="#4A8EAB"
                            fontSize="13px"
                            paddingBottom="5px"
                          >
                            Check-in date
                          </Box>
                          <Box
                            style={{
                              borderColor: "#C4C4C4",
                              padding: "15px 35px",
                              backgroundColor: "#FFFFFF",
                              borderRadius: "7px",
                              fontWeight: "600",
                            }}
                          >
                            {moment(bookingCheckout.dates[0]).format(
                              "DD MMM YYYY"
                            )}
                          </Box>
                        </Box>
                        <Box
                          style={{
                            padding: "7px 19px",
                            marginTop: "18px",
                            color: "#124765",
                            backgroundColor: "#BDE7FF",
                            borderRadius: "6px",
                            fontWeight: "600",
                          }}
                        >
                          {moment(bookingCheckout.dates[1]).diff(
                            moment(bookingCheckout.dates[0]),
                            "days"
                          )}{" "}
                          Nights
                        </Box>
                        <Box>
                          <Box
                            color="#4A8EAB"
                            fontSize="13px"
                            paddingBottom="5px"
                          >
                            Check-out date
                          </Box>
                          <Box
                            style={{
                              borderColor: "#C4C4C4",
                              padding: "15px 35px",
                              backgroundColor: "#FFFFFF",
                              borderRadius: "7px",
                              fontWeight: "600",
                            }}
                          >
                            {moment(bookingCheckout.dates[1]).format(
                              "DD MMM YYYY"
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <br />

              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box display="flex" width="100%">
                    <Box
                      flex={1}
                      fontWeight="700"
                      fontSize="14px"
                      paddingLeft="10px"
                    >
                      Guest Details
                    </Box>
                    {mode == "read" ? (
                      <Box
                        color="#4A8EAB"
                        onClick={(e) => {
                          setMode("edit");
                          e.stopPropagation();
                        }}
                      >
                        Edit
                      </Box>
                    ) : (
                      <Box
                        color="#4A8EAB"
                        onClick={(e) => {
                          submitForm();

                          e.stopPropagation();
                        }}
                      >
                        Done
                      </Box>
                    )}
                  </Box>
                </AccordionSummary>

                <AccordionDetails>
                  {mode == "read" ? (
                    <Box flexDirection="column" padding="0 11px">
                      <Box lineHeight="2">
                        <Box display="flex" width="100%">
                          <Box flex={1} color="#979797">
                            {" "}
                            FULL NAME
                          </Box>
                          <Box fontWeight="600" flex={1}>
                            {" "}
                            {values.name}
                          </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" width="100%">
                          <Box flex={1} color="#979797">
                            {" "}
                            MOBILE NUMBER
                          </Box>
                          <Box fontWeight="600" flex={1}>
                            {" "}
                            {values.phone}
                          </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" width="100%">
                          <Box flex={1} color="#979797">
                            {" "}
                            EMAIL ID
                          </Box>
                          <Box fontWeight="600" flex={1}>
                            {" "}
                            {values.email}
                          </Box>
                        </Box>
                      </Box>
                      <Box color="#565656" fontSize="12px" padding="10px 0px">
                        We’ll send your booking information on your mobile &
                        email.
                      </Box>
                      {/* <Box display="flex" flexDirection="column" width="100%">
                        <Box flex={1} color="#979797" padding="6px 0">
                          {" "}
                          DESCRIPTION
                        </Box>
                        <Box lineHeight="1.6">
                          {" "}
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Tincidunt fermentum, urna dolor elementum a
                          volutpat pellentesque. Diam turpis platea auctor odio
                          integer.
                        </Box>
                      </Box> */}
                    </Box>
                  ) : (
                    <Box flexDirection="column" padding="0 11px" width="100%">
                      <Box lineHeight="2">
                        <Box>
                          <Box fontWeight="600" paddingBottom="3px">
                            FULL NAME
                          </Box>
                          <Box>
                            <TextField
                              id="name"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.name && errors.name}
                              helperText={touched.name && errors.name}
                              placeholder="Enter Full Name"
                              variant="outlined"
                              fullWidth
                            />
                          </Box>
                        </Box>
                        <Box padding="10px 0">
                          <Box fontWeight="600" paddingBottom="3px">
                            MOBILE NUMBER
                          </Box>
                          <Box>
                            <TextField
                              id="phone"
                              name="phone"
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.phone && errors.phone}
                              helperText={touched.phone && errors.phone}
                              placeholder="Enter Phone Number"
                              variant="outlined"
                              fullWidth
                            />
                          </Box>
                        </Box>
                        <Box>
                          <Box fontWeight="600" paddingBottom="3px">
                            EMAIL ID
                          </Box>
                          <Box>
                            <TextField
                              id="email"
                              name="email"
                              value={values.email}
                              error={touched.email && errors.email}
                              helperText={touched.email && errors.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Enter Email"
                              variant="outlined"
                              fullWidth
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box color="#565656" fontSize="12px" padding="10px 0px">
                        We’ll send your booking information on your mobile &
                        email.
                      </Box>
                      <Box padding="10px 0">
                        <Box fontWeight="600" paddingBottom="3px">
                          ADD ANY INSTRUCTION IF ANY
                        </Box>
                        <Box>
                          <TextField
                            id="instructions"
                            name="instructions"
                            value={values.instructions}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Type Here"
                            variant="outlined"
                            fullWidth
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>

              {paymentBtn == "show" ? (
                <Box>
                  <div style={{display:"flex",justifyContent:"space-between"}} className="">
                  {/* <Button
                    variant="contained"
                    color="secondary"
                    style={{width:"48%"}}
                    onClick={() => {
                      submitForm();
                    }}
                    // onClick={()=>{
                    //   getPayments(922);
                    // }}
                  >
                    PAY WITH CASH
                  </Button> */}
                  {!isDetailsSubmitted && (
        <Button
        variant="contained"
        color="secondary"
        // style={{width:"48%"}}
        fullWidth
        onClick={(e) => {
          onSubmitGuestDetails(e)
        }}
        // onClick={()=>{
        //   getPayments(922);
        // }}
      >
         Proceed to pay
      </Button>
      )}
                 
                  {isDetailsSubmitted && (<>
                    {/* <Button
                     variant="contained"
                     color="secondary"
                     style={{width:"48%"}}
                     fullWidth
                     onClick={handleOpenPopup}
                     // onClick={()=>{
                     //   getPayments(922);
                     // }}
                   >
                      Pay with Coins
                   </Button>
      <Button
      variant="contained"
      color="secondary"
      style={{width:"48%"}}
      fullWidth
      onClick={()=>{handleCashPayment(values)}}
      // onClick={()=>{
      //   getPayments(922);
      // }}
    >
       Pay with Cash
    </Button> */}
      <button     onClick={handleOpenPopup} style={{ width:"48%", fontSize:"14px", borderRadius:"5px" , border:"none", color:"white",backgroundColor:"#4A8EAB", paddingTop:"18px", paddingBottom:"18px"}} >PAY WITH COIN </button>
      <button   onClick={()=>{handleCashPayment(values)}} style={{width:"48%", fontSize:"14px",borderRadius:"5px" , border:"none", color:"white",paddingTop:"18px" , backgroundColor:"#8B7862", paddingBottom:"18px"}} >PAY WITH CASH </button>
                  </>
                    
      )}
                  </div>
{/*                  
                  <button
        onClick={handleOpenPopup}
        className="bg-stone-600 text-white px-4 py-2 rounded-lg hover:bg-stone-700 flex items-center gap-2"
      >
        Pay with coins
      </button> */}
                  {isPopupOpen && (
        <TransactionPopup
          availableCoins={balance}
          payAmount={ (() => {
            let days = moment(bookingCheckout.dates[1]).diff(
              moment(bookingCheckout.dates[0]),
              "days"
            );

            let value = bookingCheckout.brik.per_night_coin
              ? bookingCheckout.brik.per_night_coin
              : 0;

            return days * value;
          })()}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
      )}
                </Box>
              ) : (
                <>
                 
                </>
              )}
              {partialPay && ( <Box >
                    <Box
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                        padding: "20px 0px 10px 0",
                      }}
                    >
                      Select Payment Method
                    </Box>
                    <Accordion defaultExpanded>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Box display="flex" width="100%">
                          <Box
                            flex={1}
                            fontWeight="700"
                            fontSize="14px"
                            paddingLeft="10px"
                          >
                            Recommended
                          </Box>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box>
                          <Box
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            <Box>
                              <Radio
                                checked={selectedValue === "a"}
                                onChange={handleChange2}
                                value="a"
                                name="radio-button-demo"
                                inputProps={{ "aria-label": "A" }}
                              />
                            </Box>
                            <Box padding="0 10px">
                              <Box display="flex">
                                <Box>
                                  <img
                                    src={BrikCoin}
                                    style={{ width: "20px" }}
                                  />
                                </Box>
                                <Box
                                  paddingLeft="10px"
                                  color="#8B7862"
                                  fontWeight="600"
                                >
                                  Brikitt Coin
                                </Box>
                              </Box>
                              <Box
                                paddingLeft="30px"
                                display="flex"
                                padding="3px 0"
                              >
                                <Box paddingRight="9px" color="#9B9B9B">
                                  Balance:
                                </Box>
                                <Box>
                                  <img
                                    src={BrikCoin}
                                    style={{ width: "15px", paddingTop: "3px" }}
                                  />
                                </Box>
                                <Box color="#B88952">&nbsp;{balance}</Box>
                              </Box>
                              <Box
                                paddingLeft="30px"
                                display="flex"
                                color="#9B9B9B"
                              >
                                <Box>Minimum recharge required:</Box>
                                <Box paddingLeft="6px">
                                  <img
                                    src={BrikCoin}
                                    style={{ width: "15px", paddingTop: "3px" }}
                                  />
                                </Box>
                                <Box color="#B88952">
                                  &nbsp;
                                  {bookingCheckout.cart_details
                                    .total_payable_coins - balance}
                                </Box>
                                <Box color="#9B9B9B">
                                  &nbsp;(
                                  {
                                    bookingCheckout.cart_details
                                      .total_payable_inr
                                  }
                                  )
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          {/* <Box
                            padding="4px 8px"
                            margin="11px 0"
                            style={{ backgroundColor: "#4A8EAB16" }}
                          >
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Ullamcorper commodo, at imperdiet feugiat
                            commod.
                          </Box> */}
                          <Box>
                            <Button
                              variant="contained"
                              color="secondary"
                              fullWidth
                              onClick={() => {
                                let payByCoin = false;
                                let payOnline = false;

                                if (
                                  balance >
                                  bookingCheckout.cart_details
                                    .total_payable_coins
                                ) {
                                  //if usr have enough balance to book brik
                                  payByCoin = true;
                                } else if (balance > 0) {
                                  //if usr don't have enough balance to book brik but he had balance to pay
                                  payByCoin = true;
                                  payOnline = true;
                                } else {
                                  setPaymentBtn(false);
                                  //setPays(false)
                                  payOnline = true;
                                }

                                addBooking({
                                  brik_id: bookingCheckout.brik.id,
                                  spv_id: localStorage.getItem('spv_id'),
                                  date_check_in: moment(
                                    bookingCheckout.dates[0]
                                  ).format("YYYYMMDD"),
                                  date_check_out: moment(
                                    bookingCheckout.dates[1]
                                  ).format("YYYYMMDD"),
                                  adult: bookingCheckout.members.adults,
                                  children: bookingCheckout.members.kids,
                                  name: values.name,
                                  phone: values.phone,
                                  email: values.email,
                                  instructions: values.instructions,
                                  pay_by_coin: payByCoin,
                                  pay_online: payOnline,
                                })
                                  .then((result) => {
                                    console.log(
                                      "payment details",
                                      result.data.order_token
                                    );
                                    let token = result.data.order_token;
                                    if(token){
                                      setPaymentBtn(false);
                                      setPay(true);
                                      renderDropin(token);
                                    }else{
                                      notification.enqueueSnackbar(
                                        "Cash Payment is not allowed for this Booking",
                                        { variant: "error" }
                                      );
                                    }
                                    //console.log("token",token)
                                    // setOrderToken(token)
                                    //console.log(orderToken)
                                    // setPay(true);
                                    // renderDropin(token);
                                    //renderDropin(token)
                                  })
                                  .catch((e) => {
                                  const errorMessage =  typeof e.response.data.error === "string" 
  ? e.response.data.error 
  : "Cash Payment is unavailable for this Booking";
                                 
                                    notification.enqueueSnackbar(
                                      errorMessage,
                                      {
                                        variant: "error",
                                      }
                                    );
                                  })
                              }}
                            >
                              Add Money & Pay
                            </Button>
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Box>)}
              
            </Grid>

            <Grid xs={4} item>
              <Paper style={{ padding: "10px", color: "#565656" }}>
                <Box lineHeight="2">
                  <Box fontSize="17px" fontWeight="600" padding="2px 0">
                    Price Summary
                  </Box>
                  <Box display="flex">
                    <Box flex={1} color="#565656">
                      Property Charge/Night
                    </Box>
                    <Box fontWeight="600">
                      ₹{" "}
                      {bookingCheckout.brik.per_night_inr
                        ? bookingCheckout.brik.per_night_inr
                        : 0}
                      /Night
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box flex={1} color="#565656">
                      In Brikk coin
                    </Box>
                    <Box display="flex">
                      <Box>
                        <img
                          src={BrikCoin}
                          style={{ height: "16px", paddingTop: "4px" }}
                        />
                      </Box>
                      <Box color="#8B7862" paddingLeft="3px" fontWeight="600">
                        {bookingCheckout.brik.per_night_coin
                          ? bookingCheckout.brik.per_night_coin
                          : 0}
                        /Night
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box flex={1} color="#565656">
                      Total Booking Nights
                    </Box>
                    <Box fontWeight="600">
                      {" "}
                      {moment(bookingCheckout.dates[1]).diff(
                        moment(bookingCheckout.dates[0]),
                        "days"
                      )}{" "}
                      Night
                    </Box>
                  </Box>
                  <hr />
                  <Box display="flex">
                    <Box flex={1} fontWeight="bold">
                      Total Payable amount{" "}
                      <Box
                        component="span"
                        style={{ fontSize: "10px", color: "#565656" }}
                      >
                        ({" "}
                        {moment(bookingCheckout.dates[1]).diff(
                          moment(bookingCheckout.dates[0]),
                          "days"
                        )}{" "}
                        X{" "}
                        {bookingCheckout.brik.per_night_inr
                          ? bookingCheckout.brik.per_night_inr
                          : 0}{" "}
                        )
                      </Box>
                    </Box>
                    <Box fontWeight="600">
                      ₹&nbsp;
                      {(() => {
                        let days = moment(bookingCheckout.dates[1]).diff(
                          moment(bookingCheckout.dates[0]),
                          "days"
                        );
                        let value = bookingCheckout.brik.per_night_inr
                          ? bookingCheckout.brik.per_night_inr
                          : 0;

                        return days * value;
                      })()}
                      &nbsp;
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Box fontWeight="bold" flex={1}>
                      Total Payable Brik Coin
                    </Box>
                    <Box display="flex">
                      <Box>
                        <img
                          src={BrikCoin}
                          style={{ height: "16px", paddingTop: "4px" }}
                        />
                      </Box>
                      <Box color="#8B7862" paddingLeft="3px" fontWeight="600">
                        {(() => {
                          let days = moment(bookingCheckout.dates[1]).diff(
                            moment(bookingCheckout.dates[0]),
                            "days"
                          );

                          let value = bookingCheckout.brik.per_night_coin
                            ? bookingCheckout.brik.per_night_coin
                            : 0;

                          return days * value;
                        })()}
                        &nbsp;Coins
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={responsive.app} padding="2%">
        <Box
          style={{
            display: "flex",
            padding: "3% 0",
            alignItems: "center",
            display: pay ? "none" : "flex",
          }}
        >
          <Box>
          <Box>
              <ArrowBackIosIcon onClick={() => {history.goBack();}}
                      />
            </Box>
          </Box>
          <Box fontSize="28px" fontWeight="700">
            Booking Review
          </Box>
        </Box>
        <Box container style={{ display: pay ? "none" : "block" }}>
          <Box width="100%" padding="2%">
            <Grid container spacing={1}>
              <Grid item md={6} xs={6}>
                <Box>
                  <img
                    height="97%"
                    width="97%"
                    src={bookingCheckout.brik.spv_cover_image?bookingCheckout.brik.spv_cover_image:bookingCheckout.brik.brik_cover_image}
                  />
                </Box>
              </Grid>
              <Grid item md={6} xs={6}>
                <Box marginLeft="20px" lineHeight="1.5">
                  <Box style={{ fontWeight: "bold", fontSize: "16px" }}>
                  {bookingCheckout.brik.property_name}
                  </Box>
                  <Box display="flex" alignItems="center">
                    {/* <RoomIcon style={{ color: "red", fontSize: "18px" }} /> */}
                    <Box>{bookingCheckout.brik.location}</Box>
                  </Box>
                  <Box
                    //display="flex"
                    fontSize="600"
                    color="#8B7862"
                    fontWeight="600"
                    padding="5px 0px"
                  >
                    <Box display="flex">
                    <Avatar
                      src={BrikCoin}
                      style={{ height: "20px", width: "20px" }}
                    />{bookingCheckout.brik.per_night_coin !== null
                                ? bookingCheckout.brik.per_night_coin
                                : 0}/Night</Box> &nbsp;
                    <Box component="span" style={{ color: "#565656" }}>
                      (₹&nbsp;
                                {bookingCheckout.brik.per_night_inr
                                  ? bookingCheckout.brik.per_night_inr
                                  : 0}/Night)
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{
                        backgroundColor: "#00A355",
                        color: "white",
                        borderRadius: "5px",
                        fontSize: "12px",
                        padding: "2px 10px",
                      }}
                      component="span"
                    >
                      <Box>{bookingCheckout.brik.average_rating}&nbsp;&nbsp;</Box>
                      <StarIcon
                        style={{
                          height: "14px",
                          width: "14px",
                          color: "#FFC107",
                        }}
                      />
                    </Box>
                    <Box color="#8B7862">&nbsp;{bookingCheckout.brik.total_rating} Rating,{" "}
                                {bookingCheckout.brik.total_review} Reviews</Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <hr />
          <Box
            style={{
              marginTop: "18px",
              //padding: "13px 15px",
              marginLeft:"2%",
              marginRight:"2%",
              borderRadius: "7px",
            }}
          >
            <Box
              width="100%"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Box color="#4A8EAB" fontSize="14px" fontWeight="600" letterSpacing="0.35px" paddingBottom="5px">
                  Check-in date
                </Box>
                <Box
                  style={{
                    border:"1px solid #C4C4C4",
                    boxShadow:"0px 0px 14px -2px rgba(0, 0, 0, 0.1)",
                    padding: "15px 35px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "5px",
                    fontWeight: "600",
                  }}
                >
                  {moment(bookingCheckout.dates[0]).format(
                              "DD MMM YYYY"
                            )}
                </Box>
              </Box>
              <Box
                style={{
                  padding: "2px 5px",
                  marginTop: "18px",
                  marginLeft:"1%",
                  marginRight:"1%",
                  color: "#124765",
                  backgroundColor: "#BDE7FF",
                  borderRadius: "5px",
                  fontWeight: "600",
                }}
              >
                {moment(bookingCheckout.dates[1]).diff(
                            moment(bookingCheckout.dates[0]),
                            "days"
                          )}{" "}
                          Nights
              </Box>
              <Box>
                <Box color="#4A8EAB" fontSize="13px" fontWeight="600" letterSpacing="0.35px" paddingBottom="5px">
                  Check-out date
                </Box>
                <Box
                  style={{
                    border:"1px solid #C4C4C4",
                    boxShadow:"0px 0px 14px -2px rgba(0, 0, 0, 0.1)",
                    padding: "15px 35px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "5px",
                    fontWeight: "600",
                  }}
                >
                  {moment(bookingCheckout.dates[1]).format(
                              "DD MMM YYYY"
                            )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <hr />
        <Accordion paddingBottom="2%" style={{ display: pay ? "none" : "block",backgroundColor:"white",
              boxShadow:"0px 8px 25px rgba(83, 89, 144, 0.07)",
              borderRadius:"5px" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box display="flex" width="100%" style={{
            }}>
              <Box flex={1} fontWeight="700" fontSize="14px" paddingLeft="10px">
                Property Policies & Rules
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              At BRIKitt, we’re on a mission to enrich lives through second home
              ownership. For Proud BRIK owners, a second home brings great pride
              and a commitment to being a conscientious, respectful owner and
              good neighbor. Together, BRIK owners protect and enhance the
              experience of their fellow owners and the local community. BRIK
              owners agree to abide by the following policies:
              <ul>
                <li>
                  BRIKitt homes are designed solely for the personal use and
                  enjoyment of owners family and their personal guests families.
                  No Stag stays are allowed at any of the BRIKitt Properties.
                </li>
                <li>
                  Large events or parties that would cause disruption for the
                  neighborhood are prohibited.
                </li>
                <li>Quiet hours are observed from 9 p.m. to 7 a.m.</li>
                <li>
                  Owners may bring up to One dog onto the property. Dogs cannot
                  weigh more than 80 pounds each and no other animals are
                  allowed (except certified assistance animals).
                </li>
                <li>
                  BRIKitt owners are strongly encouraged to avoid parking on the
                  street unless absolutely necessary.
                </li>
                <li>
                  No trash or garbage is allowed to accumulate, and garbage
                  containers must be stored out of sight except on collection
                  day.
                </li>
              </ul>
              Violations of BRIK owner policies may result in monetary penalties
              or a temporary suspension of stay rights.
            </Box>
          </AccordionDetails>
        </Accordion>
        <Paper
          style={{
            padding: "10px",
            color: "#565656",
            display: pay ? "none" : "block",
            backgroundColor:"white",
            boxShadow:"0px 8px 25px rgba(83, 89, 144, 0.07)",
            borderRadius:"5px"
          }}
          paddingBottom="2%"
        >
          <Box lineHeight="2">
            <Box fontSize="17px" fontWeight="600" padding="2px 0">
              Price Summary
            </Box>
            <Box display="flex">
              <Box flex={1} color="#565656">
                Property Charge/Night
              </Box>
              <Box fontWeight="600">₹{" "}
                      {bookingCheckout.brik.per_night_inr
                        ? bookingCheckout.brik.per_night_inr
                        : 0}
                      /Night</Box>
            </Box>
            <Box display="flex">
              <Box flex={1} color="#565656">
                In Brikk coin
              </Box>
              <Box display="flex">
                <Box>
                  <img
                    src={BrikCoin}
                    style={{ height: "16px", paddingTop: "4px" }}
                  />
                </Box>
                <Box color="#8B7862" paddingLeft="3px" fontWeight="600">
                {bookingCheckout.brik.per_night_coin
                          ? bookingCheckout.brik.per_night_coin
                          : 0}
                        /Night
                </Box>
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1} color="#565656">
                Total Booking Nights
              </Box>
              <Box fontWeight="600">{" "}
                      {moment(bookingCheckout.dates[1]).diff(
                        moment(bookingCheckout.dates[0]),
                        "days"
                      )}{" "}Nights</Box>
            </Box>
            <hr />
            <Box display="flex">
              <Box flex={1} fontWeight="bold">
                Total Payable amount{" "}
                <Box
                  component="span"
                  style={{ fontSize: "10px", color: "#565656" }}
                >
                  ({" "}
                        {moment(bookingCheckout.dates[1]).diff(
                          moment(bookingCheckout.dates[0]),
                          "days"
                        )}{" "}
                        X{" "}
                        {bookingCheckout.brik.per_night_inr
                          ? bookingCheckout.brik.per_night_inr
                          : 0}{" "}
                        )
                </Box>
              </Box>
              <Box fontWeight="600">₹&nbsp;
                      {(() => {
                        let days = moment(bookingCheckout.dates[1]).diff(
                          moment(bookingCheckout.dates[0]),
                          "days"
                        );
                        let value = bookingCheckout.brik.per_night_inr
                          ? bookingCheckout.brik.per_night_inr
                          : 0;

                        return days * value;
                      })()}
                      &nbsp;</Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Box fontWeight="bold" flex={1}>
                Total Payable Brik Coin
              </Box>
              <Box display="flex">
                <Box>
                  <img
                    src={BrikCoin}
                    style={{ height: "16px", paddingTop: "4px" }}
                  />
                </Box>
                <Box color="#8B7862" paddingLeft="3px" fontWeight="600">
                {(() => {
                          let days = moment(bookingCheckout.dates[1]).diff(
                            moment(bookingCheckout.dates[0]),
                            "days"
                          );

                          let value = bookingCheckout.brik.per_night_coin
                            ? bookingCheckout.brik.per_night_coin
                            : 0;

                          return days * value;
                        })()}
                        &nbsp;Coins
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
        <Accordion defaultExpanded style={{paddingBottom:"65px", display: pay ? "none" : "block",boxShadow:"0px 8px 25px rgba(83, 89, 144, 0.07)",
              borderRadius:"5px", backgroundColor:"white" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box display="flex" width="100%">
              <Box flex={1} fontWeight="700" fontSize="14px" paddingLeft="10px">
                Guest Details
              </Box>
              {mode == "read" ? (
                <Box
                  color="#4A8EAB"
                  onClick={(e) => {
                    setMode("edit");
                    e.stopPropagation();
                  }}
                >
                  Edit
                </Box>
              ) : (
                <Box
                  color="#4A8EAB"
                  onClick={(e) => {
                    submitForm();
                    setIsDetailsSubmitted(true);
                    e.stopPropagation();
                  }}
                >
                  Done
                </Box>
              )}
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            {mode == "read" ? (
              <Box
                width="50%"
                flexDirection="column"
                padding="0 11px"
                style={{ width: "100%" }}
              >
                <Box lineHeight="2">
                  <Box display="flex" width="100%">
                    <Box flex={1} color="#979797">
                      {" "}
                      FULL NAME
                    </Box>
                    <Box fontWeight="600" flex={1}>
                      {" "}
                      {values.name}
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row" width="100%">
                    <Box flex={1} color="#979797">
                      {" "}
                      MOBILE NUMBER
                    </Box>
                    <Box fontWeight="600" flex={1}>
                      {" "}
                      {values.phone}
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row" width="100%">
                    <Box flex={1} color="#979797">
                      {" "}
                      EMAIL ID
                    </Box>
                    <Box fontWeight="600" flex={1}>
                      {" "}
                      {values.email}
                    </Box>
                  </Box>
                </Box>
                <Box color="#565656" fontSize="12px" padding="10px 0px">
                  We’ll send your booking information on your mobile & email.
                </Box>
                {/* <Box display="flex" flexDirection="column" width="100%">
                  <Box flex={1} color="#979797" padding="6px 0">
                    {" "}
                    DESCRIPTION
                  </Box>
                  <Box lineHeight="1.6">
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Tincidunt fermentum, urna dolor elementum a volutpat
                    pellentesque. Diam turpis platea auctor odio integer.
                  </Box>
                </Box> */}
              </Box>
            ) : (
              <Box
                width="100%"
                flexDirection="column"
                padding="0 11px"
                style={{ width: "100%" }}
              >
                <Box lineHeight="2">
                  <Box>
                    <Box fontWeight="600" paddingBottom="3px">
                      FULL NAME
                    </Box>
                    <Box>
                    <TextField
                              id="name"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.name && errors.name}
                              helperText={touched.name && errors.name}
                              placeholder="Enter Full Name"
                              variant="outlined"
                              fullWidth
                            />
                    </Box>
                  </Box>
                  <Box padding="10px 0">
                    <Box fontWeight="600" paddingBottom="3px">
                      MOBILE NUMBER
                    </Box>
                    <Box>
                    <TextField
                              id="phone"
                              name="phone"
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.phone && errors.phone}
                              helperText={touched.phone && errors.phone}
                              placeholder="Enter Phone Number"
                              variant="outlined"
                              fullWidth
                            />
                    </Box>
                  </Box>
                  <Box>
                    <Box fontWeight="600" paddingBottom="3px">
                      EMAIL ID
                    </Box>
                    <Box>
                    <TextField
                              id="email"
                              name="email"
                              value={values.email}
                              error={touched.email && errors.email}
                              helperText={touched.email && errors.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Enter Email"
                              variant="outlined"
                              fullWidth
                            />
                    </Box>
                  </Box>
                </Box>
                <Box color="#565656" fontSize="12px" padding="10px 0px">
                  We’ll send your booking information on your mobile & email.
                </Box>
                <Box padding="10px 0">
                  <Box fontWeight="600" paddingBottom="3px">
                    ADD ANY INSTRUCTION IF ANY
                  </Box>
                  <Box>
                  <TextField
                            id="instructions"
                            name="instructions"
                            value={values.instructions}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Type Here"
                            variant="outlined"
                            fullWidth
                          />
                  </Box>
                </Box>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
        {paymentBtn === "show" ? (
                <Box
                marginLeft="-2%"
                padding="3%"
                fixed
                display="flex"
                width="100%"
                // position="absolute"
                bottom="0%"
                style={{ display: "flex",
                  paddingBottom:"50px", flexWrap:"wrap", justifyContent: "space-between", boxShadow:"0px 0px 6px 1px rgba(0, 0, 0, 0.15)",backgroundColor:"white" }}
               >
                 <Box alignItems="left">
                   <Box display="flex" >
                     <Box>
                       <img
                         src={BrikCoin}
                         style={{ height: "16px", paddingTop: "4px" }}
                       />
                     </Box>
                     <Box color="#8B7862" paddingLeft="3px" fontWeight="600">
                     &nbsp;
                                 {bookingCheckout.brik.per_night_coin !== null
                                   ? bookingCheckout.brik.per_night_coin
                                   : 0}
                                 /Night &nbsp;
                     </Box>
                     <Box color="#8B7862" fontWeight="600">
                     (₹{" "}
                                   {bookingCheckout.brik.per_night_inr
                                     ? bookingCheckout.brik.per_night_inr
                                     : 0}
                                   /Night)
                     </Box>
                   </Box>
                   <Box color="#8B7862" fontWeight="600">
                   {moment(bookingCheckout.dates[0]).format(
                                     "DD MMM"
                                   )}{" "} - {" "}{moment(bookingCheckout.dates[1]).format(
                                     "DD MMM"
                                   )}
                   </Box>
                 </Box>
                 {!isDetailsSubmitted && (
                   <Button
                   alignItems="right"
                   variant="contained"
                   color="#4A8EAB"
                   style={{backgroundColor:"#4A8EAB", width:"40%", color:"white"}}
                   onClick={(e)=>{
                    onSubmitGuestDetails(e)
                   }}
                  >        
                   NEXT
                 </Button>
       
      )}
                 
                  {isDetailsSubmitted && (<>
                    {/* <Button
                     variant="contained"
                     color="secondary"
                     style={{width:"48%"}}
                     fullWidth
                     onClick={handleOpenPopup}
                     // onClick={()=>{
                     //   getPayments(922);
                     // }}
                   >
                      Pay with Coins
                   </Button>
      <Button
      variant="contained"
      color="secondary"
      style={{width:"48%"}}
      fullWidth
      onClick={()=>{handleCashPayment(values)}}
      // onClick={()=>{
      //   getPayments(922);
      // }}
    >
       Pay with Cash
    </Button> */}
    <div style={{width:"100%",display: "flex", justifyContent:"space-between" , marginTop:"10px"}}> 
    <button     onClick={handleOpenPopup} style={{ width:"48%", fontSize:"14px", borderRadius:"5px" , border:"none", color:"white",backgroundColor:"#4A8EAB", paddingTop:"18px", paddingBottom:"18px"}} >PAY WITH COIN </button>
    <button   onClick={()=>{handleCashPayment(values)}} style={{width:"48%", fontSize:"14px",borderRadius:"5px" , border:"none", color:"white",paddingTop:"18px" , backgroundColor:"#8B7862", paddingBottom:"18px"}} >PAY WITH CASH </button>
    </div>

                  </>
                    
      )}
                      {isPopupOpen && (
        <TransactionPopup
          availableCoins={balance}
          payAmount={ (() => {
            let days = moment(bookingCheckout.dates[1]).diff(
              moment(bookingCheckout.dates[0]),
              "days"
            );

            let value = bookingCheckout.brik.per_night_coin
              ? bookingCheckout.brik.per_night_coin
              : 0;

            return days * value;
          })()}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
      )}
               </Box>
              ) : (
                <>
                 
                </>
              )}
               {partialPay && ( <Box style={{marginBottom:"30px"}} >
                    <Box
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                        padding: "20px 0px 10px 0",
                      }}
                    >
                      Select Payment Method
                    </Box>
                        <Box display="flex" width="100%">
                          <Box
                            flex={1}
                            fontWeight="700"
                            fontSize="14px"
                            paddingLeft="10px"
                          >
                            Recommended
                          </Box>
                        </Box>
                        <Box>
                          <Box
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            <Box>
                              <Radio
                                checked={selectedValue === "a"}
                                onChange={handleChange2}
                                value="a"
                                name="radio-button-demo"
                                inputProps={{ "aria-label": "A" }}
                              />
                            </Box>
                            <Box padding="0 10px">
                              <Box display="flex">
                                <Box>
                                  <img
                                    src={BrikCoin}
                                    style={{ width: "20px" }}
                                  />
                                </Box>
                                <Box
                                  paddingLeft="10px"
                                  color="#8B7862"
                                  fontWeight="600"
                                >
                                  Brikitt Coin
                                </Box>
                              </Box>
                              <Box
                                paddingLeft="30px"
                                display="flex"
                                padding="3px 0"
                              >
                                <Box paddingRight="9px" color="#9B9B9B">
                                  Balance:
                                </Box>
                                <Box>
                                  <img
                                    src={BrikCoin}
                                    style={{ width: "15px", paddingTop: "3px" }}
                                  />
                                </Box>
                                <Box color="#B88952">&nbsp;{balance}</Box>
                              </Box>
                              <Box
                                paddingLeft="30px"
                                display="flex"
                                color="#9B9B9B"
                              >
                                <Box>Minimum recharge required:</Box>
                                <Box paddingLeft="6px">
                                  <img
                                    src={BrikCoin}
                                    style={{ width: "15px", paddingTop: "3px" }}
                                  />
                                </Box>
                                <Box color="#B88952">
                                  &nbsp;
                                  {bookingCheckout.cart_details
                                    .total_payable_coins - balance}
                                </Box>
                                <Box color="#9B9B9B">
                                  &nbsp;(
                                  {
                                    bookingCheckout.cart_details
                                      .total_payable_inr
                                  }
                                  )
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          {/* <Box
                            padding="4px 8px"
                            margin="11px 0"
                            style={{ backgroundColor: "#4A8EAB16" }}
                          >
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Ullamcorper commodo, at imperdiet feugiat
                            commod.
                          </Box> */}
                          <Box>
                            <Button
                              variant="contained"
                              color="secondary"
                              fullWidth
                              onClick={() => {
                                let payByCoin = false;
                                let payOnline = false;

                                if (
                                  balance >
                                  bookingCheckout.cart_details
                                    .total_payable_coins
                                ) {
                                  //if usr have enough balance to book brik
                                  payByCoin = true;
                                } else if (balance > 0) {
                                  //if usr don't have enough balance to book brik but he had balance to pay
                                  payByCoin = true;
                                  payOnline = true;
                                } else {
                                  setPaymentBtn(false);
                                  //setPays(false)
                                  payOnline = true;
                                }

                                addBooking({
                                  brik_id: bookingCheckout.brik.id,
                                  spv_id: localStorage.getItem('spv_id'),
                                  date_check_in: moment(
                                    bookingCheckout.dates[0]
                                  ).format("YYYYMMDD"),
                                  date_check_out: moment(
                                    bookingCheckout.dates[1]
                                  ).format("YYYYMMDD"),
                                  adult: bookingCheckout.members.adults,
                                  children: bookingCheckout.members.kids,
                                  name: values.name,
                                  phone: values.phone,
                                  email: values.email,
                                  instructions: values.instructions,
                                  pay_by_coin: payByCoin,
                                  pay_online: payOnline,
                                })
                                  .then((result) => {
                                    console.log(
                                      "payment details",
                                      result.data.order_token
                                    );
                                    let token = result.data.order_token;
                                    if(token){
                                      setPaymentBtn(false);
                                      setPay(true);
                                      renderDropin(token);
                                    }else{
                                      notification.enqueueSnackbar(
                                        "Cash Payment is not allowed for this Booking",
                                        { variant: "error" }
                                      );
                                    }
                                   
                                       
                                    //console.log("token",token)
                                    // setOrderToken(token)
                                    //console.log(orderToken)
                                    // setPay(true);
                                    // renderDropin(token);
                                    //renderDropin(token)
                                  })
                                  .catch((e) => {
                                 const errorMessage = typeof e.response.data.error === "string" 
  ? e.response.data.error 
  : "Cash Payment is unavailable for this Booking";






                             
                                    notification.enqueueSnackbar(
                                      errorMessage,
                                      {
                                        variant: "error",
                                      }
                                    );
                                  })
                              }}
                            >
                              Add Money & Pay
                            </Button>
                          </Box>
                        </Box>
                  </Box> )}
      </Box>
    </>
  );
}

export default Payment;
