import React, { useEffect, useState } from "react";
import { useAuth } from "../providers/AuthProvider";
import { readToken, registerToken } from "../store/actions/apis";
import { getToken } from "./firebaseInit";

function GenerateToken() {
  const [isTokenFound, setTokenFound] = useState(false);
  const auth=useAuth();
  
  useEffect(() => {
    if ('Notification' in window) {
      console.log("Notification.permission: ", Notification.permission);

      if (Notification.permission == "granted") Notification.requestPermission();
      else if (Notification.permission == "denied")
        Notification.requestPermission()
    }
  }, []);

  useEffect(() => {
    let data;
    // if(auth.getUser()){
      console.log("CREATING TOKEN");
      
      async function tokenFunc() {
        data = await getToken(setTokenFound);
        if (data) {
          readToken(data).then(result => {
            console.log("TOKEN: Read API: ", result)
          }).catch(er => {
            console.log("ERROR: ", er)
            registerToken({
              registration_id: data,
              type: "web"
            })
          })
          console.log("Token is", data);
        }
        return data;
      }
  
      tokenFunc();
    // }
  }, [setTokenFound]);

  return <></>;
}

export default GenerateToken;
