import AccountReducer from "./reducers/AccountReducer";
import BookingReducer from "./reducers/BookingReducer";
import MyOwnershipReducer from "./reducers/MyOwnershipReducer";
import UserReducer from "./reducers/UserReducer";
import WalletReducer from "./reducers/WalletReducer";

const { combineReducers } = require("redux");

export const reducers = combineReducers({
  userReducer: UserReducer,
  myOwnerShip: MyOwnershipReducer,
  account: AccountReducer,
  booking: BookingReducer,
  wallet: WalletReducer,
});
