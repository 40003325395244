import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, Button, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { responsiveStyles } from "../../assets/css/generalStyling";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useFormik } from "formik";
import * as yup from "yup";
import FilePicker from "../../utils/FilePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  updateBankDetails,
  addBankDetails,
  getProfileData,
} from "../../store/actions/apis";
import { useAuth } from "../../providers/AuthProvider";
import { useSnackbar } from "notistack";
import { setProfileData } from "../../store/actions/accountActions";

function BankAcDetails(props) {
  const responsive = responsiveStyles();
  const auth = useAuth();
  const dispatch = useDispatch();
  const notification = useSnackbar();
  const [formMode, setFormMode] = useState("add");
  const BankDetails = useSelector((state) => {
    return state.account.bank_account_details;
  });

  let schema = yup.object().shape({
    bank_name: yup.string().required("Bank Name is required"),
    account_holder_name: yup
      .string()
      .required("Acoount Holder Name is required"),
    account_number: yup.string().required("Account Number is required"),
    ifsc_code: yup.string().required("IFSC CODE is required"),
  });

  let {
    values,
    touched,
    errors,
    handleBlur,
    setValues,
    setFieldValue,
    submitForm,
    resetForm,
    handleChange,
  } = useFormik({
    initialValues: {
      bank_name: "",
      account_holder_name: "",
      account_number: "",
      ifsc_code: "",
      bank_statment: "",
      bank_statment_file: "",
    },
    validationSchema: schema,
    onSubmit: () => {
      let fd = new FormData();

      let apiBody = {
        account_holder_name: values.account_holder_name,
        account_number: values.account_number,
        ifsc_code: values.ifsc_code,
        bank_name: values.bank_name,
        bank_statement: values.bank_statment_file,
      };

      if (typeof values.bank_statment_file !== "object") {
        delete apiBody.bank_statment;
      }

      for (var pair of Object.entries(apiBody)) {
        console.log(pair[0] + ", " + pair[1]);
        fd.append(pair[0], pair[1]);
      }

      if (formMode == "add") {
        addBankDetails({
          apiBody: fd,
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW;",
          },
        }).then((result) => {
          // console.log("RESULT: ", result)
          getProfileData(auth.getUser().user_id).then((result) => {
            dispatch(setProfileData(result.data));
          });
          notification.enqueueSnackbar("Bank Details Saved Successfully", {
            variant: "success",
            autoHideDuration: 2000,
          });
        });
      } else
        updateBankDetails({
          user_id: values.id,
          apiBody: fd,
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW;",
          },
        }).then((result) => {
          console.log("RESULT: ", result);
          getProfileData(auth.getUser().user_id).then((result) => {
            dispatch(setProfileData(result.data));
          });

          notification.enqueueSnackbar("Bank Details Saved Successfully", {
            variant: "success",
            autoHideDuration: 2000,
          });
        });
    },
  });

  useEffect(() => {
    if (BankDetails !== null) {
      setFormMode("edit");
      setValues({
        ...BankDetails,
        bank_statment: BankDetails.bank_statement,
        bank_statment_file: BankDetails.bank_statement,
      });
    }
  }, [BankDetails]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box
            display="flex"
            padding="10px 0"
            fontWeight="700"
            fontSize="19px"
            onClick={() => {
              props.onBack();
            }}
          >
            <Box className={responsive.app}>
              <ChevronLeftIcon />
              &nbsp;
            </Box>
            <Box>Bank Account Details</Box>
          </Box>
          <Box>
            <Box padding="5px 0" fontWeight="700" fontSize="13px">
              BANK NAME
            </Box>
            <TextField
              id="bank_name"
              name="bank_name"
              value={values.bank_name}
              onChange={handleChange}
              error={touched.bank_name && errors.bank_name ? true : false}
              helperText={touched.bank_name && errors.bank_name}
              fullWidth
              variant="outlined"
              placeholder="Enter Bank Name"
              style={{ paddingBottom: "10px" }}
            />
          </Box>
          <Box>
            <Box padding="5px 0" fontWeight="700" fontSize="13px">
              ACCOUNT HOLDER NAME
            </Box>
            <TextField
              id="account_holder_name"
              name="account_holder_name"
              value={values.account_holder_name}
              onChange={handleChange}
              error={
                touched.account_holder_name && errors.account_holder_name
                  ? true
                  : false
              }
              helperText={
                touched.account_holder_name && errors.account_holder_name
              }
              fullWidth
              variant="outlined"
              placeholder="Enter Account Holder's Full Name"
              style={{ paddingBottom: "10px" }}
            />
          </Box>
          <Box>
            <Box padding="5px 0" fontWeight="700" fontSize="13px">
              ACCOUNT NUMBER
            </Box>
            <TextField
              id="account_number"
              name="account_number"
              value={values.account_number}
              onChange={handleChange}
              error={
                touched.account_number && errors.account_number ? true : false
              }
              helperText={touched.account_number && errors.account_number}
              fullWidth
              variant="outlined"
              placeholder="Enter Account Number"
              style={{ paddingBottom: "10px" }}
            />
          </Box>
          <Box>
            <Box padding="5px 0" fontWeight="700" fontSize="13px">
              IFSC CODE
            </Box>
            <TextField
              id="ifsc_code"
              name="ifsc_code"
              value={values.ifsc_code}
              onChange={handleChange}
              error={touched.ifsc_code && errors.ifsc_code ? true : false}
              helperText={touched.ifsc_code && errors.ifsc_code}
              fullWidth
              variant="outlined"
              placeholder="Enter IFSC Code"
              style={{ paddingBottom: "10px" }}
            />
          </Box>
          <Box>
            <Box padding="8px 0" fontWeight="700" fontSize="13px">
              UPLOAD BANK STATEMENT
            </Box>
            <FilePicker
              type="component"
              fileHandler={(file, alt) => {
                // console.log("FILE PICKED: ", URL.createObjectURL(file))
                setFieldValue("bank_statment_file", file);
                setFieldValue("bank_statment", URL.createObjectURL(file));
              }}
            >
              {values.bank_statment === "" ? (
                <Box
                  border="1px dashed black"
                  width="90px"
                  height="75px"
                  display="flex"
                  justifyContent="center"
                >
                  <IconButton>
                    <AddIcon color="secondary" />
                  </IconButton>
                </Box>
              ) : (
                <img
                  style={{
                    height: "70px",
                    width: "70px",
                    objectFit: "contain",
                  }}
                  src={values.bank_statment}
                />
              )}
            </FilePicker>
          </Box>
          <Box padding="10px 0">
            * Bank Statement should be one month latest{" "}
          </Box>
          <Box padding="20px 0" display="flex" justifyContent="center">
            <Button
              onClick={submitForm}
              variant="contained"
              color="secondary"
              style={{ width: "65%", borderRadius: "5px" }}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default BankAcDetails;
