import React from 'react'
import { AppBar, IconButton, Typography, makeStyles, Box, TextField, Avatar, Menu, MenuItem, Card, Grid } from '@material-ui/core';
import BellIcon from '../assets/BellIcon.png'
import { responsiveStyles } from '../assets/css/generalStyling';
// import { pushNotification } from '../store/actions/userActions';
import { onMessageListener } from '../firebase/firebaseInit';
import { useDispatch, useSelector } from 'react-redux';
// import { pushNotification } from '../store/actions/userActions';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';


function Notification() {
    const responsive = responsiveStyles()
    const dispatch = useDispatch()

    const { notifications } = useSelector((state) => {
        return {
            notifications: state.userReducer.notifications
        }
    })
    // onMessageListener()
    //     .then((payload) => {
    //         console.log([payload.notification, ...notifications]);
    //         console.log("payload.notification: ", payload.notification);

    //         dispatch(pushNotification(payload.notification))

    //         const notification = new Notification(payload.notification.title, {
    //             body: payload.notification.body,
    //         });
    //     })
    //     .catch((err) => console.log("failed: ", err));


    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12} className={responsive.app}>
                    <Box style={{ marginTop: "80px", display: "flex", alignItems: "center" }}>
                        <IconButton style={{ fontSize: "20px", fontWeight: "600" }} onClick={() => {
                            window.location.href = '/account'
                        }}>
                            <ChevronLeftIcon />
                        </IconButton>
                        <Box fontSize="20px" fontWeight="600">Notification</Box>
                    </Box>
                    <Box id="notifications" style={{ padding: "0 10px" }}>
                        {notifications.map((notification,key) => {
                            return (
                                <Box key={key} style={{ padding: "5px 0" }}>
                                    <Card style={{ display: "flex", padding: "15px", alignItems: "center" }}>
                                        <Box paddingRight="20px">
                                            <img src={BellIcon} />
                                        </Box>
                                        <Box style={{ width: "100%" }}>
                                            <Box style={{ display: "flex", width: "100%", justifyContent: "space-between", paddingBottom: "5px" }}>
                                                <Box flex={1} fontWeight="600">{notification.title}</Box>
                                                <Box fontStyle="italic">5 min ago</Box>
                                            </Box>
                                            <Box fontWeight="600">{notification.body}</Box>
                                        </Box>
                                    </Card>
                                </Box>
                            )
                        })}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Notification
