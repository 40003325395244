import { UPDATE_BALANCE } from "../constants";

const initialState = {
  balance: 0,
};

const WalletReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case UPDATE_BALANCE:
      return { ...state, balance: payload };

    default:
      return state;
  }
};

export default WalletReducer;
