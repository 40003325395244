import {
  BOOKING_CHECKOUT,
  SET_BOOKINGS,
  SET_SEARCH_BOOKINGS,
  SET_BOOKINGS_GET_API,
  SET_CART_DETAILS,
} from "../constants";
import moment from "moment";

let initialState = {
  briks: [],
  searchBriks: [],
  get_bookings: {
    bookings: [],
    no_booking: 0,
  },
  bookingCheckout: {
    brik: {},
    cart_details: {},
    dates: [moment(), moment().add(2, "days")],
    members: {
      adults: 1,
      kids: 0,
      infants: 0,
      pets: 0,
    },
  },
};

const BookingReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case SET_BOOKINGS:
      return {
        ...state,
        briks: payload,
      };

    case SET_SEARCH_BOOKINGS:
      return {
        ...state,
        searchBriks: payload,
      };

    case SET_BOOKINGS_GET_API:
      return {
        ...state,
        get_bookings: {
          ...payload,
        },
      };

    case SET_CART_DETAILS:
      return {
        ...state,
        bookingCheckout: {
          ...state.bookingCheckout,
          cart_details: payload,
        },
      };

    case BOOKING_CHECKOUT:
      let { key, data } = payload;

      if (key == "all") {
        return {
          ...state,
          bookingCheckout: data,
        };
      } else
        return {
          ...state,
          bookingCheckout: {
            ...state.bookingCheckout,
            [key]: data,
          },
        };
    default:
      return state;
  }
};

export default BookingReducer;
