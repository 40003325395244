import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Header from "./Header";
import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Button,
  ListItemIcon,
} from "@material-ui/core";
import RestoreIcon from "@material-ui/icons/Restore";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useHistory } from "react-router-dom";
import { icons, Logo } from "../assets/assets";
import { useAuth } from "../providers/AuthProvider";
import exploreIcon from "../assets/icons/Explore.svg";
import MyOwnership from "../assets/icons/My ownership.svg";
import acountIcon from "../assets/icons/account icon.svg";

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  selectedBottomNavigation: {
    color: "#8B7862 !important",
  },
  BottomNavigation: {
    position: "absolute",
    width: "100%",
    bottom: "0px",
    display: "none",
  },
  [theme.breakpoints.down("sm")]: {
    BottomNavigation: {
      display: "block",
    },
  },
}));

export const DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootListItem: {
    color: "#8B7862",
    // textAlign:"center"
  },
  selectedListItem: {
    color: "white",
    background:
      "linear-gradient(272.56deg, #77644E 0%, rgba(139, 120, 98, 0.75) 100%)",
    borderRadius: "27px",
    "&:hover": {
      borderRadius: "27px !important",
    },
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    height: "100vh",
    width: DRAWER_WIDTH,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -DRAWER_WIDTH,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function MainDrawer(props) {
  const classes = useStyles();
  const classes2 = useStyles2();
  const auth = useAuth();
  const theme = useTheme();
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [selected, setSelected] = React.useState(1);
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}
      <Header type="app" open={open} handleDrawerOpen={handleDrawerOpen} />
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose} style={{ color: "white" }}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List style={{ padding: "20px", marginTop: "30px" }}>
          <ListItem
            onClick={() => {
              // setSelected(1)
              window.open("https://brikitt.com");
            }}
            button
            selected={1 == selected}
            classes={{
              selected: classes.selectedListItem,
              root: classes.rootListItem,
            }}
          >
            {1 == selected ? (
              <ListItemIcon>
                <Avatar
                  style={{ height: "20px", width: "20px" }}
                  src={icons.exploreWhite}
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <Avatar
                  style={{ height: "20px", width: "20px" }}
                  src={icons.explore}
                />
              </ListItemIcon>
            )}
            <ListItemText primary="Explore" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              if (auth.getUser() !== null) {
                setSelected(2);
                history.push("/my-ownership");
              }
            }}
            selected={2 == selected}
            classes={{
              selected: classes.selectedListItem,
              root: classes.rootListItem,
            }}
          >
            {2 == selected ? (
              <ListItemIcon>
                <Avatar
                  style={{ height: "20px", width: "20px" }}
                  src={icons.myOwnershipWhite}
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <Avatar
                  style={{ height: "20px", width: "20px" }}
                  src={icons.myOwnership}
                />
              </ListItemIcon>
            )}
            <ListItemText primary="My Ownership" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              if (auth.getUser() !== null) {
                setSelected(3);
                history.push("/account");
              }
            }}
            selected={3 == selected}
            classes={{
              selected: classes.selectedListItem,
              root: classes.rootListItem,
            }}
          >
            {3 == selected ? (
              <ListItemIcon>
                <Avatar
                  style={{ height: "20px", width: "20px" }}
                  src={icons.accountWhite}
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <Avatar
                  style={{ height: "20px", width: "20px" }}
                  src={icons.account}
                />
              </ListItemIcon>
            )}
            <ListItemText primary="Account" />
          </ListItem>
          <ListItem
            button
            selected={4 == selected}
            onClick={() => {
              window.location.href = "https://www.brikitt.com/faq-contact";
            }}
            // onClick={() => {
            //   setSelected(4)
            //   history.push('/faq')
            // }}
            classes={{
              selected: classes.selectedListItem,
              root: classes.rootListItem,
            }}
          >
            {4 == selected ? (
              <ListItemIcon>
                <Avatar
                  style={{ height: "20px", width: "20px" }}
                  src={icons.faqWhite}
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <Avatar
                  style={{ height: "20px", width: "20px" }}
                  src={icons.faq}
                />
              </ListItemIcon>
            )}

            <ListItemText primary="FAQ" />
          </ListItem>
        </List>

        {auth.getUser()?.user_id && (
          <Box
            style={{
              padding: "13px",
              position: "absolute",
              bottom: "0px",
              width: "100%",
            }}
          >
            <Button
              onClick={() => {
                auth.logout();
              }}
              color="secondary"
              variant="contained"
              fullWidth
            >
              Sign Out
            </Button>
          </Box>
        )}
      </Drawer>
      <main
        style={{ minHeight: "100vh" }}
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Box style={{ maxHeight: "83vh", width: "100vw", overflowY: "auto" }}>
          {props.children}
        </Box>
        <Box className={classes2.BottomNavigation}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              switch (newValue) {
                case 1:
                  setSelected(1);
                  history.push("/my-ownership");
                  break;

                case 2:
                  setSelected(2);
                  history.push("/account");

                default:
                  setSelected(1);
                  history.push("/");
              }
              setValue(newValue);
            }}
            className={classes2.root}
          >
            <BottomNavigationAction
              // style={{ color: "#ACACAC" }}
              onClick={() => {
                window.location.href = "https://www.brikitt.com/projects";
              }}
              classes={{ selected: classes2.selectedBottomNavigation }}
              label="Explore"
              icon={<img src={exploreIcon} />}
            />
            {auth.getUser()?.user_id == undefined && (
              <BottomNavigationAction
                onClick={() => {
                  history.push("/");
                }}
                classes={{ selected: classes2.selectedBottomNavigation }}
                label="Login"
                icon={<LocationOnIcon />}
              />
            )}
            {auth.getUser() !== null && (
              <BottomNavigationAction
                onClick={() => {
                  history.push("/my-ownership");
                }}
                classes={{ selected: classes2.selectedBottomNavigation }}
                label="My Ownership"
                icon={<img src={MyOwnership} />}
              />
            )}

            {auth.getUser() !== null && (
              <BottomNavigationAction
                onClick={() => {
                  history.push("/account");
                }}
                classes={{ selected: classes2.selectedBottomNavigation }}
                label="Profile"
                icon={<img src={acountIcon} />}
              />
            )}
          </BottomNavigation>
        </Box>
      </main>
    </div>
  );
}
