import {
  Grid,
  Typography,
  TextField,
  Checkbox,
  Button,
  Link,
  Box,
  makeStyles,
  Avatar,
  Card,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Logo } from "../assets/assets";
import Adhaar from "../assets/adhaar.jpg";
import Check from "../assets/check.jpg";
import LoginSideImage from "../assets/LoginSideImage.png";
import { useAuth } from "../providers/AuthProvider";
import { useFormik } from "formik";
import { loginValdation } from "../validations";
import { useSelector } from "react-redux";
import { useGeneralProvider } from "../providers/GeneralProvider";
import { useSnackbar } from "notistack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { getDocumentById, updateDocumentData } from "../store/actions/apis";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AdhaarEsign() {
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(false);
  // const initialText=`We've sent you an e-mail. Please check your inbox.Please complete your Aadhaar E-sign to continue`;
  const initialText = `we've sent you an e-mail. Please complete your Aadhar E-sign to continue. Kindly use the Aadhar number associated with your BRIK to verify your identity.`;
  const resendText = `we've sent you an e-mail. Please complete your Aadhar E-sign to continue. Kindly use the Aadhar number associated with your BRIK to verify your identity.`;
  const [dialogText, setDialogText] = React.useState(initialText);

  const handleClickOpen = () => {
    if (selected) setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const useStyles = makeStyles((theme) => ({
    header: {
      height: "100vh",
      width: "100%",
      color: "white",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      background: `linear-gradient(to top, #00000073, #00000073), url(${LoginSideImage})`,
    },
    web: {
      visibility: "block",
    },
    app: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      web: {
        display: "none",
      },
      app: {
        display: "block",
      },
    },
    // notchedOutline: {
    //     borderWidth: '1px',
    //     borderColor: `${theme.palette.secondary .main} !important`
    // }
  }));

  const history = useHistory();
  const classes = useStyles();
  const general = useGeneralProvider();
  const auth = useAuth();
  const { isComponentBusy } = useSelector((state) => {
    return { isComponentBusy: state.userReducer.isComponentBusy };
  });
  const login = () => {
    // auth.login(values)
  };

  useEffect(() => {
    if (auth.getUser()) {
      if (
        auth.getUser().document_id == null &&
        auth.getUser().terms_accepted == false
      ) {
        let fd = new FormData();
        fd.append("document_id", "12345");
        updateDocumentData(fd);
      } else {
        let fd = new FormData();
        fd.append("document_id", auth.getUser().document_id);
        updateDocumentData(fd).then((result) => {
          let user = JSON.parse(localStorage.getItem("user"));
          user = { ...user, ...result };
          localStorage.setItem("user", JSON.stringify(user));
          if (result.terms_accepted) history.push("/");
          else {
            setDialogText(resendText);
            setOpen(true);
          }
        });
      }
    } else history.push("/");
  }, []);

  const { values, touched, errors, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        username: "",
        password: "",
      },
      onSubmit: () => {
        login();
      },
      validationSchema: loginValdation,
    });

  return (
    <>
      <Grid alignItems="center" className={classes.web} container>
        <Grid
          style={{
            color: "white",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100vh",
          }}
          item
          xs={3}
        >
          <div className={classes.header}>
            <Box fontSize="12px">WELCOME TO </Box>
            <Box
              style={{
                fontSize: "18px",
                fontWeight: 600,
                paddingBottom: "12px",
                paddingTop: "4px",
              }}
            >
              BRIK itt
            </Box>
            <Box fontSize="11px">Please login to continue</Box>
            <Box
              style={{
                color: "#E5E5E5",
                fontSize: "22px",
                padding: "40px",
                fontWeight: 600,
              }}
            >
              FRACTIONAL OWNERSHIP SIMPLIFIED
            </Box>
            <Box
              style={{
                color: "#E5E5E5",
                fontSize: "12px",
                padding: "0px 25px",
              }}
            >
              BRIK itt is India’s Leading Proptech Company unlocking Fractional
              Ownership in Private & Residential Real Estate. It brings to the
              people the opportunity to own Exclusive luxury residential &
              vacational assets.
            </Box>
          </div>
        </Grid>
        {/* <Grid style={{ color: "white", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", backgroundImage: `url(${LoginSideImage})`, opacity: 1, height: "100vh" }} item xs={3} >
                    <Box fontSize="12px">WELCOME TO </Box>
                    <Box style={{ fontSize: "18px", fontWeight: 600, paddingBottom: "12px", paddingTop: "4px" }}>BRIK itt</Box>
                    <Box fontSize="11px">Please login to continue</Box>
                    <Box style={{ color: "#E5E5E5", fontSize: "22px", padding: "40px", fontWeight: 600 }}>Enjoy Ownership Experience in simple way</Box>
                    <Box style={{ color: "#E5E5E5", fontSize: '12px', padding: "0px 25px" }}>BRIK itt is India’s Leading  Proptech Company unlocking Fractional Ownership in Private & Residential Real Estate. It brings to the people the opportunity to own Exclusive luxury residential & vacational assets.</Box>
                </Grid> */}
        <Grid container justifyContent="center" item xs={8}>
          <Grid item xs={12}>
            <Box
              padding={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <Avatar src={Logo} />
              <Box
                style={{
                  padding: "0px 10px",
                  color: "#8B7862",
                  fontWeight: 500,
                }}
              >
                BRIKITT
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Box
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "24px",
                }}
              >
                Terms and Conditions
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="stretch"
                padding={1}
                height="420px"
                overflow="scroll"
              >
                <b>BRIK Owner's Review for confirmation of Understanding</b>
                <br />
                Dear BRIK Owner(s),
                <br />
                <br />
                Here are a few key points about your BRIK Ownership that you
                should be clear about. Please read each point below in
                conjunction with the detailed Terms & Conditions for complete
                understanding.
                <br />
                <br />
                1. I/We understand the Terms and Conditions of the special offer
                that I/we have chosen at the time of signing up for purchase of
                BRIK.
                <br />
                <br />
                2. I/We confirm having read the Terms and Conditions governing
                purchase of BRIK Ownership Usage and the Terms and Conditions
                with regard to BRIK ownership and agree to abide by the same.
                All necessary clarifications and information on the BRIK
                Ownership have been provided to us.
                <br />
                <br />
                3. I/We understand that any payment made by me/us would first be
                appropriated toward the Booking Fees and balance if any would be
                appropriated towards the entitlement fees.
                <br />
                <br />
                4. I/We understand that in the event of request for cancellation
                by me/us the BRIKitt shall have the right to deduct cancellation
                charges as mentioned in the Terms and Conditions and would
                refund the balance amount only.
                <br />
                <br />
                5. I/We understand that I/we shall be admitted as BRIK Owner of
                BRIK Ownership only upon realization of the entire amount and
                EMI's as per the scheme opted by me, to be entitled to the
                benefits of BRIK Ownership .<br />
                <br />
                6. I/We understand that the number of people who can occupy the
                apartment is as follows:
                <ul>
                  <li>2 BHK Apartment - 6 Adults</li>
                  <li>1 BHK Apartment – 4 Adults</li>
                  <li>Studio Apartment - 3 Adults</li>
                  (Children above 12 years shall be considered as adult for the
                  purpose of occupancy. 2 children below 12 years to be
                  considered as one adult).
                </ul>
                7. I/We understand that the gifting of the BRIK is my/our
                responsibility and in this regard the BRIKitt does not undertake
                any responsibility or liability.
                <br />
                <br />
                8. I/We understand that I/we can make my/our own arrangements to
                sell my/our BRIK and Ownership rights & that the BRIKitt shall
                not buy it back from me/us.
                <br />
                <br />
                9. I/We understand that the confirmation for reservation of
                regular unit is subject to availability and eligibility. I/We
                are aware that without the Confirmation of booking issued by the
                BRIKitt by mail/hard copy, else I/we will not have a right of
                entry into any property.
                <br />
                <br />
                10. I/We understand that the BRIKitt needs to maintain the
                property towards which I/we confirm having agreed to pay Annual
                management Fees (AMF) whether I/we avail of BRIK in a particular
                year or not. Non-payment of the same shall result in my/our
                disentitlement from the use of the entitlements and BRIKitt
                shall have the right to cancel the usage rights if I/we do not
                pay the AMF
                <br />
                <br />
                11. I/We understand that the Promotional Offer mentioned in the
                application form & signed by me/us will be final.
                <br />
                <br />
                12. I /We understand that any part payment made by me towards
                any outstanding shall first be appropriated towards interest and
                then earlier dues /outstanding.
                <br />
                <br />
                13. In case of default in payment of any installment by me/us,
                the BRIKitt shall have the right to terminate my/our BRIK
                Ownership for occupancy and that the Terms and Conditions
                mentioned in BRIK Ownership agreement shall apply.
                <br />
                <br />
                14. I/We have read the rules of cancellation, and agree to the
                same.
                <br />
                <br />
                15. I/We confirm that there are no other verbal/written promises
                or any other assurances not mentioned in the BRIK OWNERSHIP that
                have been made by any BRIKitt personnel.
                <br />
                <br />
                <br />
                <b>Payment Consent</b>
                <ul>
                  <li>
                    The amount paid for booking shall be deemed refundable if
                    BRIKitt fails to deliver the property or is unable to gather
                    the rest co owners or cancels the booking from its end to
                    the BRIK owner.
                  </li>
                  <li>
                    The BRIKitt doesn't accept payments in Cash. All payments to
                    the BRIKitt is to be made form of cheque/draft favoring
                    "BRIKitt", or through Credit Cards. Any payment in cash and
                    cheque/draft not favoring the BRIKitt is at the BRIK Owner's
                    risk and the BRIKitt will not be liable for the same.
                  </li>
                  <li>
                    BRIKitt is liable to return the booking amount with an
                    interest of 11 % pa if all the co-owners are not brought on
                    board within 180 to 365 days depending upon the property
                    type as specified at the time of booking.
                  </li>
                  <li>
                    If the BRIK owner cancels the booking from its consent the
                    BRIKitt holds the right to forfeit the payment and the
                    amount paid deems Non-re fundable. I/We hear by agree that
                    if under any circumstances booking of BRIK is cancelled by
                    Owner the booking amount deemed.
                  </li>
                </ul>
                <br />
                <b>METHODS OF PAYMENTS AND REFUNDS</b>
                <ul>
                  <li>
                    You should quote your booking details on all bank transfers
                    and cheques made payable to us to avoid error and undue
                    delay.
                  </li>
                  <li>
                    You are responsible for paying your own bank's charges in
                    respect of all payments made to and refunds received from
                    BRIKitt except in cases where BRIKitt is found to be at
                    fault. We will refund any payments made by you to us in the
                    same manner and currency as the original payment was made.
                  </li>
                </ul>
                <br />
                <b>YOUR APPLICATION FOR OWNERSHIP</b>
                To become a Owner, you must:
                <br />
                <ol>
                  <li>You must be at least 18 years of age;</li>
                  <li>
                    Complete and submit an enrolment application to BRIKitt in
                    the prescribed form (such application should be submitted
                    either by yourself personally or on your behalf);
                  </li>
                  <li>Pay the application fee to BRIKitt.</li>
                  <li>Have your application accepted by BRIKitt.</li>
                  <li>
                    We reserve the right to refuse any enrollment application,
                    including without limitation if required to do so by the
                    laws, rules or regulations of any local, state, national or
                    federal governmental entity or by any judicial, public,
                    regulatory or law enforcement authority or court.
                  </li>
                  <li>
                    Your BRIK ownership subject to these terms takes effect from
                    the date we write to notify you through mail.
                  </li>
                  <li>
                    If a company, partnership, trust, unincorporated association
                    or other entity wishes to owns BRIK Ownership, ownership
                    must be in the name of a natural person nominated by the
                    company or other entity to represent it.
                  </li>
                  <li>
                    Up to 2 co-owners of BRIK Ownership who live at the same
                    address may apply for one ownership. You must nominate a
                    lead owner to act as our principal contact for matters
                    relating to your ownership.
                  </li>
                  <li>
                    BRIKitt shall be permitted to take instructions from and
                    disclose information about your ownership to either
                    co-owner.
                  </li>
                  <li>
                    If we are given conflicting instructions from co-owners, we
                    are entitled to take the first set of instructions received
                    and act on those instructions. If we continue to receive
                    conflicting instructions received and act on those
                    instructions. If we continue to receive conflicting
                    instructions from co-owners, we may at our reasonable
                    discretion suspend or cancel (with an appropriate refund)
                    exchange privileges relating to your BRIK Ownership unless
                    such instructions can be promptly reconciled or resolved.
                  </li>
                  <li>
                    Co-owners of BRIK Ownership residing at different addresses
                    must apply for separate BRIK.
                  </li>
                </ol>
                <br />
                <br />
                <b>BRIK GENERAL CONDITIONS</b>
                <ol type="a">
                  <li>
                    A sole applicant shall purchase BRIK in his/her name, in
                    case of two applicants the application will be treated as a
                    former or Survivor basis application. More than two persons
                    shall not be entitled for applying on any account. Besides,
                    for such joint application the applicants should be from the
                    same family consisting of father, mother, spouse and lineal
                    of spring.
                  </li>
                  <li>
                    All communication to/from the Company shall be to the first
                    applicant.
                  </li>
                  <li>
                    The Company does not undertake to pay rent, buy back or sell
                    or facilitate the exchange of the BRIK of a Customer without
                    his/her consent.
                  </li>
                  <li>
                    The Company reserves its right to market the Apartments or
                    any portion thereof in respect of any day to free individual
                    traveler.
                  </li>
                  <li>
                    If the Customer, does not check-into the property, the BRIK
                    shall be mentioned from the dashboard as occupied, and the
                    company shall neither be liable to provide any other
                    accommodation in any property and BRIKitt will not bear any
                    compensation, claim or damages.
                  </li>
                  <li>
                    In the event of any delayed check-in, it shall be
                    responsibility of the occupant/Owner to inform the concerned
                    Manager on site. In the absence of any such intimation the
                    Company reserves the right to release the booking without
                    paying any compensation of nights or rent. Herein above, in
                    the event of the any partial utilization of the unit due to
                    delayed check-in/early departure or otherwise, the number of
                    days debited shall be as per the original booking.
                  </li>
                  <li>
                    The Customer shall promptly inform the change of address to
                    the company in writing supported with a valid KYC (know your
                    Customer) document.
                  </li>
                  <li>
                    The Company or its subsidiaries has rights to promote
                    various holiday homes. The BRIK customers shall not have
                    free right to claim or access to such resorts promoted by
                    the Company.
                  </li>
                  <li>
                    The purchase of BRIK shall be construed as purchase of
                    Equity/Preference Shares in the SPV (Special Purpose
                    Vehicle) and ownership right or leasehold right, over the
                    property of the SPV; The purchase of BRIK also allows the
                    Customer to get accommodation in the unit for stay as per
                    the t erms and conditions and does give him/her/it any
                    rights or interest of whatsoever nature over the SPV or its
                    assets and properties. In addition to the above and not
                    withstanding anything contained anywhere in this set of
                    terms and Conditions, it is hereby fully clarified and the
                    Customer fully understands and confirms that.
                  </li>
                  <li>
                    The Company shall not be liable to the Customer if it is not
                    in a position to fulfill obligations under BRIK, provided
                    the same is due to any war/civil commotion, any force
                    majeure reasons, Act of God or any other
                    notification/order/decree from any government/Local
                    Body/Court of Law of for any reason which is beyond the
                    reasonable control of the Company.
                  </li>
                  <li>
                    Any promises and commitments, made either in writing or in
                    words by any person representing the Company, outside the
                    purview of or in contradiction to these terms and conditions
                    are not binding on the Company.
                  </li>
                  <li>
                    If any provision or provisions of these terms shall be held
                    to be invalid, illegal, unenforceable, or in conflict with
                    the law of any jurisdiction, the validity, legality, and
                    enforceability of the remaining provisions shall not be in
                    any way affected or impaired thereby.
                  </li>
                  <li>
                    The Company doesn't accept payments in Cash. All payments to
                    the Company is to be made form of cheque/draft favoring
                    "BRIKitt", or through Credit Cards. Any payment in cash and
                    cheque/draft not favoring the company is at the Customer's
                    risk and the Company will not be liable for the same.
                  </li>
                  <li>
                    The Customer is aware that in order to help him make the
                    best use of product and case of transactions, the Company
                    needs to communicate the updates related to the customer
                    like payments transaction, vacation offers, promotions &
                    other ownership related information and hence irrevocably
                    empowers the Company to communicate with him/her vide any
                    mode of communication during the entire tenure of his
                    membership even if the customer has registered
                    himself/herself under the TRAI rules. In the event of change
                    in contact details, it's the customers’ responsibility to
                    communicate the same and ensure communication does not
                    continue to go to old contact details.
                  </li>
                  <li>
                    In case of violation of any of the house rules by the
                    Customer or his/her guest, at any of the unit or any other
                    act of the Customer whereby the Company's reputation/image
                    is likely to be tarnished by such behavior the Company
                    reserves the right to cancel the usage rights BRIK of the
                    Customer.
                  </li>
                  <li>
                    All dispute(s), difference(s) or question(s) arising out of
                    this transaction or otherwise shall be resorted to
                    Arbitration as per the provisions of the Arbitration and
                    Conciliation Act 1996, with such modifications/amendments
                    thereto and venue of such arbitration shall be Lucknow only.
                    The Sole Arbitrator shall be nominated by the board of
                    Directors of the Company. The language used in Arbitration
                    proceedings shall be English/Hindi only. The Parties agree
                    to be bound by the award passed by the Arbitrator.
                  </li>
                  <li>
                    In respect of all matters between the parties hereto or
                    his/her/heir(s)/representative(s) only the Civil Courts in
                    Lucknow City alone shall have exclusive jurisdiction and to
                    the exclusion of all other Courts.
                  </li>
                  <li>
                    The Company reserves the right to modify/amend/after any of
                    the terms and conditions contained herein and/or impose
                    additional conditions at its sole discretion. Such clauses
                    will be duly notified in the website of the Company. In
                    addition, the Customer is subject to the prevailing rules &
                    regulations of the unit concerned during the period of
                    his/her stay.
                  </li>
                  <li>
                    The Customer's BRIK shall automatically cancel. if Customer
                    being an individual is declared insolvent or bankrupt and in
                    case of a corporate entity or juristic person when it
                    completes liquidation of disbandment process or otherwise no
                    longer exists, even if (where possible) it is later restored
                    by an official or court order or decision.
                  </li>
                </ol>
                <br />
                <br />
                <b>BRIKitt's OBLIGATIONS</b>
                <br />
                <p>
                  In case Company does not provide vacation after issuance of
                  confirmation voucher for the unit, Company shall provide
                  alternate accommodation and in the event of default in
                  providing alternate accommodation
                </p>
                <p>
                  In all cases where company provided alternate accommodation or
                  pays liquidated damages, the number of nights confirmed by
                  Company shall be debited to the Customer's account.
                </p>
                <p>
                  Notwithstanding anything stated here in above, Company shall
                  not incur any liability to the Customer if it is not in a
                  position to fulfil its obligations by reason of any war, civil
                  commotion, force majeure, act of God, any notification from
                  any Court of Law or Government or any due other reason beyond
                  its control.
                </p>
                <p>
                  BRIKitt will not dilute the SPV or Property before a tenure of
                  7 years from the date of possession of unit to SPV.
                </p>
                <br />
                <b>LIMITATION OF LIABILITY</b>
                <br />
                <br />
                <p>
                  Company makes no warranty, explicitly or implicitly, without
                  Limitation with respect to the availability, quality or
                  suitability of the accommodation facility provided in any unit
                  including all amenities thereon and expressly disclaims the
                  warranties or conditions of merchantability and fitness for
                  any particular purpose. Besides, under any circumstances, the
                  company shall not be liable for any special, indirect,
                  incidental, or consequential damages of any kind whatsoever
                  (including, without limitation attorneys' fees) in any way due
                  to resulting from, or arising in connection with the BRIK or
                  the unit or the failure of company to perform its obligations
                  or for any alleged deficiency of service, regardless of any
                  negligence. Except as otherwise provided, the accommodation
                  and amenities in the unit are provided on an "as is", "as
                  available" basis and the Company disclaims all warranties.
                </p>
                <br />
                <br />
                <b>REQUESTING AN EXCHANGE</b>
                <br />
                <ol type="a">
                  <li>
                    12.1 You must upon receipt of intimation by us in this
                    regard (if any)) pay in advance the exchange fee current on
                    the date of request for each exchange requested.
                  </li>
                  <li>
                    12.2 If BRIKITT is unable to confirm an exchange acceptable
                    to you we will at your request either hold the exchange fee
                    as a credit to your account against future exchange fees or
                    refund it to you.
                  </li>
                  <li>
                    12.4 Our ability to confirm an exchange request is dependent
                    on the availability of Holiday Ownership rights deposited or
                    forecast to be deposited by other owners in the BRIKitt Pool
                    which are acceptable to you. We therefore cannot guarantee
                    that any particular request for a resort, area, travel date,
                    type or size of accommodation, travel supplier or otherwise
                    relating to your exchange will be met. We will, though,
                    offer you alternative choices which may be available.
                  </li>
                  <li>
                    12.5 A legally binding contract Is formed when the exchange
                    reservation is made.
                  </li>
                  <li>
                    12.6 Confirmation of exchange will be valid only when issued
                    to you in writing by or email from BRIKitt. You should check
                    all the details on your confirmation carefully when you
                    receive it and let us know as soon as possible if anything
                    is incorrect. Subsequent changes made by you to any material
                    aspect of your confirmation may be treated as a
                    cancellation.
                  </li>
                  <li>
                    Shareholders will not have any rights to eliminate Directors
                    of SPV, until BRIKitt is out of business. In AGM
                    shareholders can keep a resolution for removal of directors,
                    but it is on BRIKitt's management discretion for approval.
                  </li>
                  <li>
                    13.1 owners accept that accommodation may vary in unit
                    Sizes, design, fixtures. Furnishings, amenities and
                    facilities from their own BRIK Ownership.
                  </li>
                  <li>
                    13.2 Management may, where necessary, allocate alternative
                    accommodation to the unit confirmed provided it has all at
                    least the same maximum occupancy and is of similar overall
                    quality.
                  </li>
                  <li>
                    13.3 Members and/or Guests must occupy and use any
                    accommodation into which they have exchanged in a
                    responsible, careful and sole manner.
                  </li>
                  <li>
                    13.4 Members are responsible for any damage caused by them
                    and/or by their Guests whilst staying at an Property.
                  </li>
                  <li>
                    13.5 You and/or your Guests must comply with the in house
                    rules and bye-laws.
                  </li>
                  <li>
                    13.6 You and/or your Guests must replace any items missing
                    from your unit on departure or Management may charge you
                    and/or your Guests for replacement of damages/loses
                  </li>
                  <li>
                    13.7 The total number of people (babies and children
                    included) occupying the accommodation must not exceed the
                    maximum occupancy of the unit set out in the exchange
                    confirmation or guests certificate otherwise the Manager may
                    refuse access.
                  </li>
                  <li>
                    13.8 Members are responsible for payment of any applicable
                    taxes, personal expenses, utility charges, security deposits
                    and other fees or charges levied by management on occupiers
                    for the use of amenities and facilities there.
                  </li>
                  <li>
                    13.9 You must comply with check-in and check-out times. If
                    you and/or your Guests are going to arrive outside the
                    check-in day and/or time set out in your confirmation, the
                    management must be contacted directly to make alternative
                    check-in arrangements, subject to availability. Management
                    may levy a charge or impose other conditions for any
                    accommodation outside the days specified on the
                    confirmation.
                  </li>
                  <li>
                    13.10 Members and their Guests may be refused entry to
                    Property if they cannot verify their identity by means of a
                    valid passport, driving, license or other form of Photo
                    identification.
                  </li>
                </ol>
                <br />
                <br />
                <b>COMMUNICATING WITH YOU</b>
                <br />
                <p>
                  BRIKitt is the sole controller of all date held in relation to
                  owners and their Guests and all processing of data relating to
                  your ownership and to your or your Guests' use of the exchange
                  or other accommodation or of related travel services offered
                  by BRIKitt is therefore subject to India's data protection
                  laws only.
                </p>
                <p>
                  We will maintain records relating to your ownership and to
                  your and your Guests' use of exchange or other accommodation
                  or of related travel services offered by BRIKitt (including
                  Information about you or your Guests provided by yourself,
                  your Guests or by third parties).We shall be entitled to
                  process such data for the purpose of providing you or your
                  Guests with the products and services requested; to keep you
                  or your Guests Informed of further offers from us which may be
                  of interest to you or your Guests (unless you or your Guests
                  write to us asking us to exclude you or them from such offers
                  or alternatively telephone, fax or email us to that effect)
                  and for our own administration, market analyses and
                  operational reviews.
                </p>
                <p>
                  You agree that BRIKitt, or any associated companies or third
                  parties authorized by BRIKitt, shall be entitled to make
                  contact with you or your Guests by post, e-mail, telephone,
                  including automated dialing equipment, facsimile
                  transmissions, and/or pre-recorded messages for the purposes
                  set out In this paragraph. We shall be entitled to disclose to
                  any associated companies and third parties (including but not
                  necessarily limbed to resorts, owners' associations, resort
                  trustees, management companies, airlines, insurance companies,
                  car hire companies, ferry and cruise operators and other
                  suppliers) such information as may be necessary to:
                </p>
                <p>
                  provide you or your Guests with products and services you or
                  they have requested: or
                </p>
                <p>
                  verity details relating to your ownership or your BRIK
                  Ownership rights or your Guests' use of exchange
                </p>
                <p>
                  or other accommodation: or notify you of travel or other
                  services or products which we believe may be of interest.
                </p>
                <p>
                  We shall be entitled to rely on your Guests' consent to
                  receive information regarding such products and services for a
                  period of up to five years after their occupation.
                </p>
                <p>
                  We shall be entitled to process such data for the purpose
                  providing you or your Guests with the products and services
                  requested; to keep you or your Guests Informed of further
                  offers from us which may be of interest to you or your Guests
                  (unless you or your Guests write to us asking us to exclude
                  you or them from such offers or alternatively telephone, fax
                  or email us to that effect) and for our own administration,
                  market Analyses and operational reviews.
                </p>
                <p>
                  You agree that BRIKitt, or any associated companies or third
                  parties authorized by BRIKitt, shall be entitled to make
                  contact with you or your Guests by post, e-mail, telephone,
                  including automated dialing equipment, facsimile
                  transmissions, and/or pre-recorded messages for the purposes
                  set out In this paragraph. We shall be entitled to disclose to
                  any associated companies and third parties (including but not
                  necessarily limbed to resorts, owners' associations, resort
                  trustees, management companies, airlines, insurance companies,
                  car hire companies, ferry and cruise operators and other
                  suppliers) such information as may be necessary to: Provide
                  you or your Guests with products and services you or they have
                  requested: or verity details relating to your ownership or
                  your BRIK Ownership rights or your Guests' use of exchange or
                  other accommodation: or notify you of travel or other services
                  or products which we believe may be of interest. We shall be
                  entitled to rely on your Guests' consent to receive
                  information regarding such products and services for a period
                  of up to seven years after their occupation.
                </p>
                <br />
                <br />
                <b>OTHER SERVICES</b>
                <br />
                <p>
                  These Terms of ownership do not apply to any services made
                  available by BRIKitt other than exchanges.
                </p>
                <p>
                  Without limitation, BRIKitt does not provide flights, coach or
                  train travel, car hire, insurance, ferries, cruises and tours.
                  Such services may be purchased from independent third party
                  suppliers and will be subject to the terms and conditions of
                  the suppliers concerned.
                </p>
                <p>
                  BRIKitt reserves the right to very, withdraw or add to the
                  services it provides at anytime, with or without notice.
                </p>
                <br />
                <br />
                MANAGEMENT & MANAGEMENT CHARGES
                <br />
                <ol type="a">
                  <li>
                    Notwithstanding the fact that the BRIKitt has appointed the
                    Management SPV to administer the expenses, management and
                    booking of Units.
                  </li>
                  <li>
                    Owners shall contribute in the form of an annual Management
                    Charge, to all reasonable costs assessed on the SPV
                    including, without prejudice to the generally of the
                    foregoing, the following:-
                  </li>
                  <ol type="i">
                    <li>
                      Maintenance, repair, redecoration, cleaning, and (where
                      necessary) renewal of the Units, services and facilities
                      provided by the Manage- ment Company for the benefit of
                      the Owners whether exclusive or in common with others
                      entitled.
                    </li>
                    <li>
                      Maintenance, repair and (when necessary) replacement of
                      furniture, equipment, utensils, provisions, furnishings,
                      fittings and fixtures in or about the Units and provided
                      at any other unit in any project.
                    </li>
                    <li>
                      The full amount of the rent payable by the Management
                      Company in respect of Units and Alternative Holiday Units
                      in order to facilitate maintenance, repair or
                      reconstruction works, such rent to be calculated at the
                      then current rates.
                    </li>
                    <li>
                      All outgoing incurred in respect of the Units, including
                      electricity, gas, water, rates, licenses, contributions to
                      the community of property owners to which the Units may
                      belong and any taxes or other charges or impositions
                      whether of an annual or recurring nature or otherwise.
                    </li>
                    <li>
                      All work and acts which are required to be done to comply
                      with any statutory provisions or the directions or notices
                      of any governmental, local or public authority.
                    </li>
                    <li>
                      Any reasonable charges which may be incurred in the
                      management and preservation of the value of the property
                      and the running of the SPV affairs or the provisions of
                      services by the Management Company, including the
                      provision of concierge and housekeeping services
                    </li>
                    <li>
                      The maintenance of the sinking fund if one is
                      established.*
                    </li>
                    <li>
                      Any other charges that are required to be paid in order to
                      maintain the status quo of Units.
                    </li>
                  </ol>
                  <li>
                    If, at the end of any given year, the Management Charges
                    paid by the Owners have generated a surplus on the
                    Management Charges account, such surplus shall be carried
                    forward to the following applicable year Similarly, if at
                    the end of any given year, the costs incurred by the Project
                    manager and related to the Management Charges have generated
                    a deficit on the Management Charges account, such deficit
                    will be taken into consideration when calculating the
                    Management Charges of the following applicable year.
                  </li>
                  <li>
                    Without prejudice to the generality of the foregoing, the
                    powers that the Project manager has delegated to the
                    Management Company include the power to collect any sums
                    payable by, and to enforce the obligations of Owners
                    pursuant to these Rules.
                  </li>
                  <li>
                    In addition to the annual Management Charge, owners of
                    Assigned SPV shall pay, and shall remain solely responsible
                    for the payment of the management charge of the associated
                    unit, directly to that unit's management company.
                  </li>
                  <li>
                    A charge of 10% shall be added to any Management Charges not
                    paid by the Due Date and thereafter interest at a rate of 2%
                    per month will be charged on all arrears.
                  </li>
                  <ol type="i">
                    <li>
                      Expect as outlined herein the annual increase in
                      Management Charges shall not exceed 10% more than a
                      weighted average of the rates of inflation. if BRIKitt
                      wishing to impose a higher.
                    </li>
                    <li>
                      increase, it shall invoice all Owners with the proposed
                      Management Charge which shall be payable by all Owners,
                      notwithstanding the following.
                    </li>
                    <li>
                      the Management Company shall appoint an independent
                      accountant or other similarly qualified individual acting
                      as an expert, to ascertain whether the proposed increase
                      is reasonable. The Management Company shall provide that
                      expert with a budget for the forthcoming year covering the
                      costs This shall be examined by that expert, whose
                      decision as to whether the proposed Management Charge is
                      reasonable, shall be final. In calculating whether the
                      proposed increase in Management Charge is reasonable (and
                      for this purpose alone), no rental income shall be
                      considered due in respect of any Units In the event of the
                      Directors resigning, upon the approval by the BRIKitt,
                      BRIKitt shall appoint another legal entity to act as
                      Management of SPV or shall act as the Management Company
                      itself. The Management of SPV shall have sole discretion
                      in deciding what monies should be spent for any of the
                      purposes set out above and when the same shall be
                      expended.
                    </li>
                  </ol>
                </ol>
                <p>
                  Any resulting underpayment or overpayment of the succeeding
                  applicable year's Management Charge shall be adjusted on that
                  year's invoice at the Management Company's sole discretion.
                </p>
                <p>
                  The Management Company shall use its best endeavors to
                  promptly collect all Management Charges and shall promptly pay
                  and discharge out of all such monies so collected, all
                  expenses in relation to the management of the units, the
                  administration of the Club and shall maintain and keep proper
                  records and books of account and make them available at all
                  reasonable times for inspection by the BRIKitt or share
                  holders.
                </p>
                <br />
                <br />
                FURTHER OBLIGATIONS OF OWNERS
                <br />
                Each Owner shall be subject to the following further
                obligations:-
                <br />
                <p>
                  (a) (i) To notify in writing to the Management Company in
                  advance of the commencement of the relevant holiday Period of
                  the name of the person(s) who will be occupying the relevant
                  Unit unless the Owner has deposited the relevant BRIK Period
                  into the system. (ii) To occupy the Unit only from the first
                  until the last day of each period of occupation allocated to
                  him in any year between the times notified by the Management
                  Company.
                </p>
                <p>
                  (b) To keep and maintain the interior of the Unit occupied in
                  a good and tenantable state and condition during the period of
                  the Owner's occupancy, and to pay or indemnify the management
                  of SPV against any damage, deterioration or dilapidation,
                  other than as a result of fair wear and tear, and damage or
                  destruction by fire or any other insured risk, which may have
                  occurred during his occupancy period, of which the Project
                  manager shall be the sole judge.
                </p>
                <p>
                  (c) In the event that any repair or maintenance work is
                  required to a Unit or its contents whilst it is occupied by a
                  Owner, to allow reasonable access on reasonable notice except
                  in the case of emergency, to enable such work to be carried
                  out.
                </p>
                <p>
                  (d) Not to make any alterations to any Unit or its contents.
                  To pay all other expenses incurred by the Owner and to pay
                  such reasonable deposit as the Management Company may demand
                  against its charges and/or breakages.
                </p>
                <p>
                  (e) To notify the Project manager and Management Company
                  forthwith of any change in his permanent address.
                </p>
                <p>
                  (f) At all times to observe these Rules and the reservation
                  conditions, both as amended from time to time.
                </p>
                <p>
                  (g) Not to do anything which would make void or which may
                  operate to increase the premium.
                </p>
                <p>
                  (h) To pay the Management Charge plus any other sums due,
                  within 30 days of the same being demanded.
                </p>
                <p>
                  (i) To pay any local taxes or utility charges when on BRIK
                  according to the prevailing conditions extant at each unit.
                </p>
                <p>
                  (j) At all times to behave with the decorum and decency of
                  character befitting a BRIK owner and not to act in such manner
                  as may bring the unit into disrepute.
                </p>
                <p>
                  (k) Not to carry on, use or permit the Unit to be used for any
                  noisy, offensive or dangerous trade, manufacture, business or
                  occupation nor for any illegal or immoral purpose nor to do or
                  suffer to be done on the Unit any acts or things which, in the
                  reasonable opinion of the Management Company, may be an
                  annoyance, nuisance, damage, disturbance or inconvenience to
                  the prejudice of the Project manager or, Management Company or
                  to the owners or occupiers of any adjoining or neighboring
                  premises or any of them nor to use or permit the Unit to be
                  used other than as a BRIK premises. In addition, not to
                  display excessively rude, aggressive or insulting behavior
                  toward the staff of the Project manager, the Management
                  Company or toward staff at the property, or toward appointed
                  customer service representatives, nor to display such similar
                  behavior to any other owners or occupiers at the projects.
                </p>
                <br />
                <br />
                SUSPENSION OF OWNERSHIP FOR REASONS OTHER THAN NON-PAYMENT OF
                MANAGEMENT CHARGES
                <br />
                <p>
                  If at the absolute discretion of the Management Company, any
                  Owner shall have committed a substantial breach of the
                  obligations imposed on him herein, then his OWNERSHIP may be
                  immediately suspended by the Management Company by giving
                  written notice to this effect to the Owner. If the Owner shall
                  at the time such notice is served be at a Resort he may be
                  required to leave forthwith. If such breach is not remedied
                  according to the requirements of the Management Company within
                  14 days of the notice first being given, the Management
                  Company shall send a further notice to the Owner advising that
                  indefinite failure to remedy such breach (as well as the
                  consequences or effects of such breach) will result in the
                  continued and similarly indefinite suspension of the Owner's
                  OWNERSHIP.
                </p>
                <br />
                <br />
                SUSPENSION OF OWNERSHIP FOR NON-PAYMENT OF MANAGEMENT CHARGES
                <br />
                <p>
                  If a Owner has not paid his annual Management Charge within 60
                  days of being invoiced in writing the Owner's OWNERSHIP may be
                  suspended by the `Management Company. The Management Company
                  shall send written notice to the Owner advising that failure
                  to discharge in full the arrears (including any interest that
                  may have been applied in accordance with these Rules) within
                  30 days will result in the continued suspension of his
                  OWNERSHIP and the indefinite loss of his Usage and usage.
                </p>
                <br />
                <br />
                TRANSFER OF Ownership
                <br />
                <ol type="a">
                  <li>
                    Subject to the provisions of the above clause, any Owner may
                    apply to dispose of his Usage to any person, whether by
                    sale, gift or otherwise, by means of the Form of Transfer
                  </li>
                  <li>
                    Assigned Usage can be transferred to a third party, as long
                    as the Assigned Exclusive Usage of Occupation they are based
                    on are transferred to the same party at the same time. It is
                    not permitted to transfer only part of the Assigned Usage
                    owned by a Owner, or to transfer Assigned Usage to various
                    transferees.
                  </li>
                  <li>
                    On the death of a Owner, his personal representative may
                    apply to dispose of his Usage in a like manner.
                  </li>
                  <li>
                    Upon delivery to the BRIKitt of the Ownership Certificate
                    evidencing the Usage to be transferred, together with a
                    completed Form of Transfer signed by the transferor and the
                    transferee, together with such fee as the BRIKitt shall from
                    time to time determine, the BRIKitt shall replace the name
                    of the transferor by that of the transferee in the Register
                    and issue a new Usage Certificate in the name of the
                    transferee. Pending such replacement and issue no effective
                    disposal as contemplated above shall be deemed to have taken
                    place.
                  </li>
                  <li>
                    From the date of the entry of the name of the transferee in
                    the Register, all obligations to the Project manager or the
                    transferor under these Rules (other than obligations of the
                    transferor accrued at the time of the disposal of the Points
                    Usage Certificate by the transferor to the transferee) shall
                    thereupon cease and the transferee shall be entitled to
                    exercise the usage of the Owner.
                  </li>
                </ol>
                <br />
                <br />
                OBLIGATIONS OF THE PROJECT MANAGER AND BRIKITT
                <br />
                <p>
                  The Project manager and BRIKitt shall observe and perform all
                  their obligations set out herein and in the Deed of Trust from
                  the date hereof until the Termination Date. The Promoter,
                  BRIKitt and all other parties with access to the Register
                  shall keep the contents thereof confidential unless obliged to
                  disclose same by law or, in the opinion of the BRIKitt, duly
                  authorized government body.
                </p>
                <br />
                <br />
                OWNERS GUESTS
                <br />
                <ol type="a">
                  <li>
                    A Owner may without effecting a transfer within the meaning
                    of the above clause, permit other persons to take advantage
                    of Exclusive Usage of Occupation allocated to that Owner to
                    which the Points Usage Certificate relates, provided that,
                    if he wishes to do so he shall give the Management Company
                    not less than 14 days notice in writing, prior to the first
                    date of the proposed occupation, which shall include the
                    name and address of such person and shall pay the charge
                    set, from time to time, by the Management Company, for the
                    provision of that service.
                  </li>
                  <li>
                    Notwithstanding any such permission as aforesaid, the holder
                    of the points Usage Certificate shall remain liable under
                    the provisions of these Rules and shall, inter alia, be
                    responsible for the payment of the Management Charges and
                    for the behavior of the person or persons occupying the
                    Holiday Unit and for any damages caused by such person or
                    persons.
                  </li>
                </ol>
                <br />
                <br />
                LIMITATION ON USAGE GRANTED TO OWNERS
                <br />
                <p>
                  No Owner shall be considered to be acquiring a property right
                  on entering into a Purchase Agreement. Any clause or
                  sub-clause of these Rules shall be null and void in respect of
                  any particular Purchase Agreement if the application of that
                  clause or sub-clause would bring any party to this project
                  Documentation or the Purchase Agreement itself within the
                  scope of any legislation relating to investment or to property
                  usage in the country in which that Purchase Agreement was
                  entered into Notwithstanding the fact that any clause or
                  sub-clause of these Rules may have become null and void as a
                  result of the application of this sub-clause, all other
                  clauses or sub-clauses of these Rules shall remain valid and
                  enforceable in their entirety.
                </p>
                <br />
                <br />
                ALL RIGHTS RESERVED
                <br />
                <p>
                  DISCLAIMER: Information in this document is subject to change
                  without notice. No part of this document may be reproduced or
                  transmit- ted in any form or by means, electronic or
                  mechanical, for any purpose without the express written
                  permission of BRIKitt. BRIKitt may have pending trademarks,
                  copyrights or other intellectual property covering subject
                  matter in this document. The furnishing of this document does
                  not give you license to these trademarks, copyrights or other
                  intellectual property except as expressly provided in any
                  written license agreement from BRIKitt.
                </p>
                <br />
                <br />
                Customer Declaration:
                <br />
                <p>
                  I/We hereby confirm having read the Terms & Conditions and the
                  Confirmation of Understanding mentioned above and affix my/our
                  Signature as acceptance of the same. I hereby certified that
                  the details furnished above are accurate and true to the best
                  of my knowledge
                </p>
              </Box>
              <Box style={{ textAlign: "center", padding: "5px" }}>
                <Checkbox
                  onSelect={() => {
                    setSelected(true);
                  }}
                ></Checkbox>
                I agree with the{" "}
                <span style={{ color: "#4A8EAB" }}>Terms and Conditions</span>
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    // if(selected){
                    if (auth.getUser().document_id === null) {
                      updateDocumentData({}).then((result) => {
                        setOpen(true);
                        let user = JSON.parse(localStorage.getItem("user"));

                        user = { ...user, ...result };
                        localStorage.setItem("user", JSON.stringify(user));
                      });
                    } else {
                      setDialogText(initialText);
                      setOpen(true);
                    }
                    // }
                  }}
                  color="secondary"
                  variant="contained"
                  fullWidth
                  disabled={isComponentBusy}
                >
                  <Avatar src={Adhaar} />
                  &nbsp;&nbsp;&nbsp; Adhaar E-sign
                </Button>
              </Box>
              {/* <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle><Box padding={1} justifyContent="center" alignItems="center" display="flex"><Avatar style={{borderRadius:"0",alignItems:"center"}} src={Check}/></Box></DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    {dialogText}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  ><Grid>
                    <Button color="secondary" variant="outlined" onClick={()=>{
                      setOpen(false);
                      // console.log("on click of ok-->",auth.getUser().document_id);
                      if(auth.getUser().document_id==null && auth.getUser().terms_accepted==false){
                        let fd=new FormData()
                        fd.append('document_id',"12345")
                        updateDocumentData(fd)
                      }
                      else
                      {
                        let fd=new FormData()
                        fd.append('document_id',auth.getUser().document_id)
                        updateDocumentData(fd).then(result=>{
                          console.log("document id-->",result.document_id);
                          let user=JSON.parse(localStorage.getItem('user'))
                          user={...user,...result}
                          localStorage.setItem("user", JSON.stringify(user))
                          if(result.terms_accepted)
                            history.push('/')
                          else{
                            setDialogText(resendText);
                            setOpen(true);
                         }
                        })
                      }
                    }}>OK</Button>
                    </Grid>
                    <Grid>
                    <Link onClick={()=>{
                    if(auth.getUser().document_id===null){
                      updateDocumentData({}).then(result=>{
                        setOpen(true)
                        let user=JSON.parse(localStorage.getItem('user'))
                        
                        user={...user,...result}
                        localStorage.setItem("user", JSON.stringify(user))
                      })
                  }} color="secondary" style={{ cursor: "pointer", fontSize: 14 }}>
                    Resend E-mail
                  </Link>
                  </Grid>
                  </Grid>
                  </DialogActions>
                </Dialog> */}
              <Box style={{ textAlign: "center", padding: "5px" }}>
                {/* <Link href='/' color="secondary" style={{ cursor: "pointer", fontSize: 14 }}>
                    Download Brochure
                  </Link> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.app} container spacing={2}>
        <Grid
          item
          sm={3}
          xs={12}
          style={{
            overflow: "hidden",
            height: "80vh",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box>
            <Box style={{ color: "#1A4F5D", fontWeight: 600, fontSize: 20 }}>
              Hi Welcome !
            </Box>
            <Box style={{ padding: "10px 0", color: "#5C7B83" }}>
              Explore your Brik Ownership & Enjoy the managment experience
            </Box>
          </Box>
          <Box style={{ fontWeight: 600, fontSize: 20, padding: "15px 0" }}>
            Terms and Conditions
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            padding={1}
            height="auto"
            overflow="scroll"
          >
            <b>BRIK Owner's Review for confirmation of Understanding</b>
            <br />
            Dear BRIK Owner(s),
            <br />
            <br />
            Here are a few key points about your BRIK Ownership that you should
            be clear about. Please read each point below in conjunction with the
            detailed Terms & Conditions for complete understanding.
            <br />
            <br />
            1. I/We understand the Terms and Conditions of the special offer
            that I/we have chosen at the time of signing up for purchase of
            BRIK.
            <br />
            <br />
            2. I/We confirm having read the Terms and Conditions governing
            purchase of BRIK Ownership Usage and the Terms and Conditions with
            regard to BRIK ownership and agree to abide by the same. All
            necessary clarifications and information on the BRIK Ownership have
            been provided to us.
            <br />
            <br />
            3. I/We understand that any payment made by me/us would first be
            appropriated toward the Booking Fees and balance if any would be
            appropriated towards the entitlement fees.
            <br />
            <br />
            4. I/We understand that in the event of request for cancellation by
            me/us the BRIKitt shall have the right to deduct cancellation
            charges as mentioned in the Terms and Conditions and would refund
            the balance amount only.
            <br />
            <br />
            5. I/We understand that I/we shall be admitted as BRIK Owner of BRIK
            Ownership only upon realization of the entire amount and EMI's as
            per the scheme opted by me, to be entitled to the benefits of BRIK
            Ownership .<br />
            <br />
            6. I/We understand that the number of people who can occupy the
            apartment is as follows:
            <ul>
              <li>2 BHK Apartment - 6 Adults</li>
              <li>1 BHK Apartment – 4 Adults</li>
              <li>Studio Apartment - 3 Adults</li>
              (Children above 12 years shall be considered as adult for the
              purpose of occupancy. 2 children below 12 years to be considered
              as one adult).
            </ul>
            7. I/We understand that the gifting of the BRIK is my/our
            responsibility and in this regard the BRIKitt does not undertake any
            responsibility or liability.
            <br />
            <br />
            8. I/We understand that I/we can make my/our own arrangements to
            sell my/our BRIK and Ownership rights & that the BRIKitt shall not
            buy it back from me/us.
            <br />
            <br />
            9. I/We understand that the confirmation for reservation of regular
            unit is subject to availability and eligibility. I/We are aware that
            without the Confirmation of booking issued by the BRIKitt by
            mail/hard copy, else I/we will not have a right of entry into any
            property.
            <br />
            <br />
            10. I/We understand that the BRIKitt needs to maintain the property
            towards which I/we confirm having agreed to pay Annual management
            Fees (AMF) whether I/we avail of BRIK in a particular year or not.
            Non-payment of the same shall result in my/our disentitlement from
            the use of the entitlements and BRIKitt shall have the right to
            cancel the usage rights if I/we do not pay the AMF
            <br />
            <br />
            11. I/We understand that the Promotional Offer mentioned in the
            application form & signed by me/us will be final.
            <br />
            <br />
            12. I /We understand that any part payment made by me towards any
            outstanding shall first be appropriated towards interest and then
            earlier dues /outstanding.
            <br />
            <br />
            13. In case of default in payment of any installment by me/us, the
            BRIKitt shall have the right to terminate my/our BRIK Ownership for
            occupancy and that the Terms and Conditions mentioned in BRIK
            Ownership agreement shall apply.
            <br />
            <br />
            14. I/We have read the rules of cancellation, and agree to the same.
            <br />
            <br />
            15. I/We confirm that there are no other verbal/written promises or
            any other assurances not mentioned in the BRIK OWNERSHIP that have
            been made by any BRIKitt personnel.
            <br />
            <br />
            <br />
            <b>Payment Consent</b>
            <ul>
              <li>
                The amount paid for booking shall be deemed refundable if
                BRIKitt fails to deliver the property or is unable to gather the
                rest co owners or cancels the booking from its end to the BRIK
                owner.
              </li>
              <li>
                The BRIKitt doesn't accept payments in Cash. All payments to the
                BRIKitt is to be made form of cheque/draft favoring "BRIKitt",
                or through Credit Cards. Any payment in cash and cheque/draft
                not favoring the BRIKitt is at the BRIK Owner's risk and the
                BRIKitt will not be liable for the same.
              </li>
              <li>
                BRIKitt is liable to return the booking amount with an interest
                of 11 % pa if all the co-owners are not brought on board within
                180 to 365 days depending upon the property type as specified at
                the time of booking.
              </li>
              <li>
                If the BRIK owner cancels the booking from its consent the
                BRIKitt holds the right to forfeit the payment and the amount
                paid deems Non-re fundable. I/We hear by agree that if under any
                circumstances booking of BRIK is cancelled by Owner the booking
                amount deemed.
              </li>
            </ul>
            <br />
            <b>METHODS OF PAYMENTS AND REFUNDS</b>
            <ul>
              <li>
                You should quote your booking details on all bank transfers and
                cheques made payable to us to avoid error and undue delay.
              </li>
              <li>
                You are responsible for paying your own bank's charges in
                respect of all payments made to and refunds received from
                BRIKitt except in cases where BRIKitt is found to be at fault.
                We will refund any payments made by you to us in the same manner
                and currency as the original payment was made.
              </li>
            </ul>
            <br />
            <b>YOUR APPLICATION FOR OWNERSHIP</b>
            To become a Owner, you must:
            <br />
            <ol>
              <li>You must be at least 18 years of age;</li>
              <li>
                Complete and submit an enrolment application to BRIKitt in the
                prescribed form (such application should be submitted either by
                yourself personally or on your behalf);
              </li>
              <li>Pay the application fee to BRIKitt.</li>
              <li>Have your application accepted by BRIKitt.</li>
              <li>
                We reserve the right to refuse any enrollment application,
                including without limitation if required to do so by the laws,
                rules or regulations of any local, state, national or federal
                governmental entity or by any judicial, public, regulatory or
                law enforcement authority or court.
              </li>
              <li>
                Your BRIK ownership subject to these terms takes effect from the
                date we write to notify you through mail.
              </li>
              <li>
                If a company, partnership, trust, unincorporated association or
                other entity wishes to owns BRIK Ownership, ownership must be in
                the name of a natural person nominated by the company or other
                entity to represent it.
              </li>
              <li>
                Up to 2 co-owners of BRIK Ownership who live at the same address
                may apply for one ownership. You must nominate a lead owner to
                act as our principal contact for matters relating to your
                ownership.
              </li>
              <li>
                BRIKitt shall be permitted to take instructions from and
                disclose information about your ownership to either co-owner.
              </li>
              <li>
                If we are given conflicting instructions from co-owners, we are
                entitled to take the first set of instructions received and act
                on those instructions. If we continue to receive conflicting
                instructions received and act on those instructions. If we
                continue to receive conflicting instructions from co-owners, we
                may at our reasonable discretion suspend or cancel (with an
                appropriate refund) exchange privileges relating to your BRIK
                Ownership unless such instructions can be promptly reconciled or
                resolved.
              </li>
              <li>
                Co-owners of BRIK Ownership residing at different addresses must
                apply for separate BRIK.
              </li>
            </ol>
            <br />
            <br />
            <b>BRIK GENERAL CONDITIONS</b>
            <ol type="a">
              <li>
                A sole applicant shall purchase BRIK in his/her name, in case of
                two applicants the application will be treated as a former or
                Survivor basis application. More than two persons shall not be
                entitled for applying on any account. Besides, for such joint
                application the applicants should be from the same family
                consisting of father, mother, spouse and lineal of spring.
              </li>
              <li>
                All communication to/from the Company shall be to the first
                applicant.
              </li>
              <li>
                The Company does not undertake to pay rent, buy back or sell or
                facilitate the exchange of the BRIK of a Customer without
                his/her consent.
              </li>
              <li>
                The Company reserves its right to market the Apartments or any
                portion thereof in respect of any day to free individual
                traveler.
              </li>
              <li>
                If the Customer, does not check-into the property, the BRIK
                shall be mentioned from the dashboard as occupied, and the
                company shall neither be liable to provide any other
                accommodation in any property and BRIKitt will not bear any
                compensation, claim or damages.
              </li>
              <li>
                In the event of any delayed check-in, it shall be responsibility
                of the occupant/Owner to inform the concerned Manager on site.
                In the absence of any such intimation the Company reserves the
                right to release the booking without paying any compensation of
                nights or rent. Herein above, in the event of the any partial
                utilization of the unit due to delayed check-in/early departure
                or otherwise, the number of days debited shall be as per the
                original booking.
              </li>
              <li>
                The Customer shall promptly inform the change of address to the
                company in writing supported with a valid KYC (know your
                Customer) document.
              </li>
              <li>
                The Company or its subsidiaries has rights to promote various
                holiday homes. The BRIK customers shall not have free right to
                claim or access to such resorts promoted by the Company.
              </li>
              <li>
                The purchase of BRIK shall be construed as purchase of
                Equity/Preference Shares in the SPV (Special Purpose Vehicle)
                and ownership right or leasehold right, over the property of the
                SPV; The purchase of BRIK also allows the Customer to get
                accommodation in the unit for stay as per the t erms and
                conditions and does give him/her/it any rights or interest of
                whatsoever nature over the SPV or its assets and properties. In
                addition to the above and not withstanding anything contained
                anywhere in this set of terms and Conditions, it is hereby fully
                clarified and the Customer fully understands and confirms that.
              </li>
              <li>
                The Company shall not be liable to the Customer if it is not in
                a position to fulfill obligations under BRIK, provided the same
                is due to any war/civil commotion, any force majeure reasons,
                Act of God or any other notification/order/decree from any
                government/Local Body/Court of Law of for any reason which is
                beyond the reasonable control of the Company.
              </li>
              <li>
                Any promises and commitments, made either in writing or in words
                by any person representing the Company, outside the purview of
                or in contradiction to these terms and conditions are not
                binding on the Company.
              </li>
              <li>
                If any provision or provisions of these terms shall be held to
                be invalid, illegal, unenforceable, or in conflict with the law
                of any jurisdiction, the validity, legality, and enforceability
                of the remaining provisions shall not be in any way affected or
                impaired thereby.
              </li>
              <li>
                The Company doesn't accept payments in Cash. All payments to the
                Company is to be made form of cheque/draft favoring "BRIKitt",
                or through Credit Cards. Any payment in cash and cheque/draft
                not favoring the company is at the Customer's risk and the
                Company will not be liable for the same.
              </li>
              <li>
                The Customer is aware that in order to help him make the best
                use of product and case of transactions, the Company needs to
                communicate the updates related to the customer like payments
                transaction, vacation offers, promotions & other ownership
                related information and hence irrevocably empowers the Company
                to communicate with him/her vide any mode of communication
                during the entire tenure of his membership even if the customer
                has registered himself/herself under the TRAI rules. In the
                event of change in contact details, it's the customers’
                responsibility to communicate the same and ensure communication
                does not continue to go to old contact details.
              </li>
              <li>
                In case of violation of any of the house rules by the Customer
                or his/her guest, at any of the unit or any other act of the
                Customer whereby the Company's reputation/image is likely to be
                tarnished by such behavior the Company reserves the right to
                cancel the usage rights BRIK of the Customer.
              </li>
              <li>
                All dispute(s), difference(s) or question(s) arising out of this
                transaction or otherwise shall be resorted to Arbitration as per
                the provisions of the Arbitration and Conciliation Act 1996,
                with such modifications/amendments thereto and venue of such
                arbitration shall be Lucknow only. The Sole Arbitrator shall be
                nominated by the board of Directors of the Company. The language
                used in Arbitration proceedings shall be English/Hindi only. The
                Parties agree to be bound by the award passed by the Arbitrator.
              </li>
              <li>
                In respect of all matters between the parties hereto or
                his/her/heir(s)/representative(s) only the Civil Courts in
                Lucknow City alone shall have exclusive jurisdiction and to the
                exclusion of all other Courts.
              </li>
              <li>
                The Company reserves the right to modify/amend/after any of the
                terms and conditions contained herein and/or impose additional
                conditions at its sole discretion. Such clauses will be duly
                notified in the website of the Company. In addition, the
                Customer is subject to the prevailing rules & regulations of the
                unit concerned during the period of his/her stay.
              </li>
              <li>
                The Customer's BRIK shall automatically cancel. if Customer
                being an individual is declared insolvent or bankrupt and in
                case of a corporate entity or juristic person when it completes
                liquidation of disbandment process or otherwise no longer
                exists, even if (where possible) it is later restored by an
                official or court order or decision.
              </li>
            </ol>
            <br />
            <br />
            <b>BRIKitt's OBLIGATIONS</b>
            <br />
            <p>
              In case Company does not provide vacation after issuance of
              confirmation voucher for the unit, Company shall provide alternate
              accommodation and in the event of default in providing alternate
              accommodation
            </p>
            <p>
              In all cases where company provided alternate accommodation or
              pays liquidated damages, the number of nights confirmed by Company
              shall be debited to the Customer's account.
            </p>
            <p>
              Notwithstanding anything stated here in above, Company shall not
              incur any liability to the Customer if it is not in a position to
              fulfil its obligations by reason of any war, civil commotion,
              force majeure, act of God, any notification from any Court of Law
              or Government or any due other reason beyond its control.
            </p>
            <p>
              BRIKitt will not dilute the SPV or Property before a tenure of 7
              years from the date of possession of unit to SPV.
            </p>
            <br />
            <b>LIMITATION OF LIABILITY</b>
            <br />
            <br />
            <p>
              Company makes no warranty, explicitly or implicitly, without
              Limitation with respect to the availability, quality or
              suitability of the accommodation facility provided in any unit
              including all amenities thereon and expressly disclaims the
              warranties or conditions of merchantability and fitness for any
              particular purpose. Besides, under any circumstances, the company
              shall not be liable for any special, indirect, incidental, or
              consequential damages of any kind whatsoever (including, without
              limitation attorneys' fees) in any way due to resulting from, or
              arising in connection with the BRIK or the unit or the failure of
              company to perform its obligations or for any alleged deficiency
              of service, regardless of any negligence. Except as otherwise
              provided, the accommodation and amenities in the unit are provided
              on an "as is", "as available" basis and the Company disclaims all
              warranties.
            </p>
            <br />
            <br />
            <b>REQUESTING AN EXCHANGE</b>
            <br />
            <ol type="a">
              <li>
                12.1 You must upon receipt of intimation by us in this regard
                (if any)) pay in advance the exchange fee current on the date of
                request for each exchange requested.
              </li>
              <li>
                12.2 If BRIKITT is unable to confirm an exchange acceptable to
                you we will at your request either hold the exchange fee as a
                credit to your account against future exchange fees or refund it
                to you.
              </li>
              <li>
                12.4 Our ability to confirm an exchange request is dependent on
                the availability of Holiday Ownership rights deposited or
                forecast to be deposited by other owners in the BRIKitt Pool
                which are acceptable to you. We therefore cannot guarantee that
                any particular request for a resort, area, travel date, type or
                size of accommodation, travel supplier or otherwise relating to
                your exchange will be met. We will, though, offer you
                alternative choices which may be available.
              </li>
              <li>
                12.5 A legally binding contract Is formed when the exchange
                reservation is made.
              </li>
              <li>
                12.6 Confirmation of exchange will be valid only when issued to
                you in writing by or email from BRIKitt. You should check all
                the details on your confirmation carefully when you receive it
                and let us know as soon as possible if anything is incorrect.
                Subsequent changes made by you to any material aspect of your
                confirmation may be treated as a cancellation.
              </li>
              <li>
                Shareholders will not have any rights to eliminate Directors of
                SPV, until BRIKitt is out of business. In AGM shareholders can
                keep a resolution for removal of directors, but it is on
                BRIKitt's management discretion for approval.
              </li>
              <li>
                13.1 owners accept that accommodation may vary in unit Sizes,
                design, fixtures. Furnishings, amenities and facilities from
                their own BRIK Ownership.
              </li>
              <li>
                13.2 Management may, where necessary, allocate alternative
                accommodation to the unit confirmed provided it has all at least
                the same maximum occupancy and is of similar overall quality.
              </li>
              <li>
                13.3 Members and/or Guests must occupy and use any accommodation
                into which they have exchanged in a responsible, careful and
                sole manner.
              </li>
              <li>
                13.4 Members are responsible for any damage caused by them
                and/or by their Guests whilst staying at an Property.
              </li>
              <li>
                13.5 You and/or your Guests must comply with the in house rules
                and bye-laws.
              </li>
              <li>
                13.6 You and/or your Guests must replace any items missing from
                your unit on departure or Management may charge you and/or your
                Guests for replacement of damages/loses
              </li>
              <li>
                13.7 The total number of people (babies and children included)
                occupying the accommodation must not exceed the maximum
                occupancy of the unit set out in the exchange confirmation or
                guests certificate otherwise the Manager may refuse access.
              </li>
              <li>
                13.8 Members are responsible for payment of any applicable
                taxes, personal expenses, utility charges, security deposits and
                other fees or charges levied by management on occupiers for the
                use of amenities and facilities there.
              </li>
              <li>
                13.9 You must comply with check-in and check-out times. If you
                and/or your Guests are going to arrive outside the check-in day
                and/or time set out in your confirmation, the management must be
                contacted directly to make alternative check-in arrangements,
                subject to availability. Management may levy a charge or impose
                other conditions for any accommodation outside the days
                specified on the confirmation.
              </li>
              <li>
                13.10 Members and their Guests may be refused entry to Property
                if they cannot verify their identity by means of a valid
                passport, driving, license or other form of Photo
                identification.
              </li>
            </ol>
            <br />
            <br />
            <b>COMMUNICATING WITH YOU</b>
            <br />
            <p>
              BRIKitt is the sole controller of all date held in relation to
              owners and their Guests and all processing of data relating to
              your ownership and to your or your Guests' use of the exchange or
              other accommodation or of related travel services offered by
              BRIKitt is therefore subject to India's data protection laws only.
            </p>
            <p>
              We will maintain records relating to your ownership and to your
              and your Guests' use of exchange or other accommodation or of
              related travel services offered by BRIKitt (including Information
              about you or your Guests provided by yourself, your Guests or by
              third parties).We shall be entitled to process such data for the
              purpose of providing you or your Guests with the products and
              services requested; to keep you or your Guests Informed of further
              offers from us which may be of interest to you or your Guests
              (unless you or your Guests write to us asking us to exclude you or
              them from such offers or alternatively telephone, fax or email us
              to that effect) and for our own administration, market analyses
              and operational reviews.
            </p>
            <p>
              You agree that BRIKitt, or any associated companies or third
              parties authorized by BRIKitt, shall be entitled to make contact
              with you or your Guests by post, e-mail, telephone, including
              automated dialing equipment, facsimile transmissions, and/or
              pre-recorded messages for the purposes set out In this paragraph.
              We shall be entitled to disclose to any associated companies and
              third parties (including but not necessarily limbed to resorts,
              owners' associations, resort trustees, management companies,
              airlines, insurance companies, car hire companies, ferry and
              cruise operators and other suppliers) such information as may be
              necessary to:
            </p>
            <p>
              provide you or your Guests with products and services you or they
              have requested: or
            </p>
            <p>
              verity details relating to your ownership or your BRIK Ownership
              rights or your Guests' use of exchange
            </p>
            <p>
              or other accommodation: or notify you of travel or other services
              or products which we believe may be of interest.
            </p>
            <p>
              We shall be entitled to rely on your Guests' consent to receive
              information regarding such products and services for a period of
              up to five years after their occupation.
            </p>
            <p>
              We shall be entitled to process such data for the purpose
              providing you or your Guests with the products and services
              requested; to keep you or your Guests Informed of further offers
              from us which may be of interest to you or your Guests (unless you
              or your Guests write to us asking us to exclude you or them from
              such offers or alternatively telephone, fax or email us to that
              effect) and for our own administration, market Analyses and
              operational reviews.
            </p>
            <p>
              You agree that BRIKitt, or any associated companies or third
              parties authorized by BRIKitt, shall be entitled to make contact
              with you or your Guests by post, e-mail, telephone, including
              automated dialing equipment, facsimile transmissions, and/or
              pre-recorded messages for the purposes set out In this paragraph.
              We shall be entitled to disclose to any associated companies and
              third parties (including but not necessarily limbed to resorts,
              owners' associations, resort trustees, management companies,
              airlines, insurance companies, car hire companies, ferry and
              cruise operators and other suppliers) such information as may be
              necessary to: Provide you or your Guests with products and
              services you or they have requested: or verity details relating to
              your ownership or your BRIK Ownership rights or your Guests' use
              of exchange or other accommodation: or notify you of travel or
              other services or products which we believe may be of interest. We
              shall be entitled to rely on your Guests' consent to receive
              information regarding such products and services for a period of
              up to seven years after their occupation.
            </p>
            <br />
            <br />
            <b>OTHER SERVICES</b>
            <br />
            <p>
              These Terms of ownership do not apply to any services made
              available by BRIKitt other than exchanges.
            </p>
            <p>
              Without limitation, BRIKitt does not provide flights, coach or
              train travel, car hire, insurance, ferries, cruises and tours.
              Such services may be purchased from independent third party
              suppliers and will be subject to the terms and conditions of the
              suppliers concerned.
            </p>
            <p>
              BRIKitt reserves the right to very, withdraw or add to the
              services it provides at anytime, with or without notice.
            </p>
            <br />
            <br />
            MANAGEMENT & MANAGEMENT CHARGES
            <br />
            <ol type="a">
              <li>
                Notwithstanding the fact that the BRIKitt has appointed the
                Management SPV to administer the expenses, management and
                booking of Units.
              </li>
              <li>
                Owners shall contribute in the form of an annual Management
                Charge, to all reasonable costs assessed on the SPV including,
                without prejudice to the generally of the foregoing, the
                following:-
              </li>
              <ol type="i">
                <li>
                  Maintenance, repair, redecoration, cleaning, and (where
                  necessary) renewal of the Units, services and facilities
                  provided by the Manage- ment Company for the benefit of the
                  Owners whether exclusive or in common with others entitled.
                </li>
                <li>
                  Maintenance, repair and (when necessary) replacement of
                  furniture, equipment, utensils, provisions, furnishings,
                  fittings and fixtures in or about the Units and provided at
                  any other unit in any project.
                </li>
                <li>
                  The full amount of the rent payable by the Management Company
                  in respect of Units and Alternative Holiday Units in order to
                  facilitate maintenance, repair or reconstruction works, such
                  rent to be calculated at the then current rates.
                </li>
                <li>
                  All outgoing incurred in respect of the Units, including
                  electricity, gas, water, rates, licenses, contributions to the
                  community of property owners to which the Units may belong and
                  any taxes or other charges or impositions whether of an annual
                  or recurring nature or otherwise.
                </li>
                <li>
                  All work and acts which are required to be done to comply with
                  any statutory provisions or the directions or notices of any
                  governmental, local or public authority.
                </li>
                <li>
                  Any reasonable charges which may be incurred in the management
                  and preservation of the value of the property and the running
                  of the SPV affairs or the provisions of services by the
                  Management Company, including the provision of concierge and
                  housekeeping services
                </li>
                <li>
                  The maintenance of the sinking fund if one is established.*
                </li>
                <li>
                  Any other charges that are required to be paid in order to
                  maintain the status quo of Units.
                </li>
              </ol>
              <li>
                If, at the end of any given year, the Management Charges paid by
                the Owners have generated a surplus on the Management Charges
                account, such surplus shall be carried forward to the following
                applicable year Similarly, if at the end of any given year, the
                costs incurred by the Project manager and related to the
                Management Charges have generated a deficit on the Management
                Charges account, such deficit will be taken into consideration
                when calculating the Management Charges of the following
                applicable year.
              </li>
              <li>
                Without prejudice to the generality of the foregoing, the powers
                that the Project manager has delegated to the Management Company
                include the power to collect any sums payable by, and to enforce
                the obligations of Owners pursuant to these Rules.
              </li>
              <li>
                In addition to the annual Management Charge, owners of Assigned
                SPV shall pay, and shall remain solely responsible for the
                payment of the management charge of the associated unit,
                directly to that unit's management company.
              </li>
              <li>
                A charge of 10% shall be added to any Management Charges not
                paid by the Due Date and thereafter interest at a rate of 2% per
                month will be charged on all arrears.
              </li>
              <ol type="i">
                <li>
                  Expect as outlined herein the annual increase in Management
                  Charges shall not exceed 10% more than a weighted average of
                  the rates of inflation. if BRIKitt wishing to impose a higher.
                </li>
                <li>
                  increase, it shall invoice all Owners with the proposed
                  Management Charge which shall be payable by all Owners,
                  notwithstanding the following.
                </li>
                <li>
                  the Management Company shall appoint an independent accountant
                  or other similarly qualified individual acting as an expert,
                  to ascertain whether the proposed increase is reasonable. The
                  Management Company shall provide that expert with a budget for
                  the forthcoming year covering the costs This shall be examined
                  by that expert, whose decision as to whether the proposed
                  Management Charge is reasonable, shall be final. In
                  calculating whether the proposed increase in Management Charge
                  is reasonable (and for this purpose alone), no rental income
                  shall be considered due in respect of any Units In the event
                  of the Directors resigning, upon the approval by the BRIKitt,
                  BRIKitt shall appoint another legal entity to act as
                  Management of SPV or shall act as the Management Company
                  itself. The Management of SPV shall have sole discretion in
                  deciding what monies should be spent for any of the purposes
                  set out above and when the same shall be expended.
                </li>
              </ol>
            </ol>
            <p>
              Any resulting underpayment or overpayment of the succeeding
              applicable year's Management Charge shall be adjusted on that
              year's invoice at the Management Company's sole discretion.
            </p>
            <p>
              The Management Company shall use its best endeavors to promptly
              collect all Management Charges and shall promptly pay and
              discharge out of all such monies so collected, all expenses in
              relation to the management of the units, the administration of the
              Club and shall maintain and keep proper records and books of
              account and make them available at all reasonable times for
              inspection by the BRIKitt or share holders.
            </p>
            <br />
            <br />
            FURTHER OBLIGATIONS OF OWNERS
            <br />
            Each Owner shall be subject to the following further obligations:-
            <br />
            <p>
              (a) (i) To notify in writing to the Management Company in advance
              of the commencement of the relevant holiday Period of the name of
              the person(s) who will be occupying the relevant Unit unless the
              Owner has deposited the relevant BRIK Period into the system. (ii)
              To occupy the Unit only from the first until the last day of each
              period of occupation allocated to him in any year between the
              times notified by the Management Company.
            </p>
            <p>
              (b) To keep and maintain the interior of the Unit occupied in a
              good and tenantable state and condition during the period of the
              Owner's occupancy, and to pay or indemnify the management of SPV
              against any damage, deterioration or dilapidation, other than as a
              result of fair wear and tear, and damage or destruction by fire or
              any other insured risk, which may have occurred during his
              occupancy period, of which the Project manager shall be the sole
              judge.
            </p>
            <p>
              (c) In the event that any repair or maintenance work is required
              to a Unit or its contents whilst it is occupied by a Owner, to
              allow reasonable access on reasonable notice except in the case of
              emergency, to enable such work to be carried out.
            </p>
            <p>
              (d) Not to make any alterations to any Unit or its contents. To
              pay all other expenses incurred by the Owner and to pay such
              reasonable deposit as the Management Company may demand against
              its charges and/or breakages.
            </p>
            <p>
              (e) To notify the Project manager and Management Company forthwith
              of any change in his permanent address.
            </p>
            <p>
              (f) At all times to observe these Rules and the reservation
              conditions, both as amended from time to time.
            </p>
            <p>
              (g) Not to do anything which would make void or which may operate
              to increase the premium.
            </p>
            <p>
              (h) To pay the Management Charge plus any other sums due, within
              30 days of the same being demanded.
            </p>
            <p>
              (i) To pay any local taxes or utility charges when on BRIK
              according to the prevailing conditions extant at each unit.
            </p>
            <p>
              (j) At all times to behave with the decorum and decency of
              character befitting a BRIK owner and not to act in such manner as
              may bring the unit into disrepute.
            </p>
            <p>
              (k) Not to carry on, use or permit the Unit to be used for any
              noisy, offensive or dangerous trade, manufacture, business or
              occupation nor for any illegal or immoral purpose nor to do or
              suffer to be done on the Unit any acts or things which, in the
              reasonable opinion of the Management Company, may be an annoyance,
              nuisance, damage, disturbance or inconvenience to the prejudice of
              the Project manager or, Management Company or to the owners or
              occupiers of any adjoining or neighboring premises or any of them
              nor to use or permit the Unit to be used other than as a BRIK
              premises. In addition, not to display excessively rude, aggressive
              or insulting behavior toward the staff of the Project manager, the
              Management Company or toward staff at the property, or toward
              appointed customer service representatives, nor to display such
              similar behavior to any other owners or occupiers at the projects.
            </p>
            <br />
            <br />
            SUSPENSION OF OWNERSHIP FOR REASONS OTHER THAN NON-PAYMENT OF
            MANAGEMENT CHARGES
            <br />
            <p>
              If at the absolute discretion of the Management Company, any Owner
              shall have committed a substantial breach of the obligations
              imposed on him herein, then his OWNERSHIP may be immediately
              suspended by the Management Company by giving written notice to
              this effect to the Owner. If the Owner shall at the time such
              notice is served be at a Resort he may be required to leave
              forthwith. If such breach is not remedied according to the
              requirements of the Management Company within 14 days of the
              notice first being given, the Management Company shall send a
              further notice to the Owner advising that indefinite failure to
              remedy such breach (as well as the consequences or effects of such
              breach) will result in the continued and similarly indefinite
              suspension of the Owner's OWNERSHIP.
            </p>
            <br />
            <br />
            SUSPENSION OF OWNERSHIP FOR NON-PAYMENT OF MANAGEMENT CHARGES
            <br />
            <p>
              If a Owner has not paid his annual Management Charge within 60
              days of being invoiced in writing the Owner's OWNERSHIP may be
              suspended by the `Management Company. The Management Company shall
              send written notice to the Owner advising that failure to
              discharge in full the arrears (including any interest that may
              have been applied in accordance with these Rules) within 30 days
              will result in the continued suspension of his OWNERSHIP and the
              indefinite loss of his Usage and usage.
            </p>
            <br />
            <br />
            TRANSFER OF Ownership
            <br />
            <ol type="a">
              <li>
                Subject to the provisions of the above clause, any Owner may
                apply to dispose of his Usage to any person, whether by sale,
                gift or otherwise, by means of the Form of Transfer
              </li>
              <li>
                Assigned Usage can be transferred to a third party, as long as
                the Assigned Exclusive Usage of Occupation they are based on are
                transferred to the same party at the same time. It is not
                permitted to transfer only part of the Assigned Usage owned by a
                Owner, or to transfer Assigned Usage to various transferees.
              </li>
              <li>
                On the death of a Owner, his personal representative may apply
                to dispose of his Usage in a like manner.
              </li>
              <li>
                Upon delivery to the BRIKitt of the Ownership Certificate
                evidencing the Usage to be transferred, together with a
                completed Form of Transfer signed by the transferor and the
                transferee, together with such fee as the BRIKitt shall from
                time to time determine, the BRIKitt shall replace the name of
                the transferor by that of the transferee in the Register and
                issue a new Usage Certificate in the name of the transferee.
                Pending such replacement and issue no effective disposal as
                contemplated above shall be deemed to have taken place.
              </li>
              <li>
                From the date of the entry of the name of the transferee in the
                Register, all obligations to the Project manager or the
                transferor under these Rules (other than obligations of the
                transferor accrued at the time of the disposal of the Points
                Usage Certificate by the transferor to the transferee) shall
                thereupon cease and the transferee shall be entitled to exercise
                the usage of the Owner.
              </li>
            </ol>
            <br />
            <br />
            OBLIGATIONS OF THE PROJECT MANAGER AND BRIKITT
            <br />
            <p>
              The Project manager and BRIKitt shall observe and perform all
              their obligations set out herein and in the Deed of Trust from the
              date hereof until the Termination Date. The Promoter, BRIKitt and
              all other parties with access to the Register shall keep the
              contents thereof confidential unless obliged to disclose same by
              law or, in the opinion of the BRIKitt, duly authorized government
              body.
            </p>
            <br />
            <br />
            OWNERS GUESTS
            <br />
            <ol type="a">
              <li>
                A Owner may without effecting a transfer within the meaning of
                the above clause, permit other persons to take advantage of
                Exclusive Usage of Occupation allocated to that Owner to which
                the Points Usage Certificate relates, provided that, if he
                wishes to do so he shall give the Management Company not less
                than 14 days notice in writing, prior to the first date of the
                proposed occupation, which shall include the name and address of
                such person and shall pay the charge set, from time to time, by
                the Management Company, for the provision of that service.
              </li>
              <li>
                Notwithstanding any such permission as aforesaid, the holder of
                the points Usage Certificate shall remain liable under the
                provisions of these Rules and shall, inter alia, be responsible
                for the payment of the Management Charges and for the behavior
                of the person or persons occupying the Holiday Unit and for any
                damages caused by such person or persons.
              </li>
            </ol>
            <br />
            <br />
            LIMITATION ON USAGE GRANTED TO OWNERS
            <br />
            <p>
              No Owner shall be considered to be acquiring a property right on
              entering into a Purchase Agreement. Any clause or sub-clause of
              these Rules shall be null and void in respect of any particular
              Purchase Agreement if the application of that clause or sub-clause
              would bring any party to this project Documentation or the
              Purchase Agreement itself within the scope of any legislation
              relating to investment or to property usage in the country in
              which that Purchase Agreement was entered into Notwithstanding the
              fact that any clause or sub-clause of these Rules may have become
              null and void as a result of the application of this sub-clause,
              all other clauses or sub-clauses of these Rules shall remain valid
              and enforceable in their entirety.
            </p>
            <br />
            <br />
            ALL RIGHTS RESERVED
            <br />
            <p>
              DISCLAIMER: Information in this document is subject to change
              without notice. No part of this document may be reproduced or
              transmit- ted in any form or by means, electronic or mechanical,
              for any purpose without the express written permission of BRIKitt.
              BRIKitt may have pending trademarks, copyrights or other
              intellectual property covering subject matter in this document.
              The furnishing of this document does not give you license to these
              trademarks, copyrights or other intellectual property except as
              expressly provided in any written license agreement from BRIKitt.
            </p>
            <br />
            <br />
            Customer Declaration:
            <br />
            <p>
              I/We hereby confirm having read the Terms & Conditions and the
              Confirmation of Understanding mentioned above and affix my/our
              Signature as acceptance of the same. I hereby certified that the
              details furnished above are accurate and true to the best of my
              knowledge
            </p>
          </Box>
          <Box style={{ textAlign: "center", padding: "5px" }}>
            <Checkbox
              onSelect={() => {
                setSelected(true);
              }}
            ></Checkbox>
            I agree with the{" "}
            <span style={{ color: "#4A8EAB" }}>Terms and Conditions</span>
          </Box>
          <Box>
            <Button
              onClick={() => {
                // if(selected){
                if (auth.getUser().document_id === null) {
                  updateDocumentData({}).then((result) => {
                    setOpen(true);
                    let user = JSON.parse(localStorage.getItem("user"));

                    user = { ...user, ...result };
                    localStorage.setItem("user", JSON.stringify(user));
                  });
                } else {
                  setDialogText(initialText);
                  setOpen(true);
                }
                // }
              }}
              color="secondary"
              variant="contained"
              fullWidth
              disabled={isComponentBusy}
            >
              <Avatar src={Adhaar} />
              &nbsp;&nbsp;&nbsp; Adhaar E-sign
            </Button>
          </Box>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>
              <Box
                padding={1}
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                <Avatar
                  style={{ borderRadius: "0", alignItems: "center" }}
                  src={Check}
                />
              </Box>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <Box>Hi {auth.getUser().name},</Box>
                {dialogText}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      setOpen(false);
                      // console.log("on click of ok-->",auth.getUser().document_id);
                      if (
                        auth.getUser().document_id == null &&
                        auth.getUser().terms_accepted == false
                      ) {
                        let fd = new FormData();
                        fd.append("document_id", "12345");
                        updateDocumentData(fd);
                      } else {
                        let fd = new FormData();
                        fd.append("document_id", auth.getUser().document_id);
                        updateDocumentData(fd).then((result) => {
                          console.log("document id-->", result.document_id);
                          let user = JSON.parse(localStorage.getItem("user"));
                          user = { ...user, ...result };
                          localStorage.setItem("user", JSON.stringify(user));
                          if (result.terms_accepted) history.push("/");
                          else {
                            setDialogText(resendText);
                            setOpen(true);
                          }
                        });
                      }
                    }}
                  >
                    OK
                  </Button>
                </Grid>
                <Grid>
                  <Link
                    onClick={() => {
                      if (auth.getUser().document_id === null) {
                        updateDocumentData({}).then((result) => {
                          setOpen(true);
                          let user = JSON.parse(localStorage.getItem("user"));

                          user = { ...user, ...result };
                          localStorage.setItem("user", JSON.stringify(user));
                        });
                      } else setOpen(true);
                    }}
                    color="secondary"
                    style={{ cursor: "pointer", fontSize: 14 }}
                  >
                    Resend E-mail
                  </Link>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
          <Box style={{ textAlign: "center", padding: "5px" }}>
            {/* <Link href='/' color="secondary" style={{ cursor: "pointer", fontSize: 14 }}>
                    Download Brochure
                  </Link> */}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default AdhaarEsign;
