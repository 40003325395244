import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Box } from "@material-ui/core";

function Carousel({ data, ...props }) {
  return (
    <OwlCarousel
      nav={false}
      className="owl-theme"
      items={4}
      loop
      margin={10}
      {...props}
    >
      {data.map((item, index) => {
        return props.children(item);
      })}
    </OwlCarousel>
  );
}

export default Carousel;
