import React, {useEffect, useState} from "react";
import { Button, Grid, Box, TextField, Link} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import BrikittIcon from "../assets/icons/Brikitt.png";
import { getBookingByID } from "../store/actions/apis";
import { useParams } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CallBrikIcon2 from "../assets/icons/GroupCallIIcon.svg";

function CancelBooking() {
  const history = useHistory();
  const [bookingData, setBookingData] = useState({
    brik_images: [{ image: "" }],
  });
  const { booking_id } = useParams();
  useEffect(() => {
    getBookingByID(booking_id).then((result) => {
      setBookingData(result.data.bookings);
    });
  }, []);
  return (
    <Box className="layout" style={{top:"80px", bottom:"60px",padding:"2%"}}>
      <Box
          style={{
            display: "flex",
            padding: "3% 0",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Box>
          <Box
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowBackIosIcon />
            </Box>
          </Box>
          <Box fontSize="28px" fontWeight="700">
            My Bookings
          </Box>
      </Box>
      <Box
          style={{
            padding: "3% 0",
            alignItems: "center",
            color:"#565656",
            fontWeight:"700",
            fontSize:"18px",
            letterSpacing:"0.03em"
          }}
        >
          Tell us, what is the reason?
      </Box>
      <Box
          style={{
            padding: "3% 0",
            //alignItems: "center"
          }}
        >
          <Box
            style={{
              padding:"5px 2px",
              borderRadius:"10px",
              backgroundColor:"#E3E3E3",
              margin:"2%"
            }}>
              <Box
                style={{
                  fontWeight:"400",
                  fontSize:"14px",
                  color:"black",
                  marginLeft:"2%",
                  marginRight:"2%"
                }}>Lorem ipsum huana ghixo</Box>
          </Box>
          <Box
            style={{
              margin:"2%",
              padding:"5px 2px",
              borderRadius:"10px",
              backgroundColor:"#E3E3E3"
            }}>
              <Box
                style={{
                  fontWeight:"400",
                  fontSize:"14px",
                  color:"black",
                  marginLeft:"2%",
                  marginRight:"2%"
                }}>Gimrpo em ipsum hvosto a ghixo</Box>
          </Box>
          <Box
            style={{
              margin:"2%",
              padding:"5px 2px",
              borderRadius:"10px",
              backgroundColor:"#E3E3E3"
            }}>
              <Box
                style={{
                  fontWeight:"400",
                  fontSize:"14px",
                  color:"black",
                  marginLeft:"2%",
                  marginRight:"2%"
                }}>mistra bustha nimda o </Box>
          </Box>
          <Box
            style={{
              margin:"2%",
              padding:"5px 2px",
              borderRadius:"10px",
              backgroundColor:"#E3E3E3"
            }}>
              <Box
                style={{
                  fontWeight:"400",
                  fontSize:"14px",
                  color:"black",
                  marginLeft:"2%",
                  marginRight:"2%"
                }}>bindin thakue nimmda dusrea</Box>
          </Box>
      </Box>
      <Box
          style={{
            padding: "3% 0",
            //alignItems: "center"
          }}
        >
        <TextField
            style={{
              backgroundColor:"#F5F6FA",
              border:"1px solid #DFDFDF",
              borderRadius:"5px"
            }}
            // onChange={(event) => {
            //   setCancellationReason(event.target.value);
            // }}
            variant="outlined"
            fullWidth
            //error={cancellationError !== ""}
            //helperText={cancellationError}
            minRows={5}
            multiline
            placeholder="Tell us more......(Optional)"
          />
      </Box>
      <Box>
          <Link
            style={{
              textDecoration: "none",
              cursor: "pointer",
              color: "#4A8EAB",
            }}
          >
            <Box display="flex" marginTop="10px">
              <Box>
                <img src={CallBrikIcon2} />
              </Box>
              <Box marginLeft="8px">Call Brik Manager</Box>
            </Box>
          </Link>
      </Box>
      <Box
        marginTop="3%">
        <Button
          variant="contained"
          color="secondary"
          fullWidth>
            CANCEL BOOKING
        </Button>
      </Box>
    </Box>
  );
}

export default CancelBooking;
