import { UPDATE_BALANCE } from "../constants";
import { getWalletBalanceApi } from "./apis";

const updateBalance = (payload) => {
  return {
    type: UPDATE_BALANCE,
    payload,
  };
};

export const getWalletBalance = () => {
  return (dispatch) => {
    return getWalletBalanceApi()
      .then((result) => {
        dispatch(updateBalance(result.data.balance));
      })
      .catch((er) => {
        console.log("ownership list ERR: ", er);
      });
  };
};
