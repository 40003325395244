export const SET_USER = "SET_USER";
export const GET_USER = "GET_USER";
export const IS_COMPONENT_BUSY = "IS_COMPONENT_BUSY";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_NOTIFICATION_LIST = "SET_NOTIFICATION_LIST";
export const RESET_NOTIFICATION_COUNTER = "RESET_NOTIFICATION_COUNTER";
export const GET_MY_OWNERSHIPS = "GET_MY_OWNERSHIPS";
export const IS_COMPONENT_BUSY_OWNERSHIP = "IS_COMPONENT_BUSY_OWNERSHIP";
export const SET_COOWNERS = "SET_COOWNERS";
export const SET_TRANSACTION = "SET_TRANSACTION";

export const SET_USER_PROFILE_DATA = "SET_USER_PROFILE_DATA";

export const SET_BOOKINGS = "SET_BOOKINGS";
export const SET_SEARCH_BOOKINGS = "SET_SEARCH_BOOKINGS";
export const BOOKING_CHECKOUT = "BOOKING_CHECKOUT";
export const GET_BOOKINGS = "GET_BOOKINGS";
export const SET_BOOKINGS_GET_API = "SET_BOOKINGS_GET_API";

export const UPDATE_BALANCE = "UPDATE_BALANCE";
export const SET_CART_DETAILS = "SET_CART_DETAILS";
