import { Box, makeStyles } from "@material-ui/core";
import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import DashboardLayoutApp from "./layouts/MainDrawerApp";
import DashboardLayoutWeb from "./layouts/DashboardLayoutWeb";
import { useAuth } from "./providers/AuthProvider";
import Account from "./screens/Account";
import FAQ from "./screens/FAQ";
import Home from "./screens/Home";
import AdhaarEsign from "./screens/AdhaarEsign";
import HomePage from "./screens/HomePage";
import Login from "./screens/Login";
import MyOwnership from "./screens/MyOwnership";
import SliderImage from "./assets/images/Slider1.png";
import ProfileEdit from "./screens/ProfileEdit";
import PageNotFound from "./screens/PageNotFound";
import ForgotPassword from "./screens/ForgotPassword";
import ResetPassword from "./screens/ResetPassword";
import ChangePassword from "./screens/component/ChangePassword";
import Notification from "./layouts/Notification";
import GenerateToken from "./firebase/GenerateToken";
import { getNotificationsList } from "./store/actions/apis";
import { useDispatch } from "react-redux";
import {
  resetNotificationCounter,
  setNotificationList,
} from "./store/actions/userActions";

import SearchBooking from "./screens/SearchBooking";
import Payment from "./screens/Payment";
import BookingSuccess from "./screens/BookingSuccess";
import CancelBooking from "./screens/CancelBooking";
import BookingDetails from "./screens/BookingDetails";
import { getWalletBalance } from "./store/actions/walletAction";
import { useMediaQuery } from "react-responsive";
import Booking from "./screens/Booking";
import PropertyReviewRatingApp from "./screens/component/PropertyReviewRatingApp";
// const Booking = React.lazy(() => import("./screens/Booking"));
const DetailView = React.lazy(() => import("./screens/DetailView"));
const BookingReview = React.lazy(() => import("./screens/BookingReview"));
const DummyComponent = React.lazy(() =>
  import("./screens/component/DummyComponent")
);

const useStyles = makeStyles((theme) => ({
  web: {
    visibility: "block",
  },
  app: {
    display: "none",
  },
  [theme.breakpoints.down("sm")]: {
    web: {
      display: "none",
    },
    app: {
      display: "block",
    },
  },
}));

function PrivateRoute({ children, ...rest }) {
  const isExtraSmallMobileDevice = useMediaQuery({
    // query: "(max-device-width: 480px)"
    minWidth: 0,
    maxWidth: 480,
  });

  const isMobileDevice = useMediaQuery({
    // query: "(min-device-width: 480px)"

    minWidth: 481,
    maxWidth: 768,
  });

  //3550  ..double
  // 1450+90 =>2600
  const isTabletDevice = useMediaQuery({
    // query: "(min-device-width: 768px) and (min-device-width: 1023px)"
    minWidth: 769,
    maxWidth: 1024,
  });

  const isLaptop = useMediaQuery({
    // query: "(min-device-width: 1024px) and (max-device-width: 1199px)"
    minWidth: 1025,
    maxWidth: 1200,
  });

  const isDesktop = useMediaQuery({
    // query: "(min-device-width: 1200px)"
    minWidth: 1201,
  });

  let auth = useAuth();
  const classes = useStyles();

  return (
    // auth.getUser()?.terms_accepted ? (
    <>
      {/* {window.screen.availWidth > 393 ? ( */}
      {(isTabletDevice || isLaptop || isDesktop) && (
        <Box
        // className={classes.web}
        >
          <Route
            {...rest}
            render={({ location }) => (
              <DashboardLayoutWeb>{children}</DashboardLayoutWeb>
            )}
          />
        </Box>
      )}
      {/* ) : ( */}
      {(isMobileDevice || isExtraSmallMobileDevice) && (
        <Box
          // className={classes.app}
          style={{ marginTop: "60px" }}
        >
          <Route
            {...rest}
            render={({ location }) =>
              auth.getUserToken() ? (
                <DashboardLayoutApp open={true}>{children}</DashboardLayoutApp>
              ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: location },
                  }}
                />
              )
            }
          />
        </Box>
      )}
      {/* )} */}
    </>
    // ) : (
    //   <AdhaarEsign />
    // );
  );
}

function PublicRoute({ children, ...rest }) {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.web}>
        <Route {...rest} render={({ location }) => children} />
      </Box>
      <Box className={classes.app}>
        <Route
          {...rest}
          render={({ location }) => (
            <DashboardLayoutApp open={true}>{children}</DashboardLayoutApp>
          )}
        />
      </Box>
    </>
  );
}

function App() {
  const dispatch = useDispatch();
  const auth = useAuth();

  useEffect(() => {
    if (auth.getUser()) {
      getNotificationsList().then((result) => {
        var count = result.data.reduce(function (n, val) {
          return n + (val.read === false);
        }, 0);

        dispatch(resetNotificationCounter(count));
        dispatch(setNotificationList(result.data));
      });
      dispatch(getWalletBalance());
    }
  }, [auth.getUser]);

  return (
    <>
      <Suspense fallback={<div>"Loading.."</div>}>
        <GenerateToken />
        <BrowserRouter>
          <Switch>
            <PrivateRoute exact path="/home">
              <Home />
            </PrivateRoute>
            <PrivateRoute exact path="/my-ownership">
              <MyOwnership />
            </PrivateRoute>
            <PrivateRoute exact path="/detail/:id">
              <DetailView />
            </PrivateRoute>
            <PrivateRoute exact path="/detail/review/:id">
              <PropertyReviewRatingApp />
            </PrivateRoute>
            <PrivateRoute exact path="/detail/:id/coowners">
              <DetailView tabSelected={1} />
            </PrivateRoute>
            <PrivateRoute exact path="/detail/:id/requests">
              <DetailView tabSelected={1} modalStatus={1} />
            </PrivateRoute>
            <PrivateRoute exact path="/account">
              <Account />
            </PrivateRoute>
            <PrivateRoute exact path="/account/edit">
              <ProfileEdit />
            </PrivateRoute>
            <PrivateRoute exact path="/faq">
              <FAQ />
            </PrivateRoute>
            <PrivateRoute exact path="/booking">
              <Booking />
            </PrivateRoute>
            <PrivateRoute exact path="/payment">
              <Payment />
            </PrivateRoute>
            <PrivateRoute exact path="/booking/:brik_id">
              <BookingReview />
            </PrivateRoute>
            <PrivateRoute exact path="/booking/:booking_id/details">
              <BookingDetails />
            </PrivateRoute>
            <PrivateRoute exact path="/search">
              <SearchBooking />
            </PrivateRoute>
            <PrivateRoute exact path="/terms">
              <AdhaarEsign />
            </PrivateRoute>
            <PublicRoute exact path="/">
              <Login />
            </PublicRoute>
            <PublicRoute exact path="/forgotpassword">
              <ForgotPassword />
            </PublicRoute>
            <PublicRoute exact path="/resetpassword">
              <ResetPassword />
              </PublicRoute>
            <PublicRoute exact path="/changepassword">
              <ChangePassword />
            </PublicRoute>
            <PublicRoute exact path="/notification">
              <Notification />
            </PublicRoute>
            <PublicRoute exact path="/congratulations/:booking_id">
              <BookingSuccess />
            </PublicRoute>
            <PublicRoute exact path="/cancelbooking">
              <CancelBooking />
            </PublicRoute>
            <PrivateRoute exact path="/dummy">
              <DummyComponent />
            </PrivateRoute>
            <Route component={PageNotFound} />
            {/* <PublicRoute exact path="/login" >
            <Login />
            </PublicRoute> */}
          </Switch>
        </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;
