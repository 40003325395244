import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import TextField from "@mui/material/TextField";
import { Switch } from '@material-ui/core';
import {
    addReview,
    getReviews,
    getPropertyDetails,
} from "../../store/actions/apis.js";
import { AccordionDetails, AccordionSummary } from '@material-ui/core';
import FormControlLabel from '@mui/material/FormControlLabel';
import RoomIcon from '@material-ui/icons/Room';
import { useSnackbar } from 'notistack'
import { useHistory, useParams } from "react-router-dom";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import Paper from '@mui/material/Paper';


function PropertyReviewRatingApp() {
    const notification = useSnackbar()
    const [value, setValue] = React.useState(0);
    const [reviewTitle, setReviewTitle] = useState("");
    const [userReview, setUserReview] = useState("");
    const [props, setProps] = useState({
        amenities: [{}],
        property_detail: [{}],
        brik_image: [{}],
        spv_info: [{}],
    });
    const [expanded, setExpanded] = useState(0);
    const [recommend, setRecommend] = useState(false);
    const [coOwner, setCoOwner] = useState(false);
    const history = useHistory();
    const [showEdit, setShowEdit] = useState({
        sectionOne: false,
        sectionTwo: false,
        sectionThree: false
    }); // to show edit button
    const [brikImage, setBrikImage] = useState("")
    const [brikName, setBrikName] = useState("")
    const [brikLocation, setBrikLocation] = useState("")
    const [files, setFiles] = useState([]);
    let [reviews, setReviews] = useState({
        rating_dict: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
        reviews: [],
    });
    const { id } = useParams();

    const nextButtonStyle = {
        backgroundColor: '#8B7862',
        color:"#FFFFFF",
        width: "40%",
        borderRadius: "5px"
    }
    const backButtonStyle = {
        color:"#8B7862",
        border: '1px solid #8B7862',
        backgroundColor: '#FFFFFF',
        height: "40px",
        width: "40%",
        borderRadius: "5px"
    }
    const headingButtonStyle = {
        padding: 0,
        borderRadius: "50%",
        minWidth: "0px",
        width: "20px",
        height: "20px",
        fontSize: "16px",
        mb: "3px",
        mr: "10px"
    }
    const headingTextStyle = {
        fontSize: "18px"
    }

    const handleAccordionChange = (index) => {
        setExpanded(index);
    };

    const handleRecommendChange = (event) => {
        setRecommend(event.target.checked);
    };

    const handleCoOwnerChange = (event) => {
        setCoOwner(event.target.checked);
    };

    const handleDropzoneChange = (newFiles) => {
        setFiles([...files, ...newFiles]);
    };

    useEffect(() => {
        let data;
        getPropertyDetails(id).then((result) => {
            // console.log('id:', id)
            data = result.data;
            // console.log('fff:', data)
            setProps(data);
            setBrikImage(data?.brik_cover_image)
            // console.log('setBrikImage:', propertyDetails?.brik_cover_image)
            setBrikName(data?.property_name)
            setBrikLocation(data?.location)
            getReviews(data?.brik_id).then((result) => {
                setReviews(result.data);
                console.log('reviews:', reviews)
            });
        });
    }, [id]);

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = 3;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    
    return (<>
        <Box sx={{ mt:"20px", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
            <Box>
            <Box sx={{ fontSize: "28px", fontWeight:"bold", display:"flex" }}>
                <Box onClick={() => history.push(`/detail/${id}`)}>
                    <KeyboardArrowLeft sx={{fontSize:"34px", mt:"2px"}}/>
                </Box>
                <Box>
                    {"Write a review"}
                </Box>
            </Box>
            <Paper
                square
                elevation={0}
                style={{ display:"flex", flexDirection:"column",height:"100%", justifyContent:"space-between", paddingBottom:"10px"}}
            >
                    {/* <Typography>{steps[activeStep].label}</Typography> */}
                <Box style={{height:"72vh"}}>
                { activeStep === 0 ? <div style={{ marginBottom: 20 }}>
                        <Box>
                        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                            <Button variant="contained" sx={headingButtonStyle}>1</Button>
                            <Typography variant="h5" style={headingTextStyle}><strong>Brik Rating</strong></Typography>
                            {/* {showEdit["sectionOne"] === true ? (
                                <Button variant="text" sx={editButtonStyle} startIcon={<EditIcon />} onClick={(event) => {
                                    setExpanded(0)
                                    setShowEdit({
                                        "sectionOne": false,
                                        "sectionTwo": true,
                                        "sectionThree": true
                                    })
                                }}>Back</Button>
                            ) : <></>} */}
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                {/* Brik rating and overall raing parent div */}
                                <div style={{
                                    width: "100%",
        
                                    justifyContent: "space-between",
                                    marginBottom: 10,
                                }}>
                                    {/* Brik details div */}
                                    <div style={{
                                         lineHeight:"14px"
                                    }}>
                                        <img src={brikImage} style={{
                                            borderRadius: 5, width:"60%",height:"60%"
                                        }}
                                            alt="Image description" width={130} height={80} />
                                        <div class="text" style={{ marginTop:"12px" }}>
                                            <Typography component="legend" style={{ fontSize: "18px", marginBottom: 5 }}><strong>{brikName}</strong></Typography>
                                            <Typography component="legend" style={{ fontSize: "14px", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{<RoomIcon style={{ color: "#212630", fontSize: "14px" }} />}{brikLocation}</Typography>
                                        </div>
                                    </div>
                                    {/* overall raing div */}
                                    <div style={{
                                        display: "flex",
                                        lineHeight: "18px",
                                        marginTop:"10px"
                                    }}>
                                        <Typography id="modal-modal-description">
                                            <Typography component="legend" style={{ fontSize: "16px", fontWeight:"bold" }}>Overall Rating</Typography>
                                            <Rating
                                                name="simple-controlled"
                                                sx={{ fontSize: "32px" }}
                                                value={value}
                                                onChange={(event, newValue) => {
                                                    setValue(newValue);
                                                }}
                                            />
                                        </Typography>
                                    </div>
                                </div>
                                {/* switch buttons div */}
                                <div style={{
                                    width: "100%",
                                    marginRight: 30
                                }}>
                                    <FormControlLabel style={{
                                        display: 'flex',
                                        width: "100%",
                                        alignItems: 'center',
                                        fontWeight:"600",
                                        justifyContent: 'space-between'
                                    }}
                                        control={
                                            <Switch
                                                style={{
                                                    fontSize: "14px"
                                                }}
                                                checked={recommend}
                                                onChange={handleRecommendChange}
                                                name="recommend"
                                            />
                                        }
                                        label="I would recommend this property"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel style={{
                                        display: 'flex',
                                        width: "100%",
                                        alignItems: 'center',
                                        ontWeight: "600",
                                        justifyContent: 'space-between'
                                    }}
                                        control={
                                            <Switch
                                                checked={coOwner}
                                                onChange={handleCoOwnerChange}
                                                name="coOwner"
                                            />
                                        }
                                        label="I am a co-owner of this brik"
                                        labelPlacement="start"
                                    />
                                </div>
                                {/* next button div */}
                                <div style={{
                                    width: "107%",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    top: 20
                                }}>
                                    
                                </div>
                            </div>
                        </AccordionDetails>
                    </Box>
                </div> : <Box>
                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                            <Button variant="contained" sx={headingButtonStyle}>2</Button>
                            <Typography variant="h5" style={headingTextStyle}><strong>Share your experience</strong></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ width:"100%"}}>
                            <Typography component="legend" style={{ marginBottom: 10 }}>Review title</Typography>
                            <TextField
                                value={reviewTitle}
                                minRows={1}
                                placeholder="Give a title"
                                className="ratingModalInput"
                                onChange={(ev) => {
                                    setReviewTitle(ev.target.value);
                                    console.log("Review title: ", ev.target.value);
                                }}
                                fullWidth
                                id="fullWidth"
                                style={{ backgroundColor: "#F5F6FA" }}
                            />
                            <Typography component="legend" style={{ marginBottom: 10, marginTop: 10 }}>Review</Typography>
                            <TextField
                                value={userReview}
                                multiline
                                minRows={6}
                                placeholder="Write your review"
                                className="ratingModalInput"
                                onChange={(ev) => {
                                    setUserReview(ev.target.value);
                                    console.log("New Review: ", ev.target.value);
                                }}
                                fullWidth
                                id="fullWidth"
                                style={{ backgroundColor: "#F5F6FA" }}
                            // style={{ height: "148px !important" }}
                            />
                        </Box>
                    </AccordionDetails>
                        </Box>}
                    </Box>
                    <Box style={{ display: "flex", justifyContent: "space-between", width: "90%", margin: "auto" }}>
                        <Button style={backButtonStyle} onClick={handleBack} disabled={activeStep === 0} >
                            Back
                        </Button>
                        {activeStep === 0 ? <Button style={nextButtonStyle}
                            onClick={(event) => {
                                setExpanded(2);
                                setShowEdit({
                                    ...showEdit,
                                    "sectionTwo": true
                                })
                                handleNext();
                            }}
                            disabled={activeStep === maxSteps - 1}>
                            Next
                        </Button> : <Button style={nextButtonStyle}
                                onClick={() => {
                                    const requiredFields = [value];
                                    if (requiredFields.some(field => !field)) {
                                        notification.enqueueSnackbar('Please fill all required fields.', {
                                            variant: "error",
                                            autoHideDuration: 2000
                                        })
                                        return;
                                    }

                                    const apiBody = {
                                        booking_id: props.bookings[0].id,
                                        rate: value,
                                        title: reviewTitle,
                                        review: userReview,
                                        is_recommend_property: recommend,
                                        is_coowner: coOwner
                                    };
                                    console.log("API Body: ", props);
                                    addReview(apiBody).then((results) => {
                                        const { status } = results
                                        if (status == 201) notification.enqueueSnackbar('Review submitted successfully', {
                                            variant: "success",
                                            autoHideDuration: 2000
                                        })
                                    }).catch((er) => {
                                        console.log('er:', er)
                                        notification.enqueueSnackbar('Not a valid user to add a review or review already exist', {
                                            variant: "error",
                                            autoHideDuration: 2000
                                        })
                                    });
                                    history.push(`/detail/${id}`)
                            }}
                            disabled={activeStep === maxSteps - 1}>
                            Submit
                        </Button>}
                    </Box>
            </Paper>
            </Box>
        </Box>
        
    </>)
}

export default React.memo(PropertyReviewRatingApp);
