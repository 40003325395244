import { Box, Typography, Card, Button, Grid } from "@material-ui/core";
import React from "react";
import images from "../../assets/images/Slider1.png";
import RoomIcon from "@material-ui/icons/Room";
import { useHistory } from "react-router-dom";
import { responsiveStyles } from "../../assets/css/generalStyling";
import Gold from "../../assets/icons/Gold.png";
import BarIcon from "../../assets/icons/Bar.png";

function PropertyCard({ data, onClick }) {
  const paperStyle = {
    padding: "10px",
    margin: "10px",
    position: "relative",
    minHeight: "310px",
    maxHeight: "300px",
  };
  const history = useHistory();
  const responsive = responsiveStyles();

  const image = {
    width: 300,
    height: 250,
  };
  const img = {
    display: "Block",
    margin: "auto",
    borderRadius: "10px",
    maxWidth: "100%",
    height: "200px",
  };
  const box = {
    padding: "16px 0px 0px 7px",
    // padding: "7px",
  };

  return (
    <Box style={{ cursor: "pointer" }} onClick={() => onClick(data)}>
      <Card elevation={3} style={paperStyle}>
        <img style={img} src={data.spv_cover_image?data.spv_cover_image:data.brik_cover_image} />
        <Box
          style={{
            display: "flex",
            position: "absolute",
            top: "18px",
            fontSize: "12px",
            right: "22px",
            background: "#0B4B66",
            padding: "4px 15px",
            alignItems: "center",
            fontWeight: "600",
            borderRadius: "20px",
            color: "white",
          }}
        >
          <Box>Owned</Box>
          <Box style={{ margin: "4px", height: "10px", width: "10px" }}>
            <img src={BarIcon} />
          </Box>
          <Box>{data.property_detail[0]?.ownership_integer}/11</Box>
        </Box>
        <Box style={box}>
          <Box
            style={{ display: "flex", justifyContent: "space-between" }}
            className={responsive.web}
          >
            <Typography
              style={{ fontWeight: "bold", color: "#4A8EAB", fontSize: "90%" }}
            >
              {data.property_name}
            </Typography>
            <Box
              border="1px solid #EDEDED"
              borderRadius="10px"
              paddingRight="20px"
              color="#FF9E12"
              fontWeight="600"
              style={{
                backgroundColor: "#FFF9E7",
                display: "flex",
                alignItems: "center",
                fontSize: "90%",
              }}
            >
              <Box paddingLeft="5px">
                <img src={Gold} />
              </Box>
              <Box paddingLeft="5px">{data.property_type}</Box>
            </Box>
          </Box>

          <Box display="flex" alignItems="center">
            <RoomIcon style={{ color: "red", fontSize: "18px" }} />
            <Typography
              variant="subtitle2"
              style={{ paddingLeft: "5px", fontSize: "13px" }}
            >
              {data.location}
            </Typography>
          </Box>

          <Box className={responsive.app}>
            <Box display="flex" flexDirection="row">
              <Box style={{ fontSize: "13px" }}>₹ {data.brik_value}</Box>
              {/* <Box border='1px solid black' borderRadius='10px' padding='0 8px' color='#FF9E12' fontWeight='600' style={{ backgroundColor: '#FFF9E7' }}>{data.property_type}</Box> */}
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

export default PropertyCard;
