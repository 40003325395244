const { SET_USER, IS_COMPONENT_BUSY, SET_NOTIFICATION, RESET_NOTIFICATION_COUNTER,SET_NOTIFICATION_LIST } = require("../constants")

let initialState = {
    userData: {},
    isComponentBusy: false,
    notificationCounts:0,
    notifications: []
}

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_NOTIFICATION_COUNTER:
            // return {...state,notificationCounts:state.notificationCounts>0?state.notificationCounts+action.payload:0}
            return {...state,notificationCounts:action.payload}
        case SET_NOTIFICATION_LIST:
            return { ...state, notifications: [...action.payload] }
        case SET_NOTIFICATION:
            return { ...state, notifications: [action.payload,...state.notifications] }
        case SET_USER:
            return { ...state, userData: action.payload }
        case IS_COMPONENT_BUSY:
            return { ...state, isComponentBusy: action.payload }
        default:
            return state
    }
}

export default UserReducer;