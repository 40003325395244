import { Grid, Box, makeStyles, Card, Button, TextField,CircularProgress, } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { responsiveStyles } from "../../assets/css/generalStyling";
import { useSnackbar } from 'notistack';
import { useAuth } from "../../providers/AuthProvider";
import BrikittIcon from "../../assets/images/Brikitt.png";
import { changePassword } from "../../store/actions/apis";
  
  const useStyles = makeStyles((theme) => ({slider: {},[theme.breakpoints.down("sm")]: {slider: {},},}));
  
  function ChangePassword() {
    const responsive = responsiveStyles();
    const classes = useStyles();
    const history = useHistory();
    const notification = useSnackbar()
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");    
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const auth = useAuth();

    const handleChangePassword = async () => {
      if (newPassword !== confirmPassword) {
        notification.enqueueSnackbar('New Password and Confirm Password Do Not Match', {
          variant: "error",
          autoHideDuration: 2000
      })}
      
      else{
        try {
          const response = await changePassword({
            old_password: oldPassword,
            new_password1: newPassword,
            new_password2: newPassword,
            
          });
          notification.enqueueSnackbar('Password Changed Successfully', {
                     variant: "success",
                     autoHideDuration: 7000
                 })
          auth.logout();
          setMessage(response.message);
          setError("");
        } catch (error) {
          setMessage("");
          setError(error.message || "An error occurred");
        }       
      }
    };
  
    return (
      <div container>
        <div className={responsive.web}>
          <Box className={classes.slider}>
            <Box style={{ fontSize: "18px", padding: "10px",}}>
              <button onClick={()=>{history.push("/")}}> 
                <img src={BrikittIcon} alt="BrikItt" width="50px" height= "60px" /> 
              </button>
            </Box>
            <Grid container>
            </Grid>
          </Box>
        </div>
  
        <div
          // className="flex center"
          style={{ padding: "100px 0" }}
          className={responsive.web}
        >
          <Card
            style={{
              // background: "#282827",
              color: "white",
              fontSize: "10px",
              padding: "10px",
            }}
          >
            <div style={{ padding: "20px", maxWidth: "300px", margin: "auto", border: "1px solid #ccc", borderRadius: "8px" }}>
              <label style={{ display: "block", marginBottom: "8px" }}>Old Password</label>
              <input type="password" placeholder="Enter old password" onChange={(e) => setOldPassword(e.target.value)} style={{ width: "100%", padding: "8px", marginBottom: "12px", borderRadius: "4px", border: "1px solid #ccc" }} />

              <label style={{ display: "block", marginBottom: "8px" }}>New Password</label>
              <input type="password" placeholder="Enter new password" onChange={(e) => setNewPassword(e.target.value)} style={{ width: "100%", padding: "8px", marginBottom: "12px", borderRadius: "4px", border: "1px solid #ccc" }} />

              <label style={{ display: "block", marginBottom: "8px" }}>Confirm Password</label>
              <input type="password" placeholder="Confirm new password" onChange={(e) => setConfirmPassword(e.target.value)} style={{ width: "100%", padding: "8px", marginBottom: "12px", borderRadius: "4px", border: "1px solid #ccc" }} />

              <button onClick={handleChangePassword} style={{ width: "100%", padding: "10px", background: "#434343", color: "white", border: "none", borderRadius: "4px", cursor: "pointer" }}>
                  Change Password
              </button>
          </div>

          </Card>
        </div>
  
        <div
          item
          xs={12}
          style={{ padding: "200px 0" }}
          className={responsive.app}
        >
          <Card
            style={{
              background: "#282827",
              color: "white",
              fontSize: "10px",
              padding: "10px",
            }}
          >
            <div style={{ padding: "20px", maxWidth: "300px", margin: "auto", border: "1px solid #ccc", borderRadius: "8px" }}>
              <label style={{ display: "block", marginBottom: "8px" }}>Old Password</label>
              <input type="password" placeholder="Enter old password" onChange={(e) => setOldPassword(e.target.value)} style={{ width: "100%", padding: "8px", marginBottom: "12px", borderRadius: "4px", border: "1px solid #ccc" }} />

              <label style={{ display: "block", marginBottom: "8px" }}>New Password</label>
              <input type="password" placeholder="Enter new password" onChange={(e) => setNewPassword(e.target.value)} style={{ width: "100%", padding: "8px", marginBottom: "12px", borderRadius: "4px", border: "1px solid #ccc" }} />

              <label style={{ display: "block", marginBottom: "8px" }}>Confirm Password</label>
              <input type="password" placeholder="Confirm new password" onChange={(e) => setConfirmPassword(e.target.value)} style={{ width: "100%", padding: "8px", marginBottom: "12px", borderRadius: "4px", border: "1px solid #ccc" }} />

              <button onClick={handleChangePassword} style={{ width: "100%", padding: "10px", background: "#434343", color: "white", border: "none", borderRadius: "4px", cursor: "pointer" }}>
                  Change Password
              </button>
          </div>
          </Card>
        </div>
      </div>
    );
  }
  
  export default ChangePassword;
  
