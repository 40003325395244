import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, Button } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { responsiveStyles } from "../../assets/css/generalStyling";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { PinDropSharp } from "@material-ui/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../providers/AuthProvider";
import {
  updateContactDetails,
  addContactDetails,
  getProfileData,
} from "../../store/actions/apis";
import { useSnackbar } from "notistack";
import { setProfileData } from "../../store/actions/accountActions";

function ContactDetail(props) {
  const responsive = responsiveStyles();
  const [formMode, setFormMode] = useState("add");
  const notification = useSnackbar();
  const dispatch = useDispatch();
  const auth = useAuth();
  const contactData = useSelector((state) => {
    return state.account.contact_info;
  });

  let schema = yup.object().shape({
    email: yup.string().required("Email is required"),
    mobile_number: yup.string().required("Mobile Number is required"),
    house_number: yup.string().required("House Number is required"),
    street: yup.string().required("Street is required"),
    pincode: yup.string().required("Pincode is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
  });

  let {
    values,
    touched,
    errors,
    handleBlur,
    setValues,
    setFieldValue,
    submitForm,
    resetForm,
    handleChange,
  } = useFormik({
    initialValues: {
      email: "",
      mobile_number: "",
      house_number: "",
      street: "",
      pincode: "",
      city: "",
      state: "",
    },
    validationSchema: schema,
    onSubmit: () => {
      if (formMode == "add") {
        addContactDetails({
          apiBody: {
            ...values,
          },
        }).then((result) => {
          console.log("RESULT: ", result);
          getProfileData(auth.getUser().user_id).then((result) => {
            dispatch(setProfileData(result.data));
          });
          notification.enqueueSnackbar("Profile Updated Successfully", {
            variant: "success",
            autoHideDuration: 2000,
          });
        });
      } else
        updateContactDetails({
          user_id: auth.getUserId(),
          apiBody: {
            ...values,
          },
        }).then((result) => {
          console.log("RESULT: ", result);
          getProfileData(auth.getUser().user_id).then((result) => {
            dispatch(setProfileData(result.data));
          });
          notification.enqueueSnackbar("Profile Updated Successfully", {
            variant: "success",
            autoHideDuration: 2000,
          });
        });
    },
  });

  useEffect(() => {
    if (contactData !== null) {
      setFormMode("edit");
      setValues(contactData);
    }
  }, [contactData]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            padding="10px 0"
            fontWeight="700"
            fontSize="19px"
            onClick={() => {
              props.onBack();
            }}
          >
            <Box className={responsive.app}>
              <ChevronLeftIcon />
              &nbsp;
            </Box>
            <Box>Contact Info</Box>
          </Box>

          <Box>
            <Box padding="5px 0" fontWeight="700">
              EMAIL
            </Box>
            <TextField
              id="email"
              name="email"
              value={values.email}
              disabled
              onChange={handleChange}
              error={touched.email && errors.email ? true : false}
              helperText={touched.email && errors.email}
              fullWidth
              variant="outlined"
              placeholder="@gmail.com"
              style={{ paddingBottom: "10px" }}
            />
          </Box>
          <Box padding="10px 0">
            <Box padding="5px 0" fontWeight="700">
              PHONE
            </Box>
            <TextField
              id="mobile_number"
              name="mobile_number"
              value={values.mobile_number}
              onChange={handleChange}
              error={
                touched.mobile_number && errors.mobile_number ? true : false
              }
              helperText={touched.mobile_number && errors.mobile_number}
              fullWidth
              variant="outlined"
              placeholder="+91"
              style={{ paddingBottom: "10px" }}
            />
          </Box>
          <Box>
            <Box padding="5px 0" fontWeight="700">
              FULL ADDRESS
            </Box>
            <TextField
              id="house_number"
              name="house_number"
              value={values.house_number}
              onChange={handleChange}
              error={touched.house_number && errors.house_number ? true : false}
              helperText={touched.house_number && errors.house_number}
              fullWidth
              variant="outlined"
              placeholder="House Name / Flat Number"
              style={{ paddingBottom: "10px" }}
            />
            <TextField
              id="street"
              name="street"
              value={values.street}
              onChange={handleChange}
              error={touched.street && errors.street ? true : false}
              helperText={touched.street && errors.street}
              fullWidth
              variant="outlined"
              placeholder="Street"
              style={{ paddingBottom: "10px" }}
            />
            <TextField
              id="pincode"
              name="pincode"
              value={values.pincode}
              onChange={handleChange}
              error={touched.pincode && errors.pincode ? true : false}
              helperText={touched.pincode && errors.pincode}
              fullWidth
              variant="outlined"
              placeholder="Pincode"
              style={{ paddingBottom: "10px" }}
            />
            <TextField
              id="city"
              name="city"
              value={values.city}
              onChange={handleChange}
              error={touched.city && errors.city ? true : false}
              helperText={touched.city && errors.city}
              fullWidth
              variant="outlined"
              placeholder="City"
              style={{ paddingBottom: "10px" }}
            />
            <TextField
              id="state"
              name="state"
              value={values.state}
              onChange={handleChange}
              error={touched.state && errors.state ? true : false}
              helperText={touched.state && errors.state}
              fullWidth
              variant="outlined"
              placeholder="State"
              style={{ paddingBottom: "10px" }}
            />
          </Box>
          <Box padding="50px 0" display="flex" justifyContent="center">
            <Button
              onClick={submitForm}
              variant="contained"
              color="secondary"
              style={{ width: "65%", borderRadius: "5px" }}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default ContactDetail;
