import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { Box, Grid, TextField, Button, IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { responsiveStyles } from "../../assets/css/generalStyling";
import { useFormik } from "formik";
import * as yup from "yup";
import FilePicker from "../../utils/FilePicker";
import { useAuth } from "../../providers/AuthProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  updateNomineeDetails,
  addNomineeDetails,
  getProfileData,
} from "../../store/actions/apis";
import { useSnackbar } from "notistack";
import { setProfileData } from "../../store/actions/accountActions";

function NomineeDetails(props) {
  const responsive = responsiveStyles();
  const [formMode, setFormMode] = useState("add");
  const auth = useAuth();
  const dispatch = useDispatch();
  const notification = useSnackbar();
  const NomineeDeta = useSelector((state) => {
    return state.account.nominee_details;
  });

  let schema = yup.object().shape({
    full_name: yup.string().required("Full Name is required"),
    contact_number: yup.string().required("Contact Number is required"),
    email: yup.string().required("Email is required"),
    relationship: yup.string().required("Relationship is required"),
    aadhaar_number: yup.string().required("Aadhar is required"),
    pan_number: yup.string().required("Pan Card is required"),
  });

  let {
    values,
    touched,
    errors,
    handleBlur,
    setValues,
    setFieldValue,
    submitForm,
    resetForm,
    handleChange,
  } = useFormik({
    initialValues: {
      full_name: "",
      contact_number: "",
      email: "",
      relationship: "",
      aadhaar_number: "",
      pan_number: "",
      photo: "",
      photo_file: "",
    },
    validationSchema: schema,
    onSubmit: () => {
      let fd = new FormData();

      let apiBody = {
        full_name: values.full_name,
        contact_number: values.contact_number,
        email: values.email,
        relationship: values.relationship,
        aadhaar_number: values.aadhaar_number,
        pan_number: values.pan_number,
        photo: values.photo_file,
      };

      if (typeof values.photo_file !== "object") {
        delete apiBody.photo;
      }

      for (var pair of Object.entries(apiBody)) {
        console.log(pair[0] + ", " + pair[1]);
        fd.append(pair[0], pair[1]);
      }

      if (formMode == "add") {
        addNomineeDetails({
          apiBody: fd,
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW;",
          },
        }).then((result) => {
          console.log("RESULT: ", result);
          getProfileData(auth.getUser().user_id).then((result) => {
            dispatch(setProfileData(result.data));
          });
          notification.enqueueSnackbar("Nominee Details Saved Successfully", {
            variant: "success",
            autoHideDuration: 2000,
          });
        });
      } else
        updateNomineeDetails({
          user_id: values.id,
          apiBody: fd,
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW;",
          },
        }).then((result) => {
          getProfileData(auth.getUser().user_id).then((result) => {
            dispatch(setProfileData(result.data));
          });
          notification.enqueueSnackbar("Nominee Details Saved Successfully", {
            variant: "success",
            autoHideDuration: 2000,
          });
          console.log("RESULT: ", result);
        });
    },
  });

  useEffect(() => {
    if (NomineeDeta !== null) {
      setFormMode("edit");
      setValues({
        ...NomineeDeta,
        photo: NomineeDeta.photo,
        photo_file: NomineeDeta.photo,
      });
    }
  }, [NomineeDeta]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box
            display="flex"
            padding="15px 0"
            fontWeight="700"
            fontSize="19px"
            onClick={() => {
              props.onBack();
            }}
          >
            <Box className={responsive.app}>
              <ChevronLeftIcon />
              &nbsp;
            </Box>
            <Box>Nominee Details</Box>
          </Box>
          <Box>
            <Box padding="5px 0" fontWeight="700" fontSize="13px">
              FULL NAME
            </Box>
            <TextField
              id="full_name"
              name="full_name"
              value={values.full_name}
              onChange={handleChange}
              error={touched.full_name && errors.full_name ? true : false}
              helperText={touched.full_name && errors.full_name}
              fullWidth
              variant="outlined"
              placeholder="Enter Full Name"
              style={{ paddingBottom: "10px" }}
            />
          </Box>
          <Box>
            <Box padding="5px 0" fontWeight="700" fontSize="13px">
              CONTACT NUMBER
            </Box>
            <TextField
              id="contact_number"
              name="contact_number"
              value={values.contact_number}
              onChange={handleChange}
              error={
                touched.contact_number && errors.contact_number ? true : false
              }
              helperText={touched.contact_number && errors.contact_number}
              fullWidth
              variant="outlined"
              placeholder="Enter Nominee's Contact Number"
              style={{ paddingBottom: "10px" }}
            />
          </Box>
          <Box>
            <Box padding="5px 0" fontWeight="700" fontSize="13px">
              EMAIL ID
            </Box>
            <TextField
              id="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              error={touched.email && errors.email ? true : false}
              helperText={touched.email && errors.email}
              fullWidth
              variant="outlined"
              placeholder="Enter Email ID"
              style={{ paddingBottom: "10px" }}
            />
          </Box>
          <Box>
            <Box padding="5px 0" fontWeight="700" fontSize="13px">
              RELATIONSHIP WITH BRIK OWNER
            </Box>
            <TextField
              id="relationship"
              name="relationship"
              value={values.relationship}
              onChange={handleChange}
              error={touched.email && errors.email ? true : false}
              helperText={touched.email && errors.email}
              fullWidth
              variant="outlined"
              placeholder="Enter Relationship"
              style={{ paddingBottom: "10px" }}
            />
          </Box>
          <Box>
            <Box padding="5px 0" fontWeight="700" fontSize="13px">
              AADHAR NUMBER
            </Box>
            <TextField
              id="aadhaar_number"
              name="aadhaar_number"
              value={values.aadhaar_number}
              onChange={handleChange}
              error={
                touched.aadhaar_number && errors.aadhaar_number ? true : false
              }
              helperText={touched.aadhaar_number && errors.aadhaar_number}
              fullWidth
              variant="outlined"
              placeholder="Enter Aadhar Number"
              style={{ paddingBottom: "10px" }}
            />
          </Box>
          <Box>
            <Box padding="5px 0" fontWeight="700" fontSize="13px">
              PAN CARD NUMBER
            </Box>
            <TextField
              id="pan_number"
              name="pan_number"
              value={values.pan_number}
              onChange={handleChange}
              error={touched.pan_number && errors.pan_number ? true : false}
              helperText={touched.pan_number && errors.pan_number}
              fullWidth
              variant="outlined"
              placeholder="Enter Pan card Number"
              style={{ paddingBottom: "10px" }}
            />
          </Box>
          <Box>
            <Box padding="8px 0" fontWeight="700" fontSize="13px">
              UPLOAD PHOTO
            </Box>

            <FilePicker
              type="component"
              fileHandler={(file, alt) => {
                // console.log("FILE PICKED: ", URL.createObjectURL(file))
                setFieldValue("photo_file", file);
                setFieldValue("photo", URL.createObjectURL(file));
              }}
            >
              {values.photo === "" ? (
                <Box
                  width="90px"
                  border="1px dashed black"
                  padding="10px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <IconButton>
                    <AddIcon color="secondary" />
                  </IconButton>
                  <Box>Photo</Box>
                </Box>
              ) : (
                <img
                  style={{
                    height: "70px",
                    width: "70px",
                    objectFit: "contain",
                  }}
                  src={values.photo}
                />
              )}
            </FilePicker>
          </Box>
          <Box padding="20px 0" display="flex" justifyContent="center">
            <Button
              onClick={submitForm}
              variant="contained"
              color="secondary"
              style={{ width: "65%", borderRadius: "5px" }}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
export default NomineeDetails;
