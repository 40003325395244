import * as yup from "yup";

const loginValdation = yup.object().shape({
  username: yup.string().email().required("email is required"),
  password: yup.string().required("password is required"),
});

const forgotPasswordValdation = yup.object().shape({
  username: yup.string().email().required("email is required"),
});

const resetValdation = yup.object().shape({
  password: yup.string().required("password is required"),
  confirmpassword: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      "Confirm Password Must be match with new password"
    )
    .required("password is required"),
});

const paymentValidation = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().required("Email is required"),
  phone: yup.string().required("Phone number is required"),
});

export {
  loginValdation,
  forgotPasswordValdation,
  resetValdation,
  paymentValidation,
};
