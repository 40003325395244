import {
  AppBar,
  IconButton,
  Typography,
  makeStyles,
  Box,
  TextField,
  Avatar,
  Menu,
  MenuItem,
  Card,
} from "@material-ui/core";
import React, { useState } from "react";
// import MenuIcon from '@material-ui/icons/Menu';
import MenuIcon from "../assets/icons/menu.png";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import { DRAWER_WIDTH } from "./MainDrawer";
import { BrikCoin } from "../assets/assets";
import BellIcon from "../assets/BellIcon.png";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useAuth } from "../providers/AuthProvider";
import { useGeneralProvider } from "../providers/GeneralProvider";
// import MessageIcon from '../../assets/icons/message_icon.svg';
import Popover from "@mui/material/Popover";
import BrikittIcon from "../assets/images/Brikitt.png";
import Badge from "@mui/material/Badge";
import { onMessageListener } from "../firebase/firebaseInit";
import { useDispatch, useSelector } from "react-redux";
import {
  pushNotification,
  resetNotificationCounter,
} from "../store/actions/userActions";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { readNotifications } from "../store/actions/apis";
import { forgotPassword } from '../store/actions/apis';
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootMenuItem: {
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  appBar: {
    // transition: theme.transitions.create(['margin', 'width'], {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.leavingScreen,
    // }),
  },
  appBarShift: {
    // width: `calc(100% - ${DRAWER_WIDTH}px)`,
    // marginLeft: DRAWER_WIDTH,
    // transition: theme.transitions.create(['margin', 'width'], {
    //     easing: theme.transitions.easing.easeOut,
    //     duration: theme.transitions.duration.enteringScreen,
    // }),
  },
}));

function Header({ open, type, handleDrawerOpen }) {
  let classes = useStyles();
  const history = useHistory();
  const auth = useAuth();
  const general = useGeneralProvider();
  const [show, setShow] = useState({ message: "NO MESSAGE" });
  const [info, setInfo] = useState("none");
  const [button, setButton] = useState("block");
  const dispatch = useDispatch();
  const { notifications, notificationsCount, balance } = useSelector(
    (state) => {
      return {
        notifications: state.userReducer.notifications,
        notificationsCount: state.userReducer.notificationCounts,
        balance: state.wallet.balance,
      };
    }
  );
  const [processing, setProcessing] = useState(false)
  const notification = useSnackbar()

  const revisedNotificationCounter = () => {
    var count = notifications.reduce(function (n, val) {
      return n + (val.read === false);
    }, 0);
    dispatch(resetNotificationCounter(count));
  };

  onMessageListener()
    .then((payload) => {
      console.log([payload.notification, ...notifications]);
      payload.notification.read = false;
      console.log(
        "payload.notification: [on Message Listener]",
        payload.notification
      );

      dispatch(pushNotification(payload.notification));
      // revisedNotificationCounter()
      const notification = new Notification(payload.notification.title, {
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  return type == "web" ? (
    <>
      <Box
        display="flex"
        style={{
          padding: "30px",
          paddingLeft: "16px",
          paddingBottom: "10px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Box flex={1} alignItems="center" display="flex">
          {general.getHeader()}
        </Box>
        <Box
          flex={1}
          justifyContent="flex-end"
          display="flex"
          alignItems="center"
        >
          <Avatar src={auth.getUser().profile_pic} />
          <Box display="flex" alignItems="center" padding="0px 8px">
            <Box>{auth.getUser().name}</Box>
            <PopupState variant="popover" popupId="demoMenu">
              {(popupState) => (
                <React.Fragment>
                  <IconButton
                    size="small"
                    variant="contained"
                    {...bindTrigger(popupState)}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>

                  <Menu
                    {...bindMenu(popupState)}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    style={{ top: "5px", left: "1px" }}
                  >
                    <MenuItem
                      onClick={() => {
                        auth.logout();
                        popupState.close();
                      }}
                    >
                      Logout
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
            history.push("/changepassword");
                        popupState.close();
                      }}
                    >
                      Change Password
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </Box>
        </Box>
        <IconButton disableRipple size="small">
          <Avatar src={BrikCoin} style={{ height: "32px", width: "32px" }} />
          <Box>&nbsp;{balance}</Box>
        </IconButton>
        &nbsp;&nbsp;
        <PopupState variant="popover" popupId="demoMenu">
          {(popupState) => (
            <React.Fragment>
              <IconButton
                size="small"
                variant="contained"
                onClick={(event) => {
                  readNotifications().then((result) => {});
                  bindTrigger(popupState).onClick(event);
                }}
              >
                <Badge badgeContent={notificationsCount} color="primary">
                  <img src={BellIcon} />
                </Badge>
              </IconButton>
              {console.log("bindMenu(popupState):", bindMenu(popupState))}
              <Popover
                {...bindMenu(popupState)}
                onClose={(event) => {
                  dispatch(resetNotificationCounter());
                  bindMenu(popupState).onClose(event);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div
                  className="notify"
                  style={{
                    backgroundColor: "#ffffff",
                    boxShadow: "0px 8px 25px rgba(83, 89, 144, 0.07)",
                    borderRadius: "10px",
                  }}
                >
                  <Card
                    style={{
                      display: "flex",
                      padding: "15px",
                      alignItems: "center",
                      justifyContent: "center",
                      borderBottom: "none",
                    }}
                  >
                    <div style={{ textAlign: "center", fontSize: "26px" }}>
                      Notification
                    </div>
                  </Card>
                  <Card
                    style={{
                      display: "flex",
                      padding: "15px",
                      alignItems: "center",
                      borderBottom: "none",
                      backgroundColor: "#FFF2E3",
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      You have {notificationsCount} unread notifications.
                    </div>
                  </Card>
                  <Box
                    id="notifications"
                    style={{
                      width: "450px",
                      maxHeight: "400px",
                      padding: "12px",
                      backgroundColor: "#fcfafa",
                    }}
                  >
                    {notifications.slice(0, 3).map((notification) => {
                      return (
                        <>
                          <Box
                            display="flex"
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#fcfafa",
                            }}
                            flexDirection="column"
                            onClick={()=>{
                              if (notification.url && notification.url!="") window.open(notification.url);
                            }}
                          >
                            <Box
                              style={{
                                padding: "5px 0",
                                backgroundColor: "#ffffff",
                                boxShadow:
                                  "0px 8px 25px rgba(83, 89, 144, 0.07)",
                                borderRadius: "5px",
                              }}
                            >
                              <Card
                                style={{
                                  display: "flex",
                                  padding: "15px",
                                  alignItems: "center",
                                }}
                              >
                                <Box paddingRight="20px">
                                  <img src={BellIcon} />
                                </Box>
                                <Box style={{ width: "100%" }}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "space-between",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    <Box flex={1} fontWeight="600">
                                      {notification.title}
                                    </Box>
                                    <Box fontStyle="italic">
                                      {notification.date}{" "}
                                      {moment(
                                        notification.notification_date
                                      ).fromNow()}
                                    </Box>
                                  </Box>
                                  <Box
                                    fontWeight="600"
                                    style={{ color: "#565656" }}
                                  >
                                    {notification.body}
                                  </Box>
                                </Box>
                              </Card>
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                    {notifications.length == 0 && (
                      <Box textAlign="center">No Notificaiton yet</Box>
                    )}
                    {notifications.slice(3).map((notification) => {
                      return (
                        <div style={{ display: info }}>
                          <Box
                            display="flex"
                            className="extra"
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#fcfafa",
                            }}
                            flexDirection="column"
                            onClick={()=>{
                              if (notification.url && notification.url!="") window.open(notification.url);
                            }}
                          >
                            <Box
                              style={{
                                padding: "5px 0",
                                backgroundColor: "#ffffff",
                                boxShadow:
                                  "0px 8px 25px rgba(83, 89, 144, 0.07)",
                                borderRadius: "5px",
                              }}
                            >
                              <Card
                                style={{
                                  display: "flex",
                                  padding: "15px",
                                  alignItems: "center",
                                }}
                              >
                                <Box paddingRight="20px">
                                  <img src={BellIcon} />
                                </Box>
                                <Box style={{ width: "100%" }}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "space-between",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    <Box flex={1} fontWeight="600">
                                      {notification.title}
                                    </Box>
                                    <Box fontStyle="italic">
                                      {notification.date}
                                      {moment(
                                        notification.notification_date
                                      ).fromNow()}
                                    </Box>
                                  </Box>
                                  <Box
                                    fontWeight="600"
                                    style={{ color: "#565656" }}
                                  >
                                    {notification.body}
                                  </Box>
                                </Box>
                              </Card>
                            </Box>
                          </Box>
                        </div>
                      );
                    })}
                  </Box>
                  {notifications.length > 3 && (
                    <div style={{ display: button }}>
                      <Card
                        className="more"
                        onClick={() => {
                          setInfo("block");
                          setButton("none");
                        }}
                        style={{
                          display: "flex",
                          padding: "15px",
                          alignItems: "center",
                          justifyContent: "center",
                          borderBottom: "none",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "16px",
                            color: "#0B4B66",
                          }}
                        >
                          View all notification
                        </div>
                      </Card>
                    </div>
                  )}
                </div>
              </Popover>
            </React.Fragment>
          )}
        </PopupState>
      </Box>
    </>
  ) : (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
      style={{ backgroundColor: "white" }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, open && classes.hide)}
        >
          <img src={MenuIcon} />
          {/* <MenuIcon style={{ backgroundColor: "#8B7862", color: "white", padding: "4px", borderRadius: "4px" }} /> */}
        </IconButton>
        <Box display="flex" width="100%">
          <Box flex={1} textAlign="center">
            <img style={{ height: "53px" }} src={BrikittIcon} />
          </Box>
        </Box>
        <Box padding="0px 8px">
          <IconButton disableRipple size="small">
            <Avatar src={BrikCoin} style={{ height: "32px", width: "32px" }} />
            <Box>&nbsp;{balance}</Box>
          </IconButton>
        </Box>
        <Box>
          <IconButton
            size="small"
            variant="contained"
            onClick={() => {
              history.push("/notification");
            }}
          >
            <Badge badgeContent={notificationsCount} color="primary">
              <img src={BellIcon} />
            </Badge>
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
