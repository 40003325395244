import React, { useState, useEffect } from "react";
import { Avatar, Box, Button, Grid } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import LeftIcon from "@material-ui/icons/ArrowBackIos";
import RightIcon from "@material-ui/icons/ArrowForwardIos";
import { BrikCoin } from "../../assets/assets";
import { getReviews } from "../../store/actions/apis.js";
import moment from "moment";
import PropTypes from "prop-types";
// const id =5;
function PropertyReview(props) {
  const [value, setValue] = useState(2);
  const [reviews, setReviews] = useState({});

  useEffect(() => {
    console.log("PROPERT REVIWEss", props.reviews);
    // getReviews(props.brikId).then((result) => {
    setReviews(props.reviews.reviews);
    //   console.log("Data Review: ", result.data.reviews);
    //   console.log("Review: ", result.data.reviews);
    // });
  }, [props.reviews]);

  return (
    <>
      {console.log("PROPERT REVIEW RENDER")}
      <Grid container>
        <Grid item xs={12}>
          {reviews.length > 0 &&
            reviews.map((review) => {
              const todaysDate = new Date(review.date);
              const dateString = moment(todaysDate).format("MMM DD, YYYY");
              console.log("Date String: ", dateString);
              return (
                <Box>
                  <Box marginTop="20px" style={{ display: "flex" }}>
                    <Box
                      style={{
                        width: "45px",
                        height: "45px",
                        borderRadius: "50%",
                        borderWidth: "1px",
                        backgroundImage: `url(${review.profile_pic_url})`,
                      }}
                    ></Box>
                    <Box padding="0 13px">
                      <Box>{review.name}</Box>
                      <Box
                        borderColor="transparent"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Box component="legend"><strong>{review.rating}</strong></Box>
                        <Rating
                          name="read-only"
                          value={review.rating}
                          readOnly
                        />
                      </Box>
                    </Box>
                    {/* .spilt("-")[0] */}
                    <Box color="#7C7C7C">{dateString}</Box>
                  </Box>
                  <Box padding="10px 0"><strong>{review.title}</strong></Box>
                  {review.review}
                </Box>
              );
            })}
          {/* <Box display="flex" justifyContent="center">
            <Box>
              <LeftIcon />
            </Box>
            <Box
              style={{
                border: "1px solid black",
                backgroundColor: "#4A8EAB",
                padding: "3px 10px",
                margin: "0 10px",
              }}
            >
              1
            </Box>
            <Box
              style={{
                border: "1px solid black",
                padding: "3px 10px",
              }}
            >
              2
            </Box>
            <Box marginLeft="10px">
              <RightIcon />
            </Box>
          </Box> */}
        </Grid>
      </Grid>
    </>
  );
}

export default React.memo(PropertyReview);

PropertyReview.propTypes = {
  brikId: PropTypes.string,
  reviews: PropTypes.array,
};
