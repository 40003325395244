import {
  Grid,
  Typography,
  TextField,
  Button,
  Link,
  Box,
  makeStyles,
  Avatar,
  Card,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Logo } from "../assets/assets";
import LoginSideImage from "../assets/LoginSideImage.png";
import { useAuth } from "../providers/AuthProvider";
import { useFormik } from "formik";
import { resetValdation } from "../validations";
import { useSelector } from "react-redux";
import { useGeneralProvider } from "../providers/GeneralProvider";
import { useSnackbar } from "notistack";
import { resetPassword } from "../store/actions/apis";
const queryString = require("query-string");

function ResetPassword() {
  const useStyles = makeStyles((theme) => ({
    header: {
      height: "100vh",
      width: "100%",
      color: "white",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      background: `linear-gradient(to top, #00000073, #00000073), url(${LoginSideImage})`,
    },
    web: {
      visibility: "block",
    },
    app: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      web: {
        display: "none",
      },
      app: {
        display: "block",
      },
    },
    // notchedOutline: {
    //     borderWidth: '1px',
    //     borderColor: `${theme.palette.secondary .main} !important`
    // }
  }));

  const history = useHistory();
  const classes = useStyles();
  const general = useGeneralProvider();
  const auth = useAuth();
  const location = useLocation();
  const notification = useSnackbar();
  const [search, setSearch] = useState({});

  const [processing, setProcessing] = useState(false);
  const { isComponentBusy } = useSelector((state) => {
    return { isComponentBusy: state.userReducer.isComponentBusy };
  });

  useEffect(() => {
    if (localStorage.getItem("token")) history.push("/my-ownership");
    setSearch(queryString.parse(location.search));
    console.log("LOCation: ", queryString.parse(location.search));
  }, []);

  const { values, touched, errors, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        confirmpassword: "",
        password: "",
      },
      onSubmit: () => {
        setProcessing(true);
        resetPassword({
          password: values.password,
          token: search.secret,
          uidb64: search.user_code,
        }).then((result) => {
          setProcessing(false);
          history.push("/login");
          notification.enqueueSnackbar("Password Changed Successfully", {
            variant: "success",
            autoHideDuration: 2000,
          });
        });
      },
      validationSchema: resetValdation,
    });

  return (
    <>
      <Grid alignItems="center" className={classes.web} container>
        <Grid
          style={{
            color: "white",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100vh",
          }}
          item
          xs={3}
        >
          <div className={classes.header}>
            <Box fontSize="12px">WELCOME TO </Box>
            <Box
              style={{
                fontSize: "18px",
                fontWeight: 600,
                paddingBottom: "12px",
                paddingTop: "4px",
              }}
            >
              BRIK itt
            </Box>
            <Box fontSize="11px">Please login to continue</Box>
            <Box
              style={{
                color: "#E5E5E5",
                fontSize: "22px",
                padding: "40px",
                fontWeight: 600,
              }}
            >
              FRACTIONAL OWNERSHIP SIMPLIFIED
            </Box>
            <Box
              style={{
                color: "#E5E5E5",
                fontSize: "12px",
                padding: "0px 25px",
              }}
            >
              BRIK itt is India’s Leading Proptech Company unlocking Fractional
              Ownership in Private & Residential Real Estate. It brings to the
              people the opportunity to own Exclusive luxury residential &
              vacational assets.
            </Box>
          </div>
        </Grid>
        {/* <Grid style={{ color: "white", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", backgroundImage: `url(${LoginSideImage})`, opacity: 1, height: "100vh" }} item xs={3} >
                    <Box fontSize="12px">WELCOME TO </Box>
                    <Box style={{ fontSize: "18px", fontWeight: 600, paddingBottom: "12px", paddingTop: "4px" }}>BRIK itt</Box>
                    <Box fontSize="11px">Please login to continue</Box>
                    <Box style={{ color: "#E5E5E5", fontSize: "22px", padding: "40px", fontWeight: 600 }}>Enjoy Ownership Experience in simple way</Box>
                    <Box style={{ color: "#E5E5E5", fontSize: '12px', padding: "0px 25px" }}>BRIK itt is India’s Leading  Proptech Company unlocking Fractional Ownership in Private & Residential Real Estate. It brings to the people the opportunity to own Exclusive luxury residential & vacational assets.</Box>
                </Grid> */}
        <Grid container justifyContent="center" item xs={8}>
          <Grid item xs={12}>
            <Box
              padding={3}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <Avatar src={Logo} />
              <Box
                style={{
                  padding: "0px 10px",
                  color: "#8B7862",
                  fontWeight: 500,
                }}
              >
                BRIKITT
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Card style={{ padding: "30px" }}>
                <Box>Reset Password</Box>
                <Box style={{ padding: "20px 0px" }}>
                  <TextField
                    id="password"
                    name="password"
                    type="password"
                    error={touched.password && errors.password}
                    helperText={touched.password && errors.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // InputProps={{
                    //     classes: {
                    //         root: classes.cssOutlinedInput,
                    //         focused: classes.cssFocused,
                    //         notchedOutline: classes.notchedOutline,
                    //     },
                    //     inputMode: "numeric"
                    // }}
                    variant="outlined"
                    fullWidth
                    placeholder="Password"
                  />
                </Box>
                <Box style={{ paddingBottom: "20px" }}>
                  <TextField
                    id="confirmpassword"
                    type="password"
                    name="confirmpassword"
                    type="confirmpassword"
                    error={touched.confirmpassword && errors.confirmpassword}
                    helperText={
                      touched.confirmpassword && errors.confirmpassword
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // InputProps={{
                    //     classes: {
                    //         root: classes.cssOutlinedInput,
                    //         focused: classes.cssFocused,
                    //         notchedOutline: classes.notchedOutline,
                    //     },
                    //     inputMode: "numeric"
                    // }}
                    variant="outlined"
                    fullWidth
                    placeholder="Confirm Password"
                  />
                </Box>
                <Box>
                  <Button
                    onClick={handleSubmit}
                    color="secondary"
                    variant="contained"
                    fullWidth
                    disabled={isComponentBusy}
                  >
                    {/* {isComponentBusy ? <CircularProgress /> : "Continue"} */}
                    Continue
                  </Button>
                </Box>
                {/* <Box style={{ textAlign: "center", padding: "5px" }}>
                  <a style={{
                    color: "#979797",
                    fontSize: "12px"
                  }}>Forgot Password?</a>
                </Box> */}
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.app} container spacing={2}>
        <Grid
          item
          sm={3}
          xs={12}
          style={{
            height: "80vh",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box>
            <Box style={{ color: "#1A4F5D", fontWeight: 600, fontSize: 20 }}>
              Hi Welcome !
            </Box>
            <Box style={{ padding: "10px 0", color: "#5C7B83" }}>
              Explore your Brik Ownership & Enjoy the managment experience
            </Box>
          </Box>

          <Box style={{ fontWeight: 600, fontSize: 20, padding: "15px 0" }}>
            Reset Password
          </Box>
          <Box>
            <Card style={{ padding: "30px" }}>
              <Box paddingBottom="13px">
                <Box style={{ padding: "20px 0px" }}>
                  <TextField
                    id="password"
                    name="password"
                    type="password"
                    error={touched.password && errors.password}
                    helperText={touched.password && errors.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // InputProps={{
                    //     classes: {
                    //         root: classes.cssOutlinedInput,
                    //         focused: classes.cssFocused,
                    //         notchedOutline: classes.notchedOutline,
                    //     },
                    //     inputMode: "numeric"
                    // }}
                    variant="outlined"
                    fullWidth
                    placeholder="Password"
                  />
                </Box>
                <Box style={{ paddingBottom: "20px" }}>
                  <TextField
                    id="confirmpassword"
                    type="password"
                    name="confirmpassword"
                    type="confirmpassword"
                    error={touched.confirmpassword && errors.confirmpassword}
                    helperText={
                      touched.confirmpassword && errors.confirmpassword
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // InputProps={{
                    //     classes: {
                    //         root: classes.cssOutlinedInput,
                    //         focused: classes.cssFocused,
                    //         notchedOutline: classes.notchedOutline,
                    //     },
                    //     inputMode: "numeric"
                    // }}
                    variant="outlined"
                    fullWidth
                    placeholder="Confirm Password"
                  />
                </Box>

                <Box>
                  <Button
                    disabled={processing}
                    onClick={handleSubmit}
                    color="secondary"
                    variant="contained"
                    fullWidth
                  >
                    {processing ? <CircularProgress size="22px" /> : "Continue"}
                  </Button>
                </Box>
                <Box style={{ textAlign: "center", padding: "5px" }}>
                  <Link
                    href="/"
                    style={{ cursor: "pointer", color: "grey", fontSize: 14 }}
                  >
                    Back to Login
                  </Link>
                </Box>
              </Box>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default ResetPassword;
