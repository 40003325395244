import { makeStyles } from "@material-ui/core"

export const useGeneralStyles = ()=>({
    activeSideMenuLink: {
        color:"white",
        background: 'linear-gradient(272.56deg, #77644E 0%, rgba(139, 120, 98, 0.75) 100%)',
        // textAlign: "center",
        padding: "10px",
        borderRadius: "27px"
    },
    sideMenuLink:{
        color:"white",
        background: 'linear-gradient(272.56deg, #77644E 0%, rgba(139, 120, 98, 0.75) 100%)',
        // textAlign: "center",
        padding: "10px",
        borderRadius: "27px"
    }
})

export const responsiveStyles= makeStyles((theme) => ({
  web: {
    visibility: "block"
  },
  app: {
    display: "none"
  },
  [theme.breakpoints.down('sm')]: {
    web: {
      display: "none"
    },
    app: {
      display: "block"
    },
    SPVmodal:{
      left:"0px"
    }
  },
  [theme.breakpoints.down('md')]: {
    web: {
      display: "none"
    },
    app: {
      display: "block"
    },
    SPVmodal:{
      left:"0px"
    }
  },
}))


// export const responsiveStyles = makeStyles((theme) => ({
//     web: {
//       display: "visible"
//     },
//     app: {
//       display: "hidden"
//     },
//     [theme.breakpoints.down('sm')]: {
//       web: {
//         display: "hidden"
//       },
//       app: {
//         display: "visible"
//       },
//     },
//   }))