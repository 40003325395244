import React, { useState } from 'react';

import styles from './TransactionPopup.module.css';

export const TransactionPopup = ({ onCancel, onConfirm, availableCoins = 58, payAmount = 20 }) => {
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onCancel();
    }
  };

  return (
    <div className={styles['popup-backdrop']} onClick={handleBackdropClick}>
      <div className={styles['popup-content']}>
        <div className={styles['popup-body']}>
          <h2 className={styles['popup-title']}>
            Are you sure you want to 
            <br/>
            proceed with this transaction?
          </h2>
          
          <div style={{display:"flex", flexWrap:"wrap", justifyContent:"center"}} >
          <div  className={styles['coins-display']}>
            <span>Available Coins</span>
           
            <span className={styles['coins-amount']}>{availableCoins}</span>
          </div>

          </div>
         

          <div className={styles['button-group']}>
            <button 
              className={`${styles.button} ${styles['button-cancel']}`} 
              onClick={onCancel}
            >
              CANCEL
            </button>
            
            <button 
              className={`${styles.button} ${styles['button-pay']}`} 
              onClick={onConfirm}
            >
              <span>PAY</span>
            
              <span>{payAmount}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
