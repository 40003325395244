import { data } from "jquery"
import { GET_MY_OWNERSHIPS, IS_COMPONENT_BUSY_OWNERSHIP, IS_COMPONENT_BUSY, SET_COOWNERS, SET_TRANSACTION } from "../constants"
import { getOwnerShipData } from "./apis"

export const setComponentBusyState = (data) => {
    return {
        type: IS_COMPONENT_BUSY,
        payload: data
    }
}

export const setComponentBusyStateOwnership = (data) => {
    return {
        type: IS_COMPONENT_BUSY_OWNERSHIP,
        payload: data
    }
}


export const setMyOwnerShipData = (data) => {
    return {
        type: GET_MY_OWNERSHIPS,
        payload: data
    }
}

export const setCoownersData = (data) => {
    return {
        type: SET_COOWNERS,
        payload: data
    }
}

export const setTrasactionData = (data) => {
    return {
        type: SET_TRANSACTION,
        payload: data
    }
}

export const fetchMyOwnerships = (data) => {
    return (dispatch) => {
        dispatch(setComponentBusyStateOwnership(true))

        return getOwnerShipData(data).then(result => {
            // if (result.response)
            //     dispatch(setMyOwnerShipData([]))
            // else
                dispatch(setMyOwnerShipData(result))

            dispatch(setComponentBusyStateOwnership(false))
        }).catch(er => {
            console.log("ownership list ERR: ", er)
            dispatch(setComponentBusyStateOwnership(false))
        })
    }
}